import { Route, Routes } from 'react-router-dom';
import NotFoundPage from 'src/pages/NotFound.page';
import { ApiKeysPage } from './api-keys/api-keys.page';
import { WebhooksPage } from './webhooks/webhooks.page';

export function DeveloperRoutes(): JSX.Element {
  return (
    <Routes>
      <Route path="api-keys" element={<ApiKeysPage />} />
      <Route path="webhooks" element={<WebhooksPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}
