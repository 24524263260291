import { Button, EmptyDisplay } from '@addglowapp/components';
import { ErrorableLoader } from '@addglowapp/components';
import { useState } from 'react';
import {
  BrandApiKeyFragment,
  useGetBrandApiKeysQuery,
} from 'src/generated/graphql';
import { useBrandId } from 'src/hooks/useBrandId';
import { ApiKeyEditModal } from './ApiKeyEditModal';
import { BrandApiKeyTable } from './BrandApiKeyTable';
import { CreatedKeyModal } from './CreatedKeyModal';

const MAX_API_KEYS = 10;

export function ApiKeysPage(): JSX.Element {
  const brandId = useBrandId();
  const { data, error } = useGetBrandApiKeysQuery({
    variables: {
      brandId,
    },
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedApiKey, setSelectedApiKey] =
    useState<BrandApiKeyFragment | null>(null);
  const [isCreatedKeyModalOpen, setIsCreatedKeyModalOpen] = useState(false);
  const [createdKey, setCreatedKey] = useState<string | undefined>(undefined);

  if (!data) {
    return <ErrorableLoader error={error} />;
  }

  return (
    <div className="max-w-4xl space-y-2">
      <h1>API Keys</h1>
      <p>
        API keys allow you to authenticate with the AddGlow API to perform
        actions such as getting a list of users. You can create multiple keys to
        use in different environments or for different purposes.
      </p>

      <div className="flex justify-end">
        <Button
          className="btn btn-primary"
          onClick={() => {
            setSelectedApiKey(null);
            setIsModalOpen(true);
          }}
          disabled={data.brandApiKeys.length >= MAX_API_KEYS}
        >
          Create New API Key
        </Button>
      </div>

      {data.brandApiKeys.length === 0 ? (
        <EmptyDisplay
          header="No API Keys"
          subtitle="There are no API keys for this brand yet. Please create one above to get started."
        />
      ) : (
        <BrandApiKeyTable
          onEdit={(apiKey) => {
            setSelectedApiKey(apiKey);
            setIsModalOpen(true);
          }}
          brandApiKeys={data.brandApiKeys}
        />
      )}

      <ApiKeyEditModal
        isOpen={isModalOpen}
        onOpenChange={(isOpen) => {
          setIsModalOpen(isOpen);
          if (!isOpen) {
            setSelectedApiKey(null);
          }
        }}
        existingApiKey={selectedApiKey}
        onKeyCreated={(key) => {
          setCreatedKey(key);
          setIsCreatedKeyModalOpen(true);
        }}
      />
      <CreatedKeyModal
        isOpen={isCreatedKeyModalOpen}
        onOpenChange={setIsCreatedKeyModalOpen}
        createdKey={createdKey}
      />
    </div>
  );
}
