function ContactPage(): JSX.Element {
  return (
    <div className="space-y-8">
      <h1>Contact Us</h1>
      <p>
        If you have any questions, concerns, or would like to give us feedback
        on how we can improve, please do not hesitate to contact us at{' '}
        <a
          className="underline hover:opacity-80"
          href="mailto:support@addglow.com"
        >
          support@addglow.com
        </a>
        .
      </p>
    </div>
  );
}

export default ContactPage;
