import {
  Dropdown,
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableHead,
  TableCell,
  useConfirmDialog,
} from '@addglowapp/components';
import { toast } from '@addglowapp/components';
import clsx from 'clsx';
import { BsThreeDots } from 'react-icons/bs';
import {
  BrandApiKeyFragment,
  GetBrandApiKeysDocument,
  useDeleteBrandApiKeyMutation,
} from '@src/generated/graphql';
import { useBrandId } from '@src/hooks/useBrandId';
import { logAndFormatError } from '@src/services/error-formatter';
import { formatDate } from '@src/utils/date';

interface BrandApiKeyTableProps {
  className?: string;
  brandApiKeys: BrandApiKeyFragment[];
  onEdit: (brandApiKey: BrandApiKeyFragment) => void;
}

export function BrandApiKeyTable({
  className,
  brandApiKeys,
  onEdit,
}: BrandApiKeyTableProps): JSX.Element {
  const brandId = useBrandId();
  const [deleteKey] = useDeleteBrandApiKeyMutation({
    refetchQueries: [
      {
        query: GetBrandApiKeysDocument,
        variables: {
          brandId,
        },
      },
    ],
  });

  const { requestConfirm } = useConfirmDialog();

  const handleDelete = (brandApiKey: BrandApiKeyFragment): void => {
    deleteKey({
      variables: {
        input: { id: brandApiKey.id },
      },
    })
      .then(() => {
        toast.success('API Key deleted successfully');
      })
      .catch((err) => {
        toast.error(logAndFormatError(err));
      });
  };

  return (
    <Table className={clsx(className, 'table-fixed')}>
      <TableHeader>
        <TableRow>
          <TableHead className="w-1/12">Actions</TableHead>
          <TableHead className="w-1/3">Name</TableHead>
          <TableHead className="w-1/3">Key</TableHead>
          <TableHead className="w-1/4">Created At</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {brandApiKeys.map((brandApiKey) => (
          <TableRow key={brandApiKey.id}>
            <TableCell>
              <Dropdown>
                <Dropdown.Trigger>
                  <BsThreeDots />
                </Dropdown.Trigger>
                <Dropdown.Content>
                  <Dropdown.Item
                    onClick={() => {
                      onEdit(brandApiKey);
                    }}
                  >
                    Edit
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      requestConfirm({
                        title: 'Delete API Key',
                        content:
                          'Are you sure you want to delete this API Key?',
                        buttonConfirmText: 'Delete',
                        onConfirm: () => handleDelete(brandApiKey),
                      });
                    }}
                  >
                    Delete
                  </Dropdown.Item>
                </Dropdown.Content>
              </Dropdown>
            </TableCell>
            <TableCell>{brandApiKey.name}</TableCell>
            <TableCell className="font-mono">
              {brandApiKey.keyPrefix}_...
            </TableCell>
            <TableCell>{formatDate(brandApiKey.createdAt)}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
