import { Button } from '@addglowapp/components';
import { ToggleInput } from '@addglowapp/components';
import { TextInputField } from '@addglowapp/components';
import { toast } from '@addglowapp/components';
import { zodResolver } from '@hookform/resolvers/zod';
import clsx from 'clsx';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import LayoutSection from 'src/components/LayoutSection';
import {
  BrandWithInveterateIntegrationFragment,
  useDisconnectInveterateIntegrationMutation,
  useUpdateInveterateRedemptionUrlMutation,
} from 'src/generated/graphql';
import { logAndFormatError } from 'src/services/error-formatter';

interface UpdateInveterateSectionProps {
  className?: string;
  brand: BrandWithInveterateIntegrationFragment;
}

const formSchema = z.object({
  membershipJoinUrl: z.union([z.literal(''), z.string().url()]),
  isCommunityGateEnabled: z.boolean(),
});

type FormValues = z.infer<typeof formSchema>;

export function UpdateInveterateSection({
  className,
  brand,
}: UpdateInveterateSectionProps): JSX.Element {
  const [deleteInveterateIntegration, { loading: isDeleting }] =
    useDisconnectInveterateIntegrationMutation();

  const [updateInveterateRedemptionUrl] =
    useUpdateInveterateRedemptionUrlMutation();
  const {
    control,
    handleSubmit,
    formState: { isDirty, isSubmitting },
  } = useForm<FormValues>({
    values: {
      membershipJoinUrl: brand.inveterateIntegration?.membershipJoinUrl ?? '',
      isCommunityGateEnabled:
        brand.inveterateIntegration?.isCommunityGateEnabled ?? false,
    },
    resolver: zodResolver(formSchema),
  });

  async function handleUpdate(values: FormValues): Promise<void> {
    try {
      await updateInveterateRedemptionUrl({
        variables: {
          input: {
            brandId: brand.id,
            membershipJoinUrl: values.membershipJoinUrl || null,
            isCommunityGateEnabled: values.isCommunityGateEnabled,
          },
        },
      });
      toast.success('Community gating settings successfully updated!');
    } catch (err) {
      toast.error(logAndFormatError(err));
    }
  }

  return (
    <div className={clsx('max-w-4xl space-y-4', className)}>
      <LayoutSection>
        <LayoutSection.Header title="Inveterate Connection" />
        <LayoutSection.Body className="space-y-4">
          <p>
            You have connected your Inveterate integration. You can now use
            Inveterate to gate your community.
          </p>
          <p>
            If you need to change or disconnect the integration, you can disable
            the integration below:
          </p>
          <Button
            color="secondary"
            disabled={isDeleting}
            onClick={() => {
              if (
                window.confirm(
                  'Are you sure you want to disconnect Inveterate?',
                )
              ) {
                deleteInveterateIntegration({
                  variables: { input: { brandId: brand.id } },
                })
                  .then(() => {
                    toast.success(
                      'Inveterate integration successfully disconnected!',
                    );
                  })
                  .catch((err) => {
                    toast.error(logAndFormatError(err));
                  });
              }
            }}
          >
            Disconnect Integration
          </Button>
        </LayoutSection.Body>
      </LayoutSection>
      <form onSubmit={handleSubmit(handleUpdate)}>
        <LayoutSection>
          <LayoutSection.Header title="Community Gating" />
          <LayoutSection.Body className="space-y-4">
            <p>
              If you wish to restrict access to your community to only members
              who are part of your Inveterate program, you can enable community
              gating.
            </p>
            <ToggleInput.LabelledController
              name="isCommunityGateEnabled"
              control={control}
              label="Membership Required"
              hint="Check this box to only allow users that are members access the community."
            />
            <TextInputField.Controller
              name="membershipJoinUrl"
              control={control}
              label="Membership Signup URL"
            />
            <p>
              Users who do not have a membership will be directed to this URL to
              sign up.
            </p>
            <Button
              color="primary"
              disabled={isSubmitting || !isDirty}
              type="submit"
            >
              Save
            </Button>
          </LayoutSection.Body>
        </LayoutSection>
      </form>
    </div>
  );
}
