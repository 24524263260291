import { toast } from '@addglowapp/components';
import { useState } from 'react';
import { useCreateYotpoDeveloperInstallationLinkMutation } from '@src/generated/graphql';
import { useBrandId } from '@src/hooks/useBrandId';
import { logAndFormatError } from '@src/services/error-formatter';

export function useRedirectToYotpoInstallationLink(): {
  redirect: () => void;
  isRedirecting: boolean;
} {
  const [createYotpoInstallationLink] =
    useCreateYotpoDeveloperInstallationLinkMutation();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const brandId = useBrandId();

  function redirectToInstallationLink(): void {
    setIsSubmitting(true);
    createYotpoInstallationLink({
      variables: {
        brandId,
      },
    })
      .then(({ data }) => {
        const link = data?.createYotpoDeveloperInstallationLink.link;
        if (!link) {
          throw new Error('Failed to create installation link');
        }
        window.location.href = link;
      })
      .catch((err) => {
        toast.error(logAndFormatError(err));
        setIsSubmitting(false);
      });
  }

  return {
    redirect: redirectToInstallationLink,
    isRedirecting: isSubmitting,
  };
}
