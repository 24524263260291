import { Header, HeaderButton } from '@addglowapp/components';
import { HiMenu, HiOutlineUserCircle } from 'react-icons/hi';
import { HeaderButtonIconType } from '@src/generated/graphql';

interface Props {
  backgroundImageUrl?: string | null;
  brandLogoUrl?: string | null;
  brandFaviconUrl?: string | null;
  brandName: string;
  shopUrl?: string | null;
  headerButtonText: string;
  headerButtonIcon: HeaderButtonIconType;
}

function HeaderPreview({
  backgroundImageUrl,
  brandLogoUrl,
  brandFaviconUrl,
  brandName,
  shopUrl,
  headerButtonText,
  headerButtonIcon,
}: Props): JSX.Element {
  return (
    <div className="mb-4">
      <Header
        backgroundImageUrl={backgroundImageUrl}
        brandLogoUrl={brandLogoUrl}
        brandFaviconUrl={brandFaviconUrl}
        brandName={brandName}
        onShopLinkOpen={
          shopUrl ? () => window.open(shopUrl, '_blank') : undefined
        }
        disableSticky
        loginWidget={
          <HeaderButton icon={HiOutlineUserCircle}>Log In</HeaderButton>
        }
        menuWidget={<HeaderButton icon={HiMenu} />}
        headerButtonIcon={headerButtonIcon}
        headerButtonText={headerButtonText}
      />
      <p className="mt-4 p-4 font-brand-content text-sm">
        This is a preview of your secondary font. Used for posts, comments and
        labels.
      </p>
    </div>
  );
}

export default HeaderPreview;
