import { Button, NULL_SENTINEL, SelectField } from '@addglowapp/components';
import { toast } from '@addglowapp/components';
import { zodResolver } from '@hookform/resolvers/zod';
import clsx from 'clsx';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import LayoutSection from 'src/components/LayoutSection';
import {
  BrandKlaviyoIntegrationFragment,
  useUpdateKlaviyoIntegrationMutation,
} from 'src/generated/graphql';
import { logAndFormatError } from 'src/services/error-formatter';

interface KlaviyoSubscribeListSectionProps {
  className?: string;
  klaviyoIntegration: BrandKlaviyoIntegrationFragment;
}

const formSchema = z.object({
  klaviyoListId: z.string().nullish(),
});

type FormValues = z.infer<typeof formSchema>;

export function KlaviyoSubscribeListSection({
  className,
  klaviyoIntegration,
}: KlaviyoSubscribeListSectionProps): JSX.Element {
  const [updateKlaviyoIntegration] = useUpdateKlaviyoIntegrationMutation();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isDirty },
  } = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    values: klaviyoIntegration,
  });

  async function updateKlaviyoIntegrationListId({
    klaviyoListId,
  }: FormValues): Promise<void> {
    try {
      await updateKlaviyoIntegration({
        variables: {
          input: {
            id: klaviyoIntegration.brandId,
            data: {
              klaviyoListId:
                klaviyoListId === NULL_SENTINEL ? null : klaviyoListId,
            },
          },
        },
      });
      toast.success('Klaviyo mappings successfully saved!');
    } catch (err) {
      toast.error(logAndFormatError(err));
    }
  }

  const listOptions = [
    {
      label: 'None',
      value: NULL_SENTINEL,
    },
    ...klaviyoIntegration.klaviyoLists.map((list) => ({
      label: list.name,
      value: list.id,
    })),
  ];

  return (
    <form
      onSubmit={handleSubmit(updateKlaviyoIntegrationListId)}
      className={clsx('space-y-8', className)}
    >
      <LayoutSection>
        <LayoutSection.Header
          title="Klaviyo List Auto-Subscription"
          actions={
            <div className="flex space-x-2">
              <Button
                color="primary"
                type="submit"
                disabled={isSubmitting || !isDirty}
              >
                Save
              </Button>
            </div>
          }
        />
        <LayoutSection.Body className="space-y-4">
          <p>
            If you want to subscribe AddGlow members who opt-in to email
            marketing to a Klaviyo list, you can choose a list below.
          </p>
          <p className="text-sm text-gray-700">
            Note: Lists that require{' '}
            <a href="https://help.klaviyo.com/hc/en-us/articles/115005251108">
              double opt-in
            </a>{' '}
            will require the user to confirm their subscription.
          </p>
          <SelectField.Controller
            name="klaviyoListId"
            className="w-60"
            control={control}
            options={listOptions}
            placeholder="Select a list"
          />
        </LayoutSection.Body>
      </LayoutSection>
    </form>
  );
}
