import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

interface Props {
  icon: ReactNode;
  text: string;
  header: string;
  actionUrl: string;
}
export default function ActionCard({
  icon,
  text,
  header,
  actionUrl,
}: Props): JSX.Element {
  return (
    <div className="flex flex-col rounded-md border bg-white p-10">
      {icon}
      <p className="mt-4 text-lg font-bold">{header}</p>
      <p>{text}</p>
      <Link className="mt-4" to={actionUrl}>
        Get Started
      </Link>
    </div>
  );
}
