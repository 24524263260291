import { ErrorableLoader, Button, ToggleInput } from '@addglowapp/components';
import { toast } from '@addglowapp/components';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import LayoutSection from '@src/components/LayoutSection';
import {
  useGetBrandPostApprovalSettingsQuery,
  useUpdateBrandPostApprovalSettingsMutation,
} from '@src/generated/graphql';
import { useBrandId } from '@src/hooks/useBrandId';
import { logAndFormatError } from '@src/services/error-formatter';

const postApprovalSettingsFormSchema = z.object({
  isPostApprovalRequired: z.boolean(),
});

type FormValues = z.infer<typeof postApprovalSettingsFormSchema>;

export function PostApprovalPage(): JSX.Element {
  const brandId = useBrandId();
  const { data, error } = useGetBrandPostApprovalSettingsQuery({
    variables: { brandId },
  });

  const [updateBrand] = useUpdateBrandPostApprovalSettingsMutation();

  const postApprovalSettingsData = data?.brand;

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isDirty },
  } = useForm({
    resolver: zodResolver(postApprovalSettingsFormSchema),
    values: postApprovalSettingsData,
  });

  const onSubmit = async (values: FormValues): Promise<void> => {
    return updateBrand({
      variables: {
        input: {
          id: brandId,
          data: values,
        },
      },
    })
      .then(() => {
        toast.success('Post approval settings updated successfully!');
      })
      .catch((err) => {
        toast.error(
          logAndFormatError(err, 'Sorry, the action could not be completed.'),
        );
      });
  };

  if (!data) {
    return <ErrorableLoader error={error} />;
  }

  return (
    <div className="space-y-4">
      <h1>Post Approval</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <LayoutSection className="max-w-2xl">
          <LayoutSection.Header
            title="Post Approval"
            actions={
              <Button disabled={isSubmitting || !isDirty} type="submit">
                Save
              </Button>
            }
          />
          <LayoutSection.Body className="space-y-4">
            <ToggleInput.LabelledController
              label="Require Post Approval"
              control={control}
              name="isPostApprovalRequired"
            />
            <p className="text-sm">
              All posts created by users will require explicit approval to be
              seen by other community members. A Pending Posts tab will be
              visible on the community page.
            </p>
          </LayoutSection.Body>
        </LayoutSection>
      </form>
    </div>
  );
}
