import { Dialog, useControlledState } from '@addglowapp/components';
import { MemberGroupFragment } from '@src/generated/graphql';
import { MemberGroupForm } from './MemberGroupForm';

interface MemberGroupDialogProps {
  memberGroup?: MemberGroupFragment;
  open?: boolean;
  onOpenChange?: (isOpen: boolean) => void;
  children?: React.ReactNode;
}

export function MemberGroupDialog({
  memberGroup,
  open,
  onOpenChange,
  children,
}: MemberGroupDialogProps): JSX.Element {
  const [isOpen, setIsOpen] = useControlledState(open, onOpenChange);
  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      {children && <Dialog.Trigger asChild>{children}</Dialog.Trigger>}
      <Dialog.Content aria-describedby={undefined}>
        <Dialog.Header>
          <Dialog.Title>
            {memberGroup ? `Edit ${memberGroup.name}` : 'Create Group'}
          </Dialog.Title>
        </Dialog.Header>
        <MemberGroupForm
          memberGroup={memberGroup}
          onSaved={() => {
            setIsOpen(false);
          }}
        />
      </Dialog.Content>
    </Dialog>
  );
}
