import { Outlet, Route, Routes } from 'react-router-dom';
import { RequireAuth } from '@src/components';
import NotFoundPage from '../NotFound.page';
import { TrialHomePage } from './home.page';
import { NewTrialPage } from './new.page';
import TrialLayout from './TrialLayout';

export function TrialRoutes(): JSX.Element {
  return (
    <Routes>
      <Route element={<TrialLayout />}>
        <Route
          element={
            <RequireAuth>
              <Outlet />
            </RequireAuth>
          }
        >
          <Route path="new" element={<NewTrialPage />} />
        </Route>
        <Route index element={<TrialHomePage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
}
