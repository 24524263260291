import { KlaviyoProperty } from 'src/generated/graphql';

interface KlaviyoPropertyConfiguration {
  label: string;
  hasNoMapping?: boolean;
  default?: boolean;
  forceEnabled?: boolean;
}

export const KLAVIYO_PROPERTIES: Record<
  KlaviyoProperty,
  KlaviyoPropertyConfiguration
> = {
  EMAIL: {
    label: 'Email',
    hasNoMapping: true,
    default: true,
    forceEnabled: true,
  },
  FIRST_NAME: {
    label: 'First Name',
    hasNoMapping: true,
    default: true,
    forceEnabled: true,
  },
  LAST_NAME: {
    label: 'Last Name',
    hasNoMapping: true,
    default: true,
    forceEnabled: true,
  },
  BIRTHDAY: { label: 'Birthday' },
  LOCATION: { label: 'Location' },
  EYE_COLOR: { label: 'Eye Color' },
  HAIR_COLOR: { label: 'Hair Color' },
  HAIR_CONCERN: { label: 'Hair Concern' },
  HAIR_LENGTH: { label: 'Hair Length' },
  HAIR_TEXTURE: { label: 'Hair Texture' },
  SKIN_TONE: { label: 'Skin Tone' },
  SKIN_TYPE: { label: 'Skin Type' },
  JOINED_DATE: { label: 'Joined AddGlow' },
};

export const KLAVIYO_PROPERTY_KEYS = Object.keys(KLAVIYO_PROPERTIES) as [
  KlaviyoProperty,
  ...KlaviyoProperty[],
];
