import clsx from 'clsx';
import { Outlet } from 'react-router-dom';

interface Props {
  className?: string;
}

function TrialLayout({ className }: Props): JSX.Element {
  return (
    <div
      className={clsx(
        'flex h-full flex-col items-stretch justify-stretch bg-brand-background-page',
        className,
      )}
    >
      <Outlet />
    </div>
  );
}

export default TrialLayout;
