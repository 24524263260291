import { Button } from '@addglowapp/components';
import { TextAreaInputField, TextInputField } from '@addglowapp/components';
import { toast } from '@addglowapp/components';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import LayoutSection from 'src/components/LayoutSection';
import { BrandFragment, useUpdateBrandMutation } from 'src/generated/graphql';
import { logAndFormatError } from 'src/services/error-formatter';

interface Props {
  brand: BrandFragment;
  brandId: string;
}

export const welcomeMessageFormSchema = z.object({
  welcomeMessage: z.string().trim().min(1).nullable(),
  welcomeBannerButtonText: z.string().trim().min(1).max(25).nullable(),
  welcomeBannerTitle: z.string().trim().min(1).max(50).nullable(),
});

export type WelcomeMessageFormData = z.infer<typeof welcomeMessageFormSchema>;

function WelcomeMessageSection({ brand, brandId }: Props): JSX.Element {
  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting, isDirty },
  } = useForm<WelcomeMessageFormData>({
    resolver: zodResolver(welcomeMessageFormSchema),
    defaultValues: {
      ...brand,
      welcomeBannerTitle:
        brand.welcomeBannerTitle ?? `Welcome to ${brand.name}!`,
    },
  });

  const [updateBrand] = useUpdateBrandMutation();
  const onSubmit = async (data: WelcomeMessageFormData): Promise<void> => {
    updateBrand({
      variables: {
        input: {
          id: brandId,
          data,
        },
      },
    })
      .then((val) => {
        const brandData = val.data?.updateBrand.brand;
        if (brandData) {
          reset(brandData);
        }
        toast.success('Brand data updated successfully.');
      })
      .catch((error) => {
        toast.error(
          logAndFormatError(error, 'Sorry, the action could not be completed.'),
        );
      });
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <LayoutSection>
        <LayoutSection.Header
          title="Welcome Message"
          actions={
            <div className="flex space-x-2">
              <Button
                color="secondary"
                onClick={() => {
                  reset(brand);
                }}
                disabled={isSubmitting || !isDirty}
              >
                Reset
              </Button>
              <Button
                color="primary"
                type="submit"
                disabled={isSubmitting || !isDirty}
              >
                Save
              </Button>
            </div>
          }
        />
        <LayoutSection.Body>
          <div className="flex max-w-xl flex-col space-y-4">
            <p>Enter the title of the banner.</p>
            <TextInputField.Controller
              control={control}
              className="flex-1"
              name="welcomeBannerTitle"
            />
            <p>Enter a message that new visitors to your community will see.</p>
            <TextAreaInputField.Controller
              control={control}
              className="flex-1"
              name="welcomeMessage"
            />
            <p>Enter the CTA label prompting the user to join the community.</p>
            <TextInputField.Controller
              control={control}
              className="flex-1"
              name="welcomeBannerButtonText"
            />
          </div>
        </LayoutSection.Body>
      </LayoutSection>
    </form>
  );
}

export default WelcomeMessageSection;
