import { ErrorableLoader } from '@addglowapp/components';
import { useGetKlaviyoIntegrationQuery } from 'src/generated/graphql';
import { useBrandId } from 'src/hooks/useBrandId';
import { ConnectKlaviyoSection } from './ConnectKlaviyoSection';
import { UpdateKlaviyoSection } from './UpdateKlaviyoSection';

export function KlaviyoPage(): JSX.Element {
  const brandId = useBrandId();
  const { data, error } = useGetKlaviyoIntegrationQuery({
    variables: {
      id: brandId,
    },
  });

  if (!data) {
    return <ErrorableLoader error={error} />;
  }

  return (
    <div className="space-y-2">
      <h1>Klaviyo</h1>
      {!data.brand.brandKlaviyoIntegration?.isEnabled ? (
        <ConnectKlaviyoSection
          brandId={brandId}
          klaviyoIntegration={data.brand.brandKlaviyoIntegration}
        />
      ) : (
        <UpdateKlaviyoSection
          klaviyoIntegration={data.brand.brandKlaviyoIntegration}
        />
      )}
    </div>
  );
}
