interface PlausibleAnalyticsEmbedProps {
  className?: string;
  shareUrl: string;
}

function getEmbedUrl(shareUrl: string): string {
  const url = new URL(shareUrl);
  url.searchParams.append('embed', 'true');
  url.searchParams.append('theme', 'light');
  url.searchParams.append('background', 'transparent');
  return url.toString();
}

export function PlausibleAnalyticsEmbed({
  className,
  shareUrl,
}: PlausibleAnalyticsEmbedProps): JSX.Element {
  return (
    <>
      <script async src="https://plausible.io/js/embed.host.js" />
      <iframe
        title="Plausible Analytics Embed"
        className={className}
        // eslint-disable-next-line react/no-unknown-property
        plausible-embed="true"
        src={getEmbedUrl(shareUrl)}
        scrolling="no"
        frameBorder="0"
        loading="lazy"
        style={{
          width: '1px',
          minWidth: '100%',
          height: '1600px',
        }}
      />
    </>
  );
}
