import {
  Button,
  MemberBadge,
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableHead,
  TableCell,
} from '@addglowapp/components';
import { useConfirmDialog } from '@addglowapp/components';
import { toast } from '@addglowapp/components';
import { LuPencil, LuTrash2 } from 'react-icons/lu';
import {
  BrandMemberBadgeFragment,
  BrandMemberBadgeType,
  GetBrandMemberBadgesDocument,
  useDeleteBrandMemberBadgeMutation,
} from '@src/generated/graphql';
import { logAndFormatError } from '@src/services/error-formatter';
import { MemberBadgeDialog } from './MemberBadgeDialog';

interface MemberBadgeTableProps {
  memberBadges: BrandMemberBadgeFragment[];
}

const TYPE_MAP: Record<BrandMemberBadgeType, string> = {
  GROUP: 'Group',
  ADMIN: 'Admin',
  MODERATOR: 'Moderator',
  ROOM_MODERATOR: 'Room Moderator',
};

export function MemberBadgeTable({
  memberBadges,
}: MemberBadgeTableProps): JSX.Element {
  const { requestConfirm } = useConfirmDialog();
  const [deleteBrandMemberBadge, { loading: deleteLoading }] =
    useDeleteBrandMemberBadgeMutation({
      refetchQueries: [GetBrandMemberBadgesDocument],
    });

  const onDelete = (memberBadge: BrandMemberBadgeFragment): void => {
    requestConfirm({
      title: 'Delete Member Badge',
      content: `Are you sure you want to delete ${memberBadge.name}?`,
      onConfirm: () => {
        deleteBrandMemberBadge({
          variables: {
            input: { id: memberBadge.id },
          },
        })
          .then(() => {
            toast.success('Member badge deleted');
          })
          .catch((error) => {
            toast.error(logAndFormatError(error));
          });
      },
    });
  };

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Actions</TableHead>
          <TableHead>Name</TableHead>
          <TableHead>Badge</TableHead>
          <TableHead>Type</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {memberBadges.map((memberBadge) => (
          <TableRow key={memberBadge.id}>
            <TableCell className="space-x-2">
              {memberBadge.type === 'GROUP' && (
                <Button
                  icon={<LuTrash2 />}
                  size="round"
                  color="transparent"
                  onClick={() => onDelete(memberBadge)}
                  disabled={deleteLoading}
                />
              )}
              <MemberBadgeDialog memberBadge={memberBadge}>
                <Button icon={<LuPencil />} size="round" color="transparent" />
              </MemberBadgeDialog>
            </TableCell>
            <TableCell>{memberBadge.name}</TableCell>
            <TableCell>
              <MemberBadge badge={memberBadge} />
            </TableCell>
            <TableCell>{TYPE_MAP[memberBadge.type]}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
