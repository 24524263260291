import { ErrorableLoader } from '@addglowapp/components';
import { useGetBrandEmailSettingsQuery } from '@src/generated/graphql';
import { useBrandId } from '@src/hooks/useBrandId';
import { ReplyToEmailSection } from './ReplyToEmailSection';
import { WeeklyDigestSection } from './WeeklyDigestSection';

export function EmailsPage(): JSX.Element {
  const brandId = useBrandId();
  const { data, error } = useGetBrandEmailSettingsQuery({
    variables: { brandId },
  });

  if (!data) {
    return <ErrorableLoader error={error} />;
  }

  return (
    <div className="space-y-4">
      <h1>Emails</h1>
      <ReplyToEmailSection brand={data.brand} />
      <WeeklyDigestSection brand={data.brand} />
    </div>
  );
}
