import { Button, SelectField } from '@addglowapp/components';
import {
  Modal,
  TextAreaInputField,
  TextInputField,
} from '@addglowapp/components';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { CustomTraitGroupFragment } from 'src/generated/graphql';

const createCustomTraitFormSchema = z
  .object({
    title: z.string().trim().min(1).max(25),
    type: z.enum(['TEXT_INPUT', 'SELECT']),
    options: z.string().nullish(),
  })
  .superRefine((data, ctx) => {
    if (data.type === 'SELECT') {
      if (data.options == null) {
        ctx.addIssue({
          path: ['options'],
          message: 'Options are required for dropdown type',
          code: 'custom',
        });
      } else {
        const optionsArray = data.options
          .split(',')
          .map((option) => option.trim());
        // Validate that each option is non-empty after trimming
        const isValid = optionsArray.every((option) => option.length > 0);

        if (!isValid) {
          ctx.addIssue({
            path: ['options'],
            message: 'Options must be non-empty and separated by commas',
            code: 'custom',
          });
        }
      }
    }
  });

export type CreateCustomTraitFormSchema = z.infer<
  typeof createCustomTraitFormSchema
>;

interface Props {
  isModalOpen: boolean;
  onClose: () => void;
  group: CustomTraitGroupFragment;
  traitId?: string | null;
  onSubmit(
    data: CreateCustomTraitFormSchema,
    groupId: string,
    traitId?: string | null,
  ): Promise<void>;
}

export default function CreateTraitModal({
  isModalOpen,
  onClose,
  group,
  traitId,
  onSubmit,
}: Props): JSX.Element {
  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting },
    watch,
  } = useForm<CreateCustomTraitFormSchema>({
    resolver: zodResolver(createCustomTraitFormSchema),
  });

  useEffect(() => {
    if (traitId) {
      const trait = group.traits.find((trait) => trait.id === traitId);

      reset({
        title: trait?.title,
        options:
          trait?.selectOptions
            .slice()
            .sort((a, b) => a.order - b.order)
            .map((option) => option.text)
            .join(', ') ?? '',
        type: trait?.type ?? 'SELECT',
      });
    } else {
      reset({
        title: '',
        options: '',
        type: 'SELECT',
      });
    }
  }, [traitId, reset]);

  const types = [
    {
      label: 'Plain Text Input',
      value: 'TEXT_INPUT',
    },
    {
      label: 'Dropdown List',
      value: 'SELECT',
    },
  ];

  const type = watch('type');

  const handleCloseModal = (): void => {
    reset();
    onClose();
  };

  const handleSubmitData = (
    data: CreateCustomTraitFormSchema,
  ): Promise<void> => {
    return onSubmit(data, group.id, traitId);
  };

  return (
    <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
      <form onSubmit={handleSubmit(handleSubmitData)}>
        <Modal.Header className="justify-center" onClose={handleCloseModal}>
          {traitId ? `Edit trait` : `Add trait to ${group?.title}`}
        </Modal.Header>
        <Modal.Body className="space-y-4">
          <TextInputField.Controller
            control={control}
            label="Title"
            name="title"
          />
          <SelectField.Controller
            control={control}
            label="Trait Type"
            name="type"
            options={types}
            placeholder="Select a trait type"
          />
          {type === 'SELECT' && (
            <TextAreaInputField.Controller
              control={control}
              label="Options (Items should be separated by a comma)"
              name="options"
              disabled={!!traitId}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button color="secondary" type="button" onClick={onClose}>
            Cancel
          </Button>
          <Button
            disabled={isSubmitting}
            color="secondary-selected"
            type="submit"
          >
            {traitId ? 'Save' : 'Add'}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
