import { ErrorableLoader } from '@addglowapp/components';
import { z } from 'zod';
import { useBrandFontOptionsQuery, useBrandQuery } from 'src/generated/graphql';
import { useBrandId } from 'src/hooks/useBrandId';
import WelcomeMessageSection from './WelcomeMessageSection';
import WelcomeModalSection from './WelcomeModalSection';

export const submitNameAndLinksFormSchema = z.object({
  name: z.string().trim().min(1),
  shopUrl: z.string().nullish(),
  headerButtonIcon: z.string(),
  headerButtonText: z.string(),
});
export type SubmitNameAndLinksFormData = z.infer<
  typeof submitNameAndLinksFormSchema
>;

function WelcomeMessagesPage(): JSX.Element {
  const brandId = useBrandId();

  const { data, error } = useBrandQuery({
    variables: {
      id: brandId,
    },
  });
  const { data: fontOptionsData, error: fontOptionsError } =
    useBrandFontOptionsQuery();

  if (!data || !fontOptionsData) {
    return <ErrorableLoader error={error || fontOptionsError} />;
  }

  return (
    <div>
      <h1>Theming</h1>
      <div className="mt-2 space-y-2 md:space-y-4">
        <WelcomeMessageSection brand={data.brand} brandId={brandId} />
        <WelcomeModalSection brand={data.brand} brandId={brandId} />
      </div>
    </div>
  );
}

export default WelcomeMessagesPage;
