import {
  Button,
  DataTableColumnHeader,
  ErrorableLoader,
} from '@addglowapp/components';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useState, useEffect } from 'react';
import { HiOutlineExternalLink } from 'react-icons/hi';
import { useParams } from 'react-router-dom';
import {
  BrandMemberRole,
  useGetMemberGroupQuery,
} from '@src/generated/graphql';
import { useBrandId } from '@src/hooks/useBrandId';
import { logAndFormatError } from '@src/services/error-formatter';
import { BrandMemberActionMenu } from '../components/BrandMemberActionMenu';
import { BrandMemberRoleBadge } from '../components/BrandMemberRoleBadge';
import { DataTable } from '../data-table';
import { BrandMemberData } from '../types';
import { ManageMembersDialog } from './ManageMembersDialog';

// Define the column helper based on the member data structure
const columnHelper = createColumnHelper<BrandMemberData>();

const columns = (
  refetch: () => void,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): ColumnDef<BrandMemberData, any>[] => [
  columnHelper.display({
    id: 'actions',
    meta: {
      name: 'Actions',
    },
    header: 'Actions',
    cell: ({ row }) => {
      return (
        <BrandMemberActionMenu
          brandMember={row.original}
          groupId={row.original.groupId}
          refetch={refetch}
        />
      );
    },
  }),
  columnHelper.accessor('username', {
    meta: { name: 'Username' },
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Username" />
    ),
    cell: (info) => (
      <a
        className="flex flex-row items-center text-brand-text-content underline"
        href={`/profile/${info.getValue() as string}`}
      >
        {info.getValue()} <HiOutlineExternalLink className="ml-1" />
      </a>
    ),
  }),
  columnHelper.accessor('role', {
    meta: { name: 'Role' },
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Role" />
    ),
    cell: (info) => (
      <BrandMemberRoleBadge
        role={info.getValue() as BrandMemberRole}
        isBanned={info.row.original.isBanned}
        moderatedRooms={info.row.original.userModeratedRooms}
      />
    ),
  }),
  columnHelper.accessor('email', {
    meta: { name: 'Email' },
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Email" />
    ),
  }),
  columnHelper.accessor('dateJoined', {
    meta: { name: 'Date Joined' },
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Date Joined" />
    ),
    cell: (info) => new Date(info.getValue() as string).toLocaleDateString(),
  }),
];

export function GroupDetailsPage(): JSX.Element {
  const { groupId } = useParams<{ groupId: string }>();
  const brandId = useBrandId();
  const { data, error, refetch } = useGetMemberGroupQuery({
    variables: { groupId: groupId ?? '', brandId },
    skip: !groupId,
  });

  const [tableData, setTableData] = useState<BrandMemberData[]>([]);

  // Extract current member IDs for the group
  const currentMemberIds = tableData.map((member) => member.userId);

  function handleRefetch(): void {
    refetch().catch((e) => {
      logAndFormatError(e);
    });
  }
  useEffect(() => {
    if (data?.memberGroup?.memberGroupMembers) {
      const members = data.memberGroup.memberGroupMembers.map((member) => ({
        userId: member.brandMember?.user?.id,
        username: member.brandMember?.user?.username,
        role: member.brandMember?.role,
        email: member.brandMember?.user?.email,
        dateJoined: member.brandMember?.createdAt,
        label: member.brandMember?.labels[0]?.labelOption,
        isBanned: member.brandMember?.isBanned ?? false,
        groupId: groupId,
        groupMembershipIds: member.brandMember?.groupMemberships.map(
          (g) => g.memberGroupId,
        ),
        userModeratedRooms: member.brandMember?.user.userModeratedRooms,
      }));
      setTableData(members as BrandMemberData[]);
    }
  }, [data, groupId]);

  if (!data) {
    return <ErrorableLoader error={error} />;
  }

  return (
    <div className="max-w-4xl space-y-4">
      <h1>Members of {data.memberGroup?.name}</h1>
      <div className="flex flex-row justify-end">
        {groupId && (
          <ManageMembersDialog
            groupId={groupId}
            groupName={data.memberGroup?.name}
            currentMembers={currentMemberIds}
            onAddMember={refetch}
          >
            <Button>Add Members</Button>
          </ManageMembersDialog>
        )}
      </div>
      <DataTable columns={columns(handleRefetch)} data={tableData} />
    </div>
  );
}
