import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import AdminLayout from 'src/components/AdminLayout';
import { RequireAuth } from '@src/components';
import { useSession } from '../hooks/useSession';
import { identifySentryUser } from '../services/sentry';
import AuthRoutes from './auth';
import BrandRoutes from './brand/index.page';
import HomePage from './home.page';
import NotFoundPage from './NotFound.page';
import { TrialRoutes } from './trial/routes';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

function PagesRoot(): JSX.Element {
  const { userId } = useSession();

  useEffect(() => {
    if (!userId) return;

    identifySentryUser({
      id: userId,
    });
  }, [userId]);

  return (
    <SentryRoutes>
      <Route path="*" element={<NotFoundPage />} />
      <Route path="auth/*" element={<AuthRoutes />} />
      <Route path="trial/*" element={<TrialRoutes />} />
      <Route
        element={
          <RequireAuth>
            <Outlet />
          </RequireAuth>
        }
      >
        <Route index element={<HomePage />} />
        <Route element={<AdminLayout />}>
          <Route path=":brandId/*" element={<BrandRoutes />} />
        </Route>
      </Route>
    </SentryRoutes>
  );
}

export default PagesRoot;
