import {
  Dropdown,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  useConfirmDialog,
} from '@addglowapp/components';
import { toast } from '@addglowapp/components';
import clsx from 'clsx';
import { BsThreeDots } from 'react-icons/bs';
import {
  BrandWebhookFragment,
  GetBrandWebhooksDocument,
  useDeleteBrandWebhookMutation,
  useUpdateBrandWebhookMutation,
} from '@src/generated/graphql';
import { useBrandId } from '@src/hooks/useBrandId';
import { logAndFormatError } from '@src/services/error-formatter';
import { formatDate } from '@src/utils/date';
import { StatusBadge } from './StatusBadge';

interface BrandWebhookTableProps {
  className?: string;
  brandWebhooks: BrandWebhookFragment[];
  onEdit: (brandWebhook: BrandWebhookFragment) => void;
  onShow: (brandWebhook: BrandWebhookFragment) => void;
}

export function BrandWebhookTable({
  className,
  brandWebhooks,
  onEdit,
  onShow,
}: BrandWebhookTableProps): JSX.Element {
  const brandId = useBrandId();
  const [deleteWebhook] = useDeleteBrandWebhookMutation({
    refetchQueries: [
      {
        query: GetBrandWebhooksDocument,
        variables: {
          brandId,
        },
      },
    ],
  });
  const [updateWebhook] = useUpdateBrandWebhookMutation();

  const { requestConfirm } = useConfirmDialog();

  const handleDelete = (brandWebhook: BrandWebhookFragment): void => {
    deleteWebhook({
      variables: {
        input: { id: brandWebhook.id },
      },
    })
      .then(() => {
        toast.success('API Key deleted successfully');
      })
      .catch((err) => {
        toast.error(logAndFormatError(err));
      });
  };

  const handleUpdate = (id: string, isActive: boolean): void => {
    updateWebhook({
      variables: {
        input: {
          id,
          data: {
            isActive,
          },
        },
      },
    })
      .then(() => {
        toast.success(
          `Webhook ${isActive ? 'activated' : 'deactivated'} successfully`,
        );
      })
      .catch((err) => {
        toast.error(logAndFormatError(err));
      });
  };

  return (
    <Table className={clsx(className, 'table-fixed')}>
      <TableHeader>
        <TableRow>
          <TableHead className="w-1/12">Actions</TableHead>
          <TableHead className="w-1/3">Description</TableHead>
          <TableHead className="w-1/3">Endpoint URL</TableHead>
          <TableHead className="w-1/3">Status</TableHead>
          <TableHead className="w-1/4">Created At</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {brandWebhooks.map((brandWebhook) => (
          <TableRow key={brandWebhook.id}>
            <TableCell>
              <Dropdown>
                <Dropdown.Trigger>
                  <BsThreeDots />
                </Dropdown.Trigger>
                <Dropdown.Content>
                  <Dropdown.Item
                    onClick={() => {
                      onEdit(brandWebhook);
                    }}
                  >
                    Edit
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      onShow(brandWebhook);
                    }}
                  >
                    View Secret
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      requestConfirm({
                        title: 'Delete Webhook',
                        content:
                          'Are you sure you want to delete this webhook?',
                        buttonConfirmText: 'Delete',
                        onConfirm: () => handleDelete(brandWebhook),
                      });
                    }}
                  >
                    Delete
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      handleUpdate(brandWebhook.id, !brandWebhook.isActive);
                    }}
                  >
                    {brandWebhook.isActive ? 'Deactivate' : 'Activate'}
                  </Dropdown.Item>
                </Dropdown.Content>
              </Dropdown>
            </TableCell>
            <TableCell>{brandWebhook.description}</TableCell>
            <TableCell className="font-mono">
              {brandWebhook.endpointUrl}
            </TableCell>
            <TableCell>
              <StatusBadge
                status={brandWebhook.isActive ? 'active' : 'inactive'}
              />
            </TableCell>
            <TableCell>{formatDate(brandWebhook.createdAt)}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
