import { ErrorableLoader } from '@addglowapp/components';
import { addDays, differenceInDays, parseISO, startOfDay } from 'date-fns';
import { useMemo, useState } from 'react';
import { IconType } from 'react-icons';
import { BiCommentAdd, BiCommentDots } from 'react-icons/bi';
import { FiShare } from 'react-icons/fi';
import {
  HiOutlineHeart,
  HiOutlineShoppingCart,
  HiOutlineUser,
} from 'react-icons/hi';
import {
  AnalyticsCountType,
  useGetBrandAnalyticsQuery,
} from 'src/generated/graphql';
import { useBrandId } from 'src/hooks/useBrandId';
import { convertToDate, formatDateWithoutTime } from 'src/utils/date';
import AnalyticsFigure from './components/AnalyticsFigure';
import { DateRange, DateRangePicker } from './components/DateRangePicker';

interface CountTypeInfo {
  label: string;
  icon: IconType;
}

// we're excluding these two types because it's more accurate to use Plausible
type AnalyticsCountTypeFiltered = Exclude<
  AnalyticsCountType,
  'HOME_VIEWED' | 'HOME_VIEWED_UNIQUE'
>;

const ANALYTICS_COUNT_TYPES: Record<AnalyticsCountTypeFiltered, CountTypeInfo> =
  {
    USER_JOINED: { label: 'Member Signups', icon: HiOutlineUser },
    COMMENT_CREATED: { label: 'Comments', icon: BiCommentAdd },
    POST_CREATED: { label: 'Posts', icon: BiCommentDots },
    POST_LIKED: { label: 'Post Likes', icon: HiOutlineHeart },
    POST_SHARED: { label: 'Post Shares', icon: FiShare },
    POST_VIEWED: { label: 'Post Views', icon: BiCommentDots },
    PROFILE_VIEWED: { label: 'Profile Views', icon: HiOutlineUser },
    SHOP_OPENED: { label: 'Shop Link Opens', icon: HiOutlineShoppingCart },
  };

function generateDateRanges(): { range: DateRange; label: string }[] {
  const yesterday = addDays(startOfDay(new Date()), -1);
  return [
    { label: 'Yesterday', range: { start: yesterday, end: yesterday } },
    {
      label: 'Last 7 days',
      range: { start: addDays(yesterday, -7), end: yesterday },
    },
    {
      label: 'Last 30 days',
      range: { start: addDays(yesterday, -30), end: yesterday },
    },
  ];
}

function AnalyticsOverviewPage(): JSX.Element {
  const brandId = useBrandId();
  const dateRanges = useMemo(() => generateDateRanges(), []);
  const [selectedDateRange, setSelectedDateRange] = useState<DateRange>(
    dateRanges[1].range,
  );

  const dateRangeDays = differenceInDays(
    selectedDateRange.end,
    selectedDateRange.start,
  );

  const { data, error } = useGetBrandAnalyticsQuery({
    variables: {
      brandId,
      startDate: convertToDate(
        addDays(selectedDateRange.start, -1 - dateRangeDays),
      ),
      endDate: convertToDate(selectedDateRange.end),
    },
  });

  const aggregatedData = useMemo(() => {
    if (!data) {
      return null;
    }
    const countTypes = Object.keys(
      ANALYTICS_COUNT_TYPES,
    ) as AnalyticsCountTypeFiltered[];

    const currentDateRange = selectedDateRange;
    const previousDateRange = {
      start: addDays(currentDateRange.start, -1 - dateRangeDays),
      end: addDays(currentDateRange.start, -1),
    };
    const analyticsCounts = data.brand.analyticsEventCounts;
    return countTypes.map((type) => {
      const counts = analyticsCounts.filter((item) => item.type === type);

      function countForDateRange(dateRange: DateRange): number {
        return counts
          .filter(
            (item) =>
              parseISO(item.date) >= dateRange.start &&
              parseISO(item.date) <= dateRange.end,
          )
          .reduce((acc, item) => acc + item.count, 0);
      }

      return {
        currentPeriodCount: countForDateRange(currentDateRange),
        previousPeriodCount: countForDateRange(previousDateRange),
        type,
      };
    });
  }, [data, selectedDateRange, dateRangeDays]);

  return (
    <div className="space-y-8">
      <h1>Event Analytics</h1>
      <div className="flex max-w-5xl items-center justify-between">
        <p>
          These show user events{' '}
          {selectedDateRange.start === selectedDateRange.end
            ? `on ${formatDateWithoutTime(selectedDateRange.start)}`
            : `between ${formatDateWithoutTime(
                selectedDateRange.start,
              )} and ${formatDateWithoutTime(selectedDateRange.end)}`}
        </p>
        <div className="flex items-center space-x-2">
          <div>
            <strong>Filter by date:</strong>
          </div>
          <DateRangePicker
            presetOptions={dateRanges}
            value={selectedDateRange}
            onChange={setSelectedDateRange}
          />
        </div>
      </div>
      {!aggregatedData ? (
        <ErrorableLoader error={error} />
      ) : (
        <div className="grid max-w-5xl grid-cols-3 gap-8 lg:grid-cols-4">
          {aggregatedData.map((item) => (
            <AnalyticsFigure
              key={item.type}
              label={ANALYTICS_COUNT_TYPES[item.type].label}
              icon={ANALYTICS_COUNT_TYPES[item.type].icon}
              currentPeriodCount={item.currentPeriodCount}
              previousPeriodCount={item.previousPeriodCount}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default AnalyticsOverviewPage;
