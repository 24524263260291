import { Button } from '@addglowapp/components';
import { Alert } from '@addglowapp/components';
import { toast } from '@addglowapp/components';
import { useState } from 'react';
import LayoutSection from 'src/components/LayoutSection';
import {
  BrandShopifyIntegrationFragment,
  useSetShopifyProductSyncEnabledMutation,
} from 'src/generated/graphql';
import { logAndFormatError } from 'src/services/error-formatter';
import { getApolloErrorCode } from '@src/utils/apollo-error';

interface ShopifyProductSyncSectionProps {
  className?: string;
  shopifyIntegration: BrandShopifyIntegrationFragment;
}

export function ShopifyProductSyncSection({
  className,
  shopifyIntegration,
}: ShopifyProductSyncSectionProps): React.JSX.Element {
  const [setShopifyProductSyncEnabled, { loading }] =
    useSetShopifyProductSyncEnabledMutation();

  const [showNewScopeRequired, setShowNewScopeRequired] = useState(false);

  const appShopifyUrl = shopifyIntegration.shopUrl
    ? `https://${shopifyIntegration.shopUrl}/admin/apps/${shopifyIntegration.shopifyAppName}`
    : undefined;

  const isSyncEnabled = shopifyIntegration.isProductSyncEnabled;

  const handleSetSyncEnabled = async (enabled: boolean): Promise<void> => {
    try {
      await setShopifyProductSyncEnabled({
        variables: {
          input: {
            brandId: shopifyIntegration.id,
            enabled,
          },
        },
      });
      toast.success(
        `Shopify product sync was successfully ${
          enabled ? 'enabled' : 'disabled'
        }`,
      );
    } catch (error) {
      if (
        getApolloErrorCode(error, ['SHOPIFY_SCOPE_MISSING']) ===
        'SHOPIFY_SCOPE_MISSING'
      ) {
        setShowNewScopeRequired(true);
        toast.error(
          'Your Shopify integration does not have sufficient permissions. Please visit your app settings to update your permissions.',
        );
        return;
      }
      toast.error(logAndFormatError(error));
    }
  };

  const isEnabled = shopifyIntegration.isConnected;

  return (
    <LayoutSection className={className}>
      <LayoutSection.Header
        title="Shopify Product Sync"
        actions={
          <Button
            color="primary"
            onClick={() => handleSetSyncEnabled(!isSyncEnabled)}
            disabled={loading || !isEnabled}
          >
            {isSyncEnabled ? 'Disable' : 'Enable'}
          </Button>
        }
      />
      <LayoutSection.Body>
        <div className="flex max-w-3xl flex-col space-y-4">
          {showNewScopeRequired && (
            <Alert type="warning">
              You need to add the product scope to your integration in order to
              enable product sync. You can do this by accessing{' '}
              <a href={appShopifyUrl} target="_blank" rel="noreferrer">
                the app in your Shopify store
              </a>{' '}
              and granting the app additional permission.
            </Alert>
          )}
          {!isEnabled && (
            <Alert type="info">
              You must connect your Shopify store before enabling product sync.
            </Alert>
          )}
          <p>
            You can enable Product Sync to synchronize products that are
            published on your Shopify store to your AddGlow community. This
            enables customers to tag your products in their posts.
          </p>
          <p>
            Sync will happen immediately upon enabling and occur every hour.
          </p>
        </div>
      </LayoutSection.Body>
    </LayoutSection>
  );
}
