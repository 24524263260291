import { Button } from '@addglowapp/components';
import { toast } from '@addglowapp/components';
import { zodResolver } from '@hookform/resolvers/zod';
import clsx from 'clsx';
import Color from 'color';
import _ from 'lodash';
import { useMemo } from 'react';
import { Control, useForm, useWatch } from 'react-hook-form';
import LayoutSection from 'src/components/LayoutSection';
import { BrandFragment, useUpdateBrandMutation } from 'src/generated/graphql';
import { logAndFormatError } from 'src/services/error-formatter';
import BrandPreview from './BrandPreview';
import ColorInput from './ColorInput';
import {
  BrandColorsFormData,
  brandColorsSchema,
  BRAND_COLOR_KEYS,
  DEFAULT_BRAND_COLORS,
} from './colors';

interface Props {
  brand: BrandFragment;
  brandId: string;
}

function convertToRgb(
  data: Partial<BrandColorsFormData>,
): Partial<BrandColorsFormData> {
  return _.mapValues(data, (value) => {
    try {
      return new Color(value).rgb().array().join(' ');
    } catch {
      return null;
    }
  }) as BrandColorsFormData;
}

function ColorPreview({
  className,
  control,
  brand,
}: {
  className?: string;
  control: Control<BrandColorsFormData>;
  brand: BrandFragment;
}): JSX.Element {
  const liveColors = useWatch({ control });
  const selectedColors = useMemo(() => convertToRgb(liveColors), [liveColors]);
  return (
    <BrandPreview
      className={clsx(
        'flex h-96 w-96 flex-col items-center justify-between bg-brand-background-page p-4',
        className,
      )}
      colors={selectedColors}
      contentFont={brand.contentFont}
    >
      <div className="flex w-full flex-row justify-end border p-2">
        <Button color="tertiary">Tertiary</Button>
      </div>
      <p className="w-full p-4 text-sm text-brand-text-page">
        <strong>Home</strong> &gt; Posts{' '}
      </p>
      <div className="flex h-48 w-64 flex-col justify-between space-x-2 rounded-lg border border-brand-border bg-brand-background-content p-4 shadow-md">
        <p className="text-brand-text-content">
          Hi there,
          <br />
          <br />
          This is a preview of the content text.
        </p>
        <div className="space-x-2">
          <Button color="primary">Primary</Button>
          <Button color="secondary">Secondary</Button>
        </div>
      </div>
    </BrandPreview>
  );
}

function convertBrandToColors(brand: BrandFragment): BrandColorsFormData {
  return BRAND_COLOR_KEYS.reduce(
    (acc, key) => ({
      ...acc,
      [key]: brand[key]
        ? new Color(`rgb(${brand[key]})`).hex()
        : DEFAULT_BRAND_COLORS[key],
    }),
    {} as BrandColorsFormData,
  );
}

export default function ColorsSection({ brand, brandId }: Props): JSX.Element {
  const defaultValues = useMemo(() => convertBrandToColors(brand), [brand]);

  const {
    handleSubmit,
    control,
    formState: { isSubmitting, isDirty },
    reset,
  } = useForm<BrandColorsFormData>({
    resolver: zodResolver(brandColorsSchema),
    defaultValues,
  });

  const [updateBrand] = useUpdateBrandMutation();
  const onSubmit = async (data: BrandColorsFormData): Promise<void> => {
    const brandColorsConverted = convertToRgb(data);
    updateBrand({
      variables: {
        input: {
          id: brandId,
          data: brandColorsConverted,
        },
      },
    })
      .then((result) => {
        const updatedBrand = result.data?.updateBrand.brand;
        if (updatedBrand) {
          reset(convertBrandToColors(updatedBrand));
        }
        toast.success('Colors updated successfully.');
      })
      .catch((error) => {
        toast.error(
          logAndFormatError(error, 'Sorry, the action could not be completed.'),
        );
      });
  };

  return (
    <LayoutSection>
      <form onSubmit={handleSubmit(onSubmit)}>
        <LayoutSection.Header
          title="Colors"
          actions={
            <div className="flex space-x-2">
              <Button
                color="secondary"
                onClick={() => {
                  reset(defaultValues);
                }}
                disabled={isSubmitting || !isDirty}
              >
                Reset
              </Button>
              <Button
                color="primary"
                type="submit"
                disabled={isSubmitting || !isDirty}
              >
                Save
              </Button>
            </div>
          }
        />
        <LayoutSection.Body>
          <div className="flex flex-row justify-between space-x-8">
            <div className="flex flex-1 flex-col space-y-8">
              <h3>Content</h3>
              <div className="grid gap-4 lg:grid-cols-2 xl:grid-cols-3">
                <ColorInput.LabelledController
                  name="primaryBackgroundColor"
                  label="Brand/Primary Button Background"
                  control={control}
                />
                <ColorInput.LabelledController
                  name="pageBackgroundColor"
                  label="Page Background"
                  control={control}
                />
                <ColorInput.LabelledController
                  name="pageTextColor"
                  label="Page Text Color"
                  control={control}
                />
                <ColorInput.LabelledController
                  name="contentBackgroundColor"
                  control={control}
                  label="Content Background"
                />
                <ColorInput.LabelledController
                  name="contentTextColor"
                  control={control}
                  label="Content Text"
                />
                <ColorInput.LabelledController
                  name="borderColor"
                  label="Border Color"
                  control={control}
                />
              </div>
              <h3>Buttons</h3>
              <div className="grid gap-4 lg:grid-cols-2 xl:grid-cols-3">
                <ColorInput.LabelledController
                  name="primaryTextColor"
                  label="Primary Button Label"
                  control={control}
                />
                <ColorInput.LabelledController
                  name="secondaryTextColor"
                  label="Secondary Button Label"
                  control={control}
                />
                <ColorInput.LabelledController
                  name="secondaryBackgroundColor"
                  control={control}
                  label="Secondary Button Background"
                />
                <ColorInput.LabelledController
                  name="tertiaryTextColor"
                  control={control}
                  label="Tertiary Button Label"
                />
                <ColorInput.LabelledController
                  name="tertiaryBackgroundColor"
                  label="Tertiary Button Background"
                  control={control}
                />
              </div>
            </div>
            <ColorPreview className="flex-1" control={control} brand={brand} />
          </div>
        </LayoutSection.Body>
      </form>
    </LayoutSection>
  );
}
