import { z } from 'zod';
import { BrandFragment } from 'src/generated/graphql';

export const COLOR_VALIDATION = z
  .string()
  .regex(
    /^#([A-Fa-f0-9]{6})$/,
    'Please provide a valid hex color code, e.g. #FFFFFF',
  );

export const brandColorsSchema = z.object({
  primaryBackgroundColor: COLOR_VALIDATION,
  primaryTextColor: COLOR_VALIDATION,
  secondaryBackgroundColor: COLOR_VALIDATION,
  secondaryTextColor: COLOR_VALIDATION,
  tertiaryBackgroundColor: COLOR_VALIDATION,
  tertiaryTextColor: COLOR_VALIDATION,
  pageBackgroundColor: COLOR_VALIDATION,
  pageTextColor: COLOR_VALIDATION,
  contentBackgroundColor: COLOR_VALIDATION,
  contentTextColor: COLOR_VALIDATION,
  borderColor: COLOR_VALIDATION,
});

export type BrandColorsFormData = z.infer<typeof brandColorsSchema>;

export type BrandColorType = keyof BrandColorsFormData;

export type BrandColorsData = Pick<BrandFragment, BrandColorType>;

export const DEFAULT_BRAND_COLORS: Record<BrandColorType, string> = {
  primaryBackgroundColor: '#5647AE',
  primaryTextColor: '#FFFFFF',
  secondaryBackgroundColor: '#FFFFFF',
  secondaryTextColor: '#000000',
  tertiaryBackgroundColor: '#FFFFFF',
  tertiaryTextColor: '#000000',
  pageBackgroundColor: '#F3F4FD',
  pageTextColor: '#000000',
  contentBackgroundColor: '#FFFFFF',
  contentTextColor: '#000000',
  borderColor: '#EAEAEA',
};

export const BRAND_COLOR_KEYS = Object.keys(
  DEFAULT_BRAND_COLORS,
) as BrandColorType[];

const BRAND_COLOR_VARIABLES: Record<BrandColorType, string> = {
  primaryBackgroundColor: '--color-primary-background',
  secondaryBackgroundColor: '--color-secondary-background',
  tertiaryBackgroundColor: '--color-tertiary-background',
  pageBackgroundColor: '--color-page-background',
  pageTextColor: '--color-page-text',
  contentBackgroundColor: '--color-content-background',
  borderColor: '--color-border',
  contentTextColor: '--color-content-text',
  primaryTextColor: '--color-primary-text',
  secondaryTextColor: '--color-secondary-text',
  tertiaryTextColor: '--color-tertiary-text',
};

export function convertToCssVariables(
  colors: BrandColorsData,
): React.CSSProperties {
  return BRAND_COLOR_KEYS.reduce(
    (acc, key) => ({
      ...acc,
      [BRAND_COLOR_VARIABLES[key]]: colors[key] || DEFAULT_BRAND_COLORS[key],
    }),
    {},
  );
}
