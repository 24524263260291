import { Badge } from '@addglowapp/components';
import { useState } from 'react';
import { BrandMemberRole, RoomOptionFragment } from '@src/generated/graphql';

interface BrandMemberRoleBadgeProps {
  role: BrandMemberRole;
  isBanned: boolean;
  moderatedRooms: RoomOptionFragment[];
}

export function BrandMemberRoleBadge({
  role,
  isBanned,
  moderatedRooms = [],
}: BrandMemberRoleBadgeProps): JSX.Element {
  const [showTooltip, setShowTooltip] = useState(false);

  if (isBanned) {
    return <Badge variant="destructive">Banned</Badge>;
  }

  if (moderatedRooms.length > 0) {
    return (
      <div
        className="relative inline-block"
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        <Badge variant="default">Room Moderator</Badge>
        {showTooltip && (
          <div className="absolute left-0 z-50 mt-2 w-48 rounded bg-gray-800 p-2 text-sm text-white shadow-lg">
            {moderatedRooms.length > 0
              ? `Can moderate: ${moderatedRooms.map((room) => room.name).join(', ')}`
              : 'No rooms assigned'}
          </div>
        )}
      </div>
    );
  }

  switch (role) {
    case 'ADMIN':
      return <Badge variant="default">Admin</Badge>;
    case 'MODERATOR':
      return <Badge variant="secondary">Moderator</Badge>;
    case 'CUSTOMER':
      return <Badge variant="outline">Customer</Badge>;
    default:
      return <Badge variant="outline">Customer</Badge>;
  }
}
