import { Button, EmptyDisplay } from '@addglowapp/components';
import { ErrorableLoader } from '@addglowapp/components';
import { useState } from 'react';
import {
  BrandWebhookFragment,
  useGetBrandWebhooksQuery,
} from 'src/generated/graphql';
import { useBrandId } from 'src/hooks/useBrandId';
import { BrandWebhookTable } from './BrandWebhookTable';
import { WebhookEditModal } from './WebhookEditModal';
import { WebhookShowModal } from './WebhookShowModal';

const MAX_WEBHOOKS = 10;

export function WebhooksPage(): JSX.Element {
  const brandId = useBrandId();
  const { data, error } = useGetBrandWebhooksQuery({
    variables: {
      brandId,
    },
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedWebhook, setSelectedWebhook] =
    useState<BrandWebhookFragment | null>(null);
  const [isWebhookShowModalOpen, setIsWebhookShowModalOpen] = useState(false);
  const [shownWebhook, setShownWebhook] = useState<
    BrandWebhookFragment | undefined
  >(undefined);

  if (!data) {
    return <ErrorableLoader error={error} />;
  }

  return (
    <div className="max-w-6xl space-y-2">
      <h1>Webhooks</h1>
      <p>
        Webhooks allow you to receive real-time notifications of events within
        AddGlow. You can create multiple webhooks to listen for different events
        or to use in different environments.
      </p>

      <div className="flex justify-end">
        <Button
          className="btn btn-primary"
          onClick={() => {
            setSelectedWebhook(null);
            setIsModalOpen(true);
          }}
          disabled={data.brandWebhooks.length >= MAX_WEBHOOKS}
        >
          Create New Webhook
        </Button>
      </div>

      {data.brandWebhooks.length === 0 ? (
        <EmptyDisplay
          header="No Webhooks"
          subtitle="There are no webhooks for this brand yet. Please create one above to get started."
        />
      ) : (
        <BrandWebhookTable
          onEdit={(webhook) => {
            setSelectedWebhook(webhook);
            setIsModalOpen(true);
          }}
          onShow={(webhook) => {
            setShownWebhook(webhook);
            setIsWebhookShowModalOpen(true);
          }}
          brandWebhooks={data.brandWebhooks}
        />
      )}

      <WebhookEditModal
        isOpen={isModalOpen}
        onOpenChange={(isOpen) => {
          setIsModalOpen(isOpen);
          if (!isOpen) {
            setSelectedWebhook(null);
          }
        }}
        existingWebhook={selectedWebhook}
        onWebhookCreated={(webhook) => {
          setShownWebhook(webhook);
          setIsWebhookShowModalOpen(true);
        }}
      />
      <WebhookShowModal
        isOpen={isWebhookShowModalOpen}
        onOpenChange={setIsWebhookShowModalOpen}
        brandWebhook={shownWebhook}
      />
    </div>
  );
}
