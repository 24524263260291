import { Button } from '@addglowapp/components';
import { TextInputField } from '@addglowapp/components';
import { toast } from '@addglowapp/components';
import LayoutSection from 'src/components/LayoutSection';
import {
  BrandShopifyIntegrationFragment,
  useDisconnectBrandShopifyApiKeyMutation,
  useGenerateBrandShopifyApiKeyMutation,
} from 'src/generated/graphql';
import { logAndFormatError } from 'src/services/error-formatter';

interface ShopifyApiKeySectionProps {
  className?: string;
  shopifyIntegration?: BrandShopifyIntegrationFragment | null;
  brandId: string;
}

export function ShopifyApiKeySection({
  className,
  shopifyIntegration,
  brandId,
}: ShopifyApiKeySectionProps): JSX.Element {
  const [generateBrandShopifyApiKey, { loading: isGenerating }] =
    useGenerateBrandShopifyApiKeyMutation();
  const [disconnectBrandShopifyApiKey] =
    useDisconnectBrandShopifyApiKeyMutation();

  const handleGenerateShopifyApiKey = (shouldRegenerate?: boolean): void => {
    generateBrandShopifyApiKey({
      variables: {
        input: {
          brandId,
          shouldRegenerate,
        },
      },
    })
      .then(() => {
        toast.success('Successfully generated Shopify API key!');
      })
      .catch((err) => toast.error(logAndFormatError(err)));
  };

  const handleDisconnectApiKey = (): void => {
    disconnectBrandShopifyApiKey({
      variables: {
        input: {
          brandId,
        },
      },
    })
      .then(() => {
        toast.success('Successfully disconnected Shopify API key!');
      })
      .catch((err) => toast.error(logAndFormatError(err)));
  };

  if (shopifyIntegration) {
    return (
      <LayoutSection className={className}>
        <LayoutSection.Header
          title="Shopify API Key"
          actions={
            <Button
              color="primary"
              onClick={handleDisconnectApiKey}
              disabled={isGenerating}
            >
              Disable Integration
            </Button>
          }
        />
        <LayoutSection.Body>
          <div className="flex max-w-3xl flex-col space-y-4">
            <div className="space-y-4">
              <p>
                In order to use this API key, you will need to go to{' '}
                <strong>
                  <a
                    href="https://apps.shopify.com/addglow-community-app"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Shopify App Store
                  </a>
                </strong>{' '}
                and install the AddGlow app.
              </p>
              <p>
                Once installed, you can insert this API key in the app to
                integrate Shopify with AddGlow.
              </p>
            </div>
            <div className="flex space-x-2">
              <div>
                <strong>Connection Status:</strong>
              </div>
              <div>
                {shopifyIntegration.isConnected ? 'Connected' : 'Disconnected'}
              </div>
            </div>
            <strong>Shopify API Key</strong>
            <TextInputField
              value={shopifyIntegration.apiKey}
              readOnly
              onFocus={(e) => {
                e.target.select();
              }}
            />
          </div>
        </LayoutSection.Body>
      </LayoutSection>
    );
  }

  return (
    <LayoutSection className={className}>
      <LayoutSection.Header
        title="Shopify API Key"
        actions={
          <Button
            color="primary"
            onClick={() => handleGenerateShopifyApiKey(false)}
            disabled={isGenerating}
          >
            Generate API Key
          </Button>
        }
      />
      <LayoutSection.Body>
        <div className="flex max-w-3xl flex-col space-y-4">
          <div className="space-y-4">
            <p>
              By integrating with Shopify, you enable the ability to redeem
              discounts from our loyalty program on your Shopify store and show
              community posts.
            </p>
            <p>
              Click <strong>Generate API Key</strong> to get started
            </p>
          </div>
        </div>
      </LayoutSection.Body>
    </LayoutSection>
  );
}
