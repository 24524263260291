import clsx from 'clsx';
import { IconType } from 'react-icons';

interface Props {
  className?: string;
  icon?: IconType;
  label: string;
  currentPeriodCount: number;
  previousPeriodCount: number;
}

function AnalyticsFigure({
  className,
  icon: Icon,
  label,
  currentPeriodCount,
  previousPeriodCount,
}: Props): JSX.Element {
  const differencePercentage = Math.round(
    ((currentPeriodCount - previousPeriodCount) / previousPeriodCount) * 100,
  );

  function getColoredDifference(number: number, suffix?: string): JSX.Element {
    return (
      <span
        className={clsx('font-bold', {
          'text-green-500': number > 0,
          'text-red-500': number < 0,
        })}
      >
        {number > 0 && '+'}
        {number < 0 && '-'}
        {Math.abs(number)}
        {suffix}
      </span>
    );
  }
  return (
    <div
      className={clsx(
        'flex flex-col space-y-4 rounded-lg border border-brand-border bg-white p-4 shadow-md',
        className,
      )}
    >
      <div className="flex items-center space-x-2 text-sm text-black">
        {Icon && <Icon className="h-4 w-4" />}
        <span>{label}</span>
      </div>
      <div className="space-y-2">
        <div className="text-3xl font-medium">{currentPeriodCount}</div>
        <div className="text-xs">
          {currentPeriodCount === previousPeriodCount ? (
            <>
              <strong>same</strong> as previous time period
            </>
          ) : (
            <>
              {previousPeriodCount < 10
                ? getColoredDifference(currentPeriodCount - previousPeriodCount)
                : getColoredDifference(differencePercentage, '%')}{' '}
              than previous time period
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default AnalyticsFigure;
