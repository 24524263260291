import {
  ConfirmDialog,
  ErrorHandlerContext,
  ErrorHandlerValue,
  Toaster,
} from '@addglowapp/components';
import { Auth0Provider } from '@auth0/auth0-react';
import { useMemo } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ViteComponentProvider } from 'src/components/ViteComponentProvider';
import { formatError, logAndFormatError } from '@src/services/error-formatter';
import { logError } from '@src/services/error-logger';
import { ErrorBoundary } from '../components/ErrorBoundary';
import PagesRoot from '../pages';
import { config } from '../services/config';
import AppApolloProvider from './AppApolloProvider';
import { AppGrowthBookWrapper } from './AppGrowthBookWrapper';

function App(): JSX.Element {
  const errorHandlers = useMemo(
    (): ErrorHandlerValue => ({
      logError,
      logAndFormatError,
      formatError,
    }),
    [],
  );

  return (
    <ErrorBoundary>
      <ErrorHandlerContext.Provider value={errorHandlers}>
        <Auth0Provider
          domain={config.VITE_AUTH0_DOMAIN}
          clientId={config.VITE_AUTH0_CLIENT_ID}
          authorizationParams={{
            redirect_uri: `${window.location.origin}/auth/auth0-callback`,
            audience: config.VITE_AUTH0_AUDIENCE,
            connection: 'email',
            prompt: 'none',
          }}
          skipRedirectCallback
        >
          <AppGrowthBookWrapper>
            <AppApolloProvider>
              <ViteComponentProvider>
                <BrowserRouter>
                  <PagesRoot />
                  <Toaster />
                  <ConfirmDialog />
                </BrowserRouter>
              </ViteComponentProvider>
            </AppApolloProvider>
          </AppGrowthBookWrapper>
        </Auth0Provider>
      </ErrorHandlerContext.Provider>
    </ErrorBoundary>
  );
}

export default App;
