import { Badge, TableRow, TableCell } from '@addglowapp/components';
import { useState } from 'react';
import { BsCheck } from 'react-icons/bs';
import { RoomOptionFragment } from 'src/generated/graphql';
import RoomsActionMenu from './RoomsActionMenu';

interface Props {
  room: RoomOptionFragment;
  onActionComplete(): void;
  brandId: string;
  totalRoomCount: number;
}

export default function RoomRow({
  room,
  onActionComplete,
  brandId,
  totalRoomCount,
}: Props): JSX.Element {
  const [loading, setLoading] = useState(false);
  return (
    <TableRow
      key={room.id}
      className={
        loading
          ? 'pointer-events-none animate-pulse touch-none bg-gray-200'
          : ''
      }
    >
      <TableCell>
        <RoomsActionMenu
          room={room}
          setLoading={setLoading}
          onActionComplete={onActionComplete}
          brandId={brandId}
          totalRoomCount={totalRoomCount}
        />
      </TableCell>
      <TableCell>
        <div>{room.name}</div>
      </TableCell>
      <TableCell>{room.description}</TableCell>
      <TableCell>{room.type}</TableCell>
      <TableCell>
        {room.requiresLogin && <BsCheck className="h-6 w-6" />}
      </TableCell>
      <TableCell className="flex flex-wrap gap-2">
        {room.isRoomRestricted &&
          room.memberGroups.map(({ memberGroup }) => {
            return (
              <Badge key={memberGroup.id} variant="default">
                {memberGroup.name}
              </Badge>
            );
          })}
      </TableCell>
    </TableRow>
  );
}
