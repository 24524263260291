import { Loader, Card, Button } from '@addglowapp/components';
import { useAuth0 } from '@auth0/auth0-react';
import { Navigate } from 'react-router-dom';
import { logError } from 'src/services/error-logger';

export function TrialHomePage(): JSX.Element {
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <Loader />;
  }

  if (isAuthenticated) {
    return <Navigate to="/trial/new" />;
  }

  return (
    <div className="flex h-full flex-1 items-center justify-center">
      <Card padding noMaxWidth className="space-y-8 md:w-[30rem]">
        <h1>Set up your AddGlow Community</h1>
        <p>
          To start with your AddGlow community, please log in to your existing
          AddGlow account or sign up for a new account.
        </p>
        <div className="flex justify-stretch md:justify-center">
          <Button
            size="large"
            className="w-full md:w-[240px]"
            onClick={() => {
              loginWithRedirect({
                authorizationParams: {
                  screen_hint: 'signup',
                  prompt: 'login',
                  connection: 'email',
                },
                appState: {
                  returnTo: '/trial/new',
                },
              }).catch((err) => logError(err));
            }}
          >
            Sign Up
          </Button>
        </div>
      </Card>
    </div>
  );
}
