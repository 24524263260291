import {
  AsyncReactSelectInput,
  Button,
  Modal,
  SelectField,
  TextInputField,
} from '@addglowapp/components';
import { toast } from '@addglowapp/components';
import { useLazyQuery } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { debounce } from 'es-toolkit';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import {
  BrandProductFragment,
  GetBrandLoyaltyPageDocument,
  GetBrandProductsConnectionDocument,
  GetBrandProductsConnectionQuery,
  GetBrandProductsConnectionQueryResult,
  GetBrandProductsConnectionQueryVariables,
  useCreateLoyaltyDiscountMutation,
} from 'src/generated/graphql';
import { logAndFormatError } from 'src/services/error-formatter';

const submitDiscountFormSchema = z.object({
  pointsCost: z.coerce.number().finite().min(1),
  discountPercent: z.coerce.number().min(1).max(100),
  maxCountPerMember: z.coerce.number().nullish(),
  productId: z.string().nullish(),
});
type SubmitDiscountFormSchema = z.infer<typeof submitDiscountFormSchema>;

interface Props {
  brandId: string;
  isModalOpen: boolean;
  onClose: (created?: boolean) => void;
}

export default function CreateDiscountModal({
  brandId,
  isModalOpen,
  onClose,
}: Props): JSX.Element {
  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting },
  } = useForm<SubmitDiscountFormSchema>({
    resolver: zodResolver(submitDiscountFormSchema),
  });

  useEffect(() => {
    reset();
  }, [isModalOpen, reset]);

  const [createLoyaltyDiscount] = useCreateLoyaltyDiscountMutation();

  const [loadProductsQuery] = useLazyQuery<
    GetBrandProductsConnectionQuery,
    GetBrandProductsConnectionQueryVariables
  >(GetBrandProductsConnectionDocument, {
    fetchPolicy: 'network-only',
  });
  const loadProductOptions = useMemo(
    () =>
      debounce(
        (
          inputValue: string,
          callback: (options: { label: string; value: string }[]) => void,
        ): void => {
          loadProductsQuery({
            variables: {
              brandId,
              search: inputValue || null,
              first: 10,
            },
          })
            .then((result: GetBrandProductsConnectionQueryResult) => {
              const products =
                result.data?.brandProductsConnection?.edges.map(
                  (edge) => edge.node,
                ) ?? [];

              const options = products.map((product: BrandProductFragment) => ({
                label: product.title,
                value: product.id,
              }));

              callback(options);
            })
            .catch((error) => {
              logAndFormatError(error);
              callback([]);
            });
        },
        300,
      ),
    [brandId, loadProductsQuery],
  );

  const onSubmit = async (data: SubmitDiscountFormSchema): Promise<void> => {
    const maxCountPerMember =
      data?.maxCountPerMember && data.maxCountPerMember > 0
        ? data.maxCountPerMember
        : null;

    createLoyaltyDiscount({
      variables: {
        input: {
          data: {
            brandId,
            pointsCost: data.pointsCost,
            discountPercent: data.discountPercent,
            maxCountPerMember,
            productId: data.productId,
          },
        },
      },
      refetchQueries: [
        { query: GetBrandLoyaltyPageDocument, variables: { brandId } },
      ],
    })
      .then(() => {
        toast.success('Discount created successfully.');
        onClose(true);
      })
      .catch((error) => {
        toast.error(
          logAndFormatError(error, 'Sorry, the action could not be completed.'),
        );
      });
  };

  return (
    <Modal isOpen={isModalOpen} onClose={onClose} width="small">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header className="justify-center" onClose={onClose}>
          Add Discount Code
        </Modal.Header>
        <Modal.Body className="space-y-4">
          <TextInputField.Controller
            control={control}
            label="Points Cost"
            name="pointsCost"
            type="number"
          />
          <TextInputField.Controller
            control={control}
            label="Discount Percent (1-100)"
            name="discountPercent"
            type="number"
          />
          <SelectField.Controller
            control={control}
            label="Max Redemptions Per Member"
            name="maxCountPerMember"
            placeholder="Max redemptions per member"
            options={[
              { label: 'Unlimited', value: '-1' },
              ...Array.from({ length: 100 }, (_, i) => ({
                label: String(i + 1),
                value: String(i + 1),
              })),
            ]}
          />
          <AsyncReactSelectInput.LabelledController
            control={control}
            name="productId"
            label={
              <p>
                Limit to a Specific Product{' '}
                <span className="opacity-60">(optional)</span>
              </p>
            }
            loadOptions={loadProductOptions}
            defaultOptions={[]}
            placeholder="Search for a product"
            noOptionsMessage={({ inputValue }) =>
              inputValue.length < 1
                ? 'Type to search for a product'
                : 'No products found.'
            }
            isClearable
            fixedPosition
            emptyAsNull
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            color="secondary"
            type="button"
            onClick={() => onClose(false)}
          >
            Cancel
          </Button>
          <Button disabled={isSubmitting} color="primary" type="submit">
            Add
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
