import { ReactNode } from 'react';

interface Props {
  id: string;
  children: ReactNode;
  title: string;
}
export default function GuideSection({
  id,
  children,
  title,
}: Props): JSX.Element {
  return (
    <div id={id} className="my-6 flex flex-col space-y-4">
      <h1 className="mb-4 text-2xl font-bold">{title}</h1>
      {children}
    </div>
  );
}
