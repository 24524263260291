import { Dropdown, useIsLoading } from '@addglowapp/components';
import { toast } from '@addglowapp/components';
import { useState } from 'react';
import { BsPerson, BsThreeDots } from 'react-icons/bs';
import {
  FaUserAltSlash,
  FaUserCheck,
  FaUserPlus,
  FaUserShield,
} from 'react-icons/fa';
import { LuUsers } from 'react-icons/lu';
import {
  useBanBrandMemberMutation,
  useUpdateBrandMemberMutation,
} from 'src/generated/graphql';
import { useBrandId } from 'src/hooks/useBrandId';
import { logAndFormatError } from 'src/services/error-formatter';
import { BrandMemberRole } from '@src/generated/graphql';
import { useRequiredUserId } from '@src/hooks/useRequiredUserId';
import { BrandMemberData } from '../types';
import { BrandMemberGroupDialog } from './BrandMemberGroupDialog';
import { RevokeRoomModeratorDialog } from './RevokeRoomModeratorDialog';

interface BrandMemberActionMenuProps {
  brandMember: BrandMemberData;
  groupId?: string | null;
  refetch: () => void;
}

export function BrandMemberActionMenu({
  brandMember,
  groupId,
  refetch,
}: BrandMemberActionMenuProps): JSX.Element {
  const { role, userId, isBanned, userModeratedRooms } = brandMember;

  const brandId = useBrandId();
  const ownUserId = useRequiredUserId();
  const [updateBrandMember] = useUpdateBrandMemberMutation();
  const [banBrandMember] = useBanBrandMemberMutation();
  const { setIsLoading } = useIsLoading();
  const [showGroupsDialog, setShowGroupsDialog] = useState(false);
  const [showRevokeRoomModeratorDialog, setShowRevokeRoomModeratorDialog] =
    useState(false);
  const handleMemberUpdate = (
    setIsBanned?: boolean,
    newMemberRole?: BrandMemberRole,
  ): void => {
    if ((newMemberRole === 'CUSTOMER' || setIsBanned) && userId === ownUserId) {
      toast.error('You cannot change your own role or ban yourself.');
      return;
    }

    setIsLoading(true);

    updateBrandMember({
      variables: {
        input: {
          id: {
            userId,
            brandId,
          },
          data: {
            isBanned: setIsBanned,
            role: newMemberRole ?? null,
          },
        },
        brandId,
      },
    })
      .then(() => {
        toast.success('Updated successfully.');
      })
      .catch((err) => {
        toast.error(
          logAndFormatError(err, 'Sorry, the action could not be completed.'),
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleBanMember = (): void => {
    if (userId === ownUserId) {
      toast.error('You cannot change your own role or ban yourself.');
      return;
    }
    setIsLoading(true);
    banBrandMember({
      variables: {
        input: {
          userId,
          brandId,
        },
      },
    })
      .then(() => {
        toast.success('User banned.');
        refetch();
        setIsLoading(false);
      })
      .catch((error) =>
        toast.error(
          logAndFormatError(error, 'Sorry, user could not be banned.'),
        ),
      )
      .finally(() => {
        setIsLoading(false);
      });
  };
  const handleRemoveFromGroup = (): void => {
    if (!groupId) {
      toast.error('Group id is required.');
      return;
    }

    setIsLoading(true);

    const updatedGroupIds = brandMember.groupMembershipIds.filter(
      (id) => id !== groupId,
    );

    updateBrandMember({
      variables: {
        input: {
          id: {
            userId: brandMember.userId,
            brandId,
          },
          data: {
            memberGroupIds: updatedGroupIds,
          },
        },
        brandId,
      },
    })
      .then(() => {
        toast.success('Successfully removed from group.');
        refetch();
      })
      .catch((error) => {
        toast.error(
          logAndFormatError(
            error,
            'Sorry, user could not be removed from group.',
          ),
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Dropdown>
      <Dropdown.Trigger className="p-2">
        <BsThreeDots aria-label="User action" />
      </Dropdown.Trigger>
      <Dropdown.Content>
        {role !== 'ADMIN' && !isBanned && (
          <Dropdown.Item
            onClick={() => handleMemberUpdate(undefined, 'ADMIN')}
            className="flex items-center gap-2"
          >
            <FaUserShield />
            <div>Make Admin</div>
          </Dropdown.Item>
        )}
        {role !== 'MODERATOR' && !isBanned && (
          <Dropdown.Item
            onClick={() => handleMemberUpdate(undefined, 'MODERATOR')}
            className="flex items-center gap-2"
          >
            <FaUserPlus />
            <div>Make Moderator</div>
          </Dropdown.Item>
        )}
        {role !== 'CUSTOMER' && (
          <Dropdown.Item
            onClick={() => handleMemberUpdate(undefined, 'CUSTOMER')}
            className="flex items-center gap-2"
          >
            <BsPerson />
            <div>Make Customer</div>
          </Dropdown.Item>
        )}
        {isBanned && (
          <Dropdown.Item
            onClick={() => handleMemberUpdate(false, 'CUSTOMER')}
            className="flex items-center gap-2"
          >
            <FaUserCheck />
            <div>Unban User</div>
          </Dropdown.Item>
        )}
        {userModeratedRooms.length > 0 && (
          <Dropdown.Item
            onClick={() => setShowRevokeRoomModeratorDialog(true)}
            className="flex items-center gap-2"
          >
            <FaUserCheck />
            <div>Revoke Room Moderator Role</div>
          </Dropdown.Item>
        )}
        {!isBanned && (
          <Dropdown.Item
            onClick={handleBanMember}
            className="flex items-center gap-2"
          >
            <FaUserAltSlash />
            <div>Ban User</div>
          </Dropdown.Item>
        )}
        {groupId ? (
          <Dropdown.Item
            onClick={() => {
              handleRemoveFromGroup();
            }}
            className="flex items-center gap-2"
          >
            <LuUsers />
            <div>Remove from Group</div>
          </Dropdown.Item>
        ) : (
          <Dropdown.Item
            onClick={() => {
              setShowGroupsDialog(true);
            }}
            className="flex items-center gap-2"
          >
            <LuUsers />
            <div>Manage Groups</div>
          </Dropdown.Item>
        )}
      </Dropdown.Content>
      <BrandMemberGroupDialog
        brandMember={brandMember}
        open={showGroupsDialog}
        onOpenChange={setShowGroupsDialog}
      />
      <RevokeRoomModeratorDialog
        brandMember={brandMember}
        open={showRevokeRoomModeratorDialog}
        onOpenChange={setShowRevokeRoomModeratorDialog}
        refetch={refetch}
      />
    </Dropdown>
  );
}
