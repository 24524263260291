import { Route, Routes } from 'react-router-dom';
import NotFoundPage from '@src/pages/NotFound.page';
import { MemberBadgesPage } from './badges/member-badges.page';
import { GroupDetailsPage } from './groups/group-details.page';
import { MemberGroupsPage } from './groups/member-groups.page';
import { MembersPage } from './members.page';

export function MemberRoutes(): JSX.Element {
  return (
    <Routes>
      <Route index element={<MembersPage />} />
      <Route path="groups/:groupId" element={<GroupDetailsPage />} />
      <Route path="groups" element={<MemberGroupsPage />} />
      <Route path="badges" element={<MemberBadgesPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}
