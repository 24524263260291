import { Route, Routes } from 'react-router-dom';
import { useFeatureEnabled } from '@src/hooks/useFeatureEnabled';
import NotFoundPage from '../NotFound.page';
import AnalyticsRoutes from './analytics';
import ContactPage from './contact.page';
import { CustomizationRoutes } from './customization/index.page';
import { DeveloperRoutes } from './developers';
import UserGuide from './guides/guide.page';
import BrandHomePage from './home.page';
import { InveteratePage } from './inveterate/inveterate.page';
import { KlaviyoPage } from './klaviyo/klaviyo.page';
import { LogEntriesPage } from './log-entries/index.page';
import LoyaltyPage from './loyalty/index.page';
import { MemberRoutes } from './members/index.page';
import BannedWordsPage from './moderation/bannedwords/bannedwords.page';
import GuidelinesPage from './moderation/guidelines/guidelines.page';
import { PostApprovalPage } from './moderation/post-approval/post-approval.page';
import { ShopifyPage } from './shopify/shopify.page';
import { YotpoAuthCallbackPage } from './yotpo/auth.page';
import { YotpoPage } from './yotpo/yotpo.page';
import { YotpoLoyaltyPage } from './yotpo-loyalty/yotpo-loyalty.page';

function BrandRoutes(): JSX.Element {
  const shouldHidePostApprovals = useFeatureEnabled('hide-post-approvals');

  return (
    <Routes>
      <Route path="analytics/*" element={<AnalyticsRoutes />} />
      <Route path="bannedwords" element={<BannedWordsPage />} />
      <Route path="guidelines" element={<GuidelinesPage />} />
      {!shouldHidePostApprovals && (
        <Route path="post-approval" element={<PostApprovalPage />} />
      )}
      <Route path="loyalty" element={<LoyaltyPage />} />
      <Route path="customization/*" element={<CustomizationRoutes />} />
      <Route path="klaviyo" element={<KlaviyoPage />} />
      <Route path="integrations/yotpo" element={<YotpoPage />} />
      <Route
        path="integrations/yotpo/auth"
        element={<YotpoAuthCallbackPage />}
      />
      <Route path="integrations/yotpo-loyalty" element={<YotpoLoyaltyPage />} />
      <Route path="inveterate" element={<InveteratePage />} />
      <Route path="shopify" element={<ShopifyPage />} />
      <Route path="contact" element={<ContactPage />} />
      <Route path="developers/*" element={<DeveloperRoutes />} />
      <Route path="guide" element={<UserGuide />} />
      <Route path="members/*" element={<MemberRoutes />} />
      <Route path="log-entries" element={<LogEntriesPage />} />
      <Route index element={<BrandHomePage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default BrandRoutes;
