import { Button } from '@addglowapp/components';
import clsx from 'clsx';
import LayoutSection from 'src/components/LayoutSection';
import { useRedirectToYotpoInstallationLink } from './hooks';

interface ConnectYotpoSectionProps {
  className?: string;
}

export function ConnectYotpoSection({
  className,
}: ConnectYotpoSectionProps): JSX.Element {
  const { redirect, isRedirecting } = useRedirectToYotpoInstallationLink();

  return (
    <LayoutSection className={clsx('max-w-3xl', className)}>
      <LayoutSection.Header
        title="Connect Yotpo"
        actions={
          <div className="flex space-x-2">
            <Button color="primary" onClick={redirect} disabled={isRedirecting}>
              Install Yotpo
            </Button>
          </div>
        }
      />
      <LayoutSection.Body>
        <div className="space-y-4">
          <p>
            To connect your Yotpo account with AddGlow, click the button above
            to install the Yotpo app.
          </p>
          <p>
            Once you&apos;ve installed the app, you&apos;ll be able to sync your
            AddGlow customers with Yotpo&apos;s SMS program.
          </p>
        </div>
      </LayoutSection.Body>
    </LayoutSection>
  );
}
