import { z } from 'zod';

export const fileSchema = z.object({
  id: z.string().min(1),
  name: z.string().nullish(),
  hostedUrl: z
    .string()
    .nullish()
    // we want to erase it before it gets sent up to the server
    .transform(() => undefined as string | undefined | null),
});
