import {
  ComponentContextProvider,
  ComponentProvider,
  ImageComponentProps,
  LinkComponentProps,
} from '@addglowapp/components';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

interface Props {
  children: React.ReactNode;
}

function ImageComponent({
  src,
  alt,
  fill,
  className,
  width,
  height,
}: ImageComponentProps): JSX.Element {
  return (
    <img
      src={src}
      alt={alt}
      width={width}
      height={height}
      className={clsx(className, {
        'absolute inset-0 h-full w-full': fill,
      })}
    />
  );
}

function LinkComponent({ href, ...props }: LinkComponentProps): JSX.Element {
  return <Link to={href} {...props} />;
}

export function ViteComponentProvider({ children }: Props): JSX.Element {
  const value: ComponentProvider = useMemo(
    () => ({
      ImageComponent,
      LinkComponent,
    }),
    [],
  );

  return (
    <ComponentContextProvider.Provider value={value}>
      {children}
    </ComponentContextProvider.Provider>
  );
}
