import {
  Button,
  Dialog,
  TextInputField,
  MemberBadge,
  SwitchField,
} from '@addglowapp/components';
import { toast } from '@addglowapp/components';
import { updateBrandMemberBadgeSchema } from '@addglowapp/shared';
import { zodResolver } from '@hookform/resolvers/zod';
import { clsx } from 'clsx';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import {
  GetBrandMemberBadgesDocument,
  GetMemberGroupsDocument,
  MemberGroupFragment,
  useCreateMemberGroupMutation,
  useUpdateMemberGroupMutation,
} from '@src/generated/graphql';
import { useBrandId } from '@src/hooks/useBrandId';
import { logAndFormatError } from '@src/services/error-formatter';
import ColorInput from '../../customization/theming/components/ColorInput';

interface MemberGroupFormProps {
  memberGroup?: MemberGroupFragment;
  className?: string;
  onSaved?: () => void;
}

const formSchema = z.object({
  name: z.string().trim().min(1),
  brandMemberBadge: updateBrandMemberBadgeSchema.optional(),
});

type FormValues = z.infer<typeof formSchema>;

export function MemberGroupForm({
  memberGroup,
  className,
  onSaved,
}: MemberGroupFormProps): JSX.Element {
  const [updateGroup] = useUpdateMemberGroupMutation({
    refetchQueries: [GetBrandMemberBadgesDocument],
  });
  const [createGroup] = useCreateMemberGroupMutation({
    refetchQueries: [GetMemberGroupsDocument, GetBrandMemberBadgesDocument],
  });

  const brandId = useBrandId();

  const {
    handleSubmit,
    control,
    formState: { isSubmitting, isDirty },
    watch,
    setValue,
  } = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    values: {
      name: memberGroup?.name ?? '',
      brandMemberBadge: memberGroup?.brandMemberBadge ?? undefined,
    },
  });

  const onSubmit = handleSubmit(async (data) => {
    try {
      if (memberGroup) {
        await updateGroup({
          variables: {
            input: {
              id: memberGroup.id,
              data,
            },
          },
        });
        toast.success('Member group updated successfully');
      } else {
        await createGroup({
          variables: {
            input: {
              data: {
                ...data,
                brandId,
              },
            },
          },
        });
        toast.success('Member group created successfully');
      }
      onSaved?.();
    } catch (error) {
      toast.error(
        logAndFormatError(
          error,
          'Failed to save member group. Please try again.',
        ),
      );
    }
  });

  const badge = watch('brandMemberBadge');
  const name = watch('name');

  return (
    <form className={clsx('space-y-4', className)} onSubmit={onSubmit}>
      <TextInputField.Controller
        autoComplete="off"
        control={control}
        name="name"
        label="Name"
      />
      <SwitchField
        label="Badge"
        description="Show a badge next to the member's name in the community."
        value={!!badge}
        onChange={(value) => {
          setValue(
            'brandMemberBadge',
            value
              ? {
                  backgroundColor: '#000000',
                  foregroundColor: '#ffffff',
                  name,
                }
              : undefined,
            { shouldDirty: true },
          );
        }}
      />
      {!!badge && (
        <div className="flex-col space-y-4">
          <TextInputField.Controller
            autoComplete="off"
            control={control}
            name="brandMemberBadge.name"
            label="Badge Name"
          />
          <div className="flex gap-4">
            <ColorInput.LabelledController
              control={control}
              className="flex-1"
              name="brandMemberBadge.backgroundColor"
              label="Background Color"
            />
            <ColorInput.LabelledController
              control={control}
              className="flex-1"
              name="brandMemberBadge.foregroundColor"
              label="Foreground Color"
            />
          </div>
          <div className="flex w-full flex-col items-center justify-center gap-2 border border-brand-border p-4">
            <MemberBadge badge={badge} />
            <span className="text-sm text-muted-foreground">Badge Preview</span>
          </div>
        </div>
      )}
      <Dialog.Footer>
        <Dialog.Close>
          <Button color="secondary">Cancel</Button>
        </Dialog.Close>
        <Button
          type="submit"
          color="primary"
          disabled={isSubmitting || !isDirty}
        >
          Save
        </Button>
      </Dialog.Footer>
    </form>
  );
}
