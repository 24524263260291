import { Button } from '@addglowapp/components';
import { Modal, TextInputField } from '@addglowapp/components';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { CustomTraitGroupFragment } from 'src/generated/graphql';
import { logAndFormatError } from '@src/services/error-formatter';

const createCustomTraitGroupFormSchema = z.object({
  title: z.string().trim().min(1).max(100),
});

export type CreateCustomTraitGroupFormSchema = z.infer<
  typeof createCustomTraitGroupFormSchema
>;

interface Props {
  isModalOpen: boolean;
  onClose: () => void;
  group?: CustomTraitGroupFragment | null;
  onSubmit(
    data: CreateCustomTraitGroupFormSchema,
    groupId?: string,
  ): Promise<void>;
}

export default function CreateGroupModal({
  isModalOpen,
  onClose,
  group,
  onSubmit,
}: Props): JSX.Element {
  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting },
  } = useForm<CreateCustomTraitGroupFormSchema>({
    mode: 'onChange',
    resolver: zodResolver(createCustomTraitGroupFormSchema),
  });

  useEffect(() => {
    reset({
      title: group?.title ?? '',
    });
  }, [group, reset]);

  const handleSubmitData = (data: CreateCustomTraitGroupFormSchema): void => {
    onSubmit(data, group?.id)
      .catch((e) => logAndFormatError(e))
      .finally(() => {
        reset();
      });
  };
  return (
    <Modal isOpen={isModalOpen} onClose={onClose}>
      <form onSubmit={handleSubmit(handleSubmitData)}>
        <Modal.Header className="justify-center" onClose={onClose}>
          {group ? 'Edit trait group' : 'Create trait group'}
        </Modal.Header>
        <Modal.Body className="space-y-4">
          <TextInputField.Controller
            control={control}
            label="Title"
            name="title"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button color="secondary" type="button" onClick={onClose}>
            Cancel
          </Button>
          <Button
            disabled={isSubmitting}
            color="secondary-selected"
            type="submit"
          >
            {group ? 'Save' : 'Add'}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
