import {
  TableRow,
  TableCell,
  Button,
  Alert,
  Table,
  TableHeader,
  TableHead,
  TableBody,
} from '@addglowapp/components';
import { toast } from '@addglowapp/components';
import { useState } from 'react';
import { BsTrash } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import LayoutSection from 'src/components/LayoutSection';
import {
  BrandLoyaltyPageDataFragment,
  GetBrandLoyaltyPageDocument,
  LoyaltyDiscountFragment,
  useDeleteLoyaltyDiscountMutation,
} from 'src/generated/graphql';
import CreateDiscountModal from './CreateDiscountModal';

interface DiscountRowProps {
  discount: LoyaltyDiscountFragment;
}

function DiscountRow({ discount }: DiscountRowProps): JSX.Element {
  const [loading, setLoading] = useState(false);
  const [deleteLoyaltyDiscount] = useDeleteLoyaltyDiscountMutation({
    refetchQueries: [
      {
        query: GetBrandLoyaltyPageDocument,
        variables: { brandId: discount.brandId },
      },
    ],
  });

  const handleDelete = async (): Promise<void> => {
    setLoading(true);
    try {
      await deleteLoyaltyDiscount({
        variables: {
          input: {
            id: discount.id,
          },
        },
      });
      toast.success('Discount deleted successfully.');
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <TableRow
      key={discount.id}
      className={
        loading
          ? 'pointer-events-none animate-pulse touch-none bg-gray-200'
          : ''
      }
    >
      <TableCell>
        <Button color="tertiary" onClick={handleDelete}>
          {!loading && <BsTrash />}
        </Button>
      </TableCell>
      <TableCell>
        <strong>{discount.pointsCost.toLocaleString()}</strong> points
      </TableCell>
      <TableCell>{discount.discountPercent}%</TableCell>
      <TableCell>{discount.maxCountPerMember ?? 'Unlimited'}</TableCell>
      <TableCell>
        {discount.product?.onlineStoreUrl ? (
          <a
            href={discount.product.onlineStoreUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            {discount.product.title}
          </a>
        ) : (
          'None'
        )}
      </TableCell>{' '}
    </TableRow>
  );
}

function RewardsSection({
  brandData,
}: {
  brandData: BrandLoyaltyPageDataFragment;
}): JSX.Element | null {
  const [createModalOpen, setCreateModalOpen] = useState(false);

  const rows = [...(brandData?.loyaltyDiscountsByBrand || [])].sort(
    (a, b) => a.discountPercent - b.discountPercent,
  );

  const { brand } = brandData;

  return (
    <div>
      <CreateDiscountModal
        brandId={brand.id}
        isModalOpen={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
      />

      <LayoutSection>
        <LayoutSection.Header title="Rewards" />
        <LayoutSection.Body>
          <div className="flex flex-col space-y-4">
            <div>
              <h2 className="text-base font-semibold">
                Create redeemable rewards
              </h2>
              <p className="text-sm text-gray-500">
                What will your community members gain from engaging with your
                brand?
              </p>
            </div>
            <hr />
            {!brand.isYotpoLoyaltyEnabled &&
              !brand.shopifyIntegration?.isConnected && (
                <Alert type="info">
                  You will need to{' '}
                  <Link to="../shopify" className="font-bold">
                    connect your Shopify store
                  </Link>{' '}
                  under Integrations for discounts to work within AddGlow.
                </Alert>
              )}
            {brand.isYotpoLoyaltyEnabled ? (
              <Alert type="info" className="space-y-2">
                <p>
                  <strong>Rewards managed by Yotpo</strong>
                </p>
                <p>
                  If you want to create new redemption awards, please log into
                  your{' '}
                  <a href="https://loyalty-app.yotpo.com/coupons">
                    Yotpo dashboard
                  </a>
                  .
                </p>
              </Alert>
            ) : (
              <div>
                <h2 className="text-base font-semibold">Store discounts</h2>
                <Table className="mt-2">
                  <TableHeader>
                    <TableRow className="border-b border-gray-200">
                      <TableHead>Action</TableHead>
                      <TableHead>Points Cost</TableHead>
                      <TableHead>Discount</TableHead>
                      <TableHead>Max Redemptions</TableHead>
                      <TableHead>Product</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {rows.map((row) => (
                      <DiscountRow key={row.id} discount={row} />
                    ))}
                  </TableBody>
                </Table>
                <div className="mt-2">
                  <Button
                    color="primary"
                    type="submit"
                    disabled={createModalOpen}
                    onClick={() => setCreateModalOpen(true)}
                  >
                    Add a discount
                  </Button>
                </div>
              </div>
            )}
          </div>
        </LayoutSection.Body>
      </LayoutSection>
    </div>
  );
}

export default RewardsSection;
