import { Dialog, useControlledState } from '@addglowapp/components';
import { BrandMemberBadgeFragment } from '@src/generated/graphql';
import { MemberBadgeForm } from './MemberBadgeForm';

interface MemberBadgeDialogProps {
  memberBadge?: BrandMemberBadgeFragment;
  open?: boolean;
  onOpenChange?: (isOpen: boolean) => void;
  children?: React.ReactNode;
}

export function MemberBadgeDialog({
  memberBadge,
  open,
  onOpenChange,
  children,
}: MemberBadgeDialogProps): JSX.Element {
  const [isOpen, setIsOpen] = useControlledState(open, onOpenChange);
  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      {children && <Dialog.Trigger asChild>{children}</Dialog.Trigger>}
      <Dialog.Content aria-describedby={undefined}>
        <Dialog.Header>
          <Dialog.Title>
            {memberBadge ? `Edit ${memberBadge.name}` : 'Create Badge'}
          </Dialog.Title>
        </Dialog.Header>
        {memberBadge && (
          <MemberBadgeForm
            memberBadge={memberBadge}
            onSaved={() => {
              setIsOpen(false);
            }}
          />
        )}
      </Dialog.Content>
    </Dialog>
  );
}
