import clsx from 'clsx';
import { BrandFontOptionFragment } from 'src/generated/graphql';
import { BrandColorsData, convertToCssVariables } from './colors';

interface Props {
  className?: string;
  colors: BrandColorsData;
  contentFont?: BrandFontOptionFragment | null;
  children: React.ReactNode;
}

const DEFAULT_FONT: BrandFontOptionFragment = {
  id: 'default',
  name: 'Poppins',
  isGoogle: true,
};

function renderFontStyleTag(font: BrandFontOptionFragment): JSX.Element {
  if (font.isGoogle) {
    return (
      <link
        href={`https://fonts.googleapis.com/css2?family=${font.name.replace(
          /\s/g,
          '+',
        )}:ital,wght@0,400;0,700;1,400;1,700&display=swap`}
        rel="stylesheet"
      />
    );
  }
  return (
    <link
      href={`/fonts/${font.name
        .replace(' ', '_')
        .toLowerCase()}/stylesheet.css`}
      rel="stylesheet"
    />
  );
}

function BrandPreview({
  className,
  colors,
  children,
  contentFont,
}: Props): JSX.Element {
  const colorVariables = convertToCssVariables(colors);

  const contentFontRender = contentFont || DEFAULT_FONT;

  const fontVariables = {
    '--font-brand-content': contentFontRender.name,
  } as React.CSSProperties;

  return (
    <div
      className={clsx('relative rounded-lg border border-gray-300', className)}
      style={{
        ...colorVariables,
        ...fontVariables,
      }}
    >
      {renderFontStyleTag(contentFontRender)}
      {children}
    </div>
  );
}

export default BrandPreview;
