import {
  Button,
  CreateRoomModal,
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableHead,
  ErrorableLoader,
} from '@addglowapp/components';
import _ from 'lodash';
import { useState } from 'react';
import { useRoomOptionsQuery } from 'src/generated/graphql';
import { useBrandId } from 'src/hooks/useBrandId';
import { ReorderRoomsModalButton } from './ReorderRoomsModalButton';
import RoomRow from './RoomRow';

function RoomsPage(): JSX.Element {
  const brandId = useBrandId();
  const [createModalOpen, setCreateModalOpen] = useState(false);

  const { data, error, refetch } = useRoomOptionsQuery({
    variables: {
      brandId,
    },
  });

  if (!data) {
    return <ErrorableLoader error={error} />;
  }

  const roomOptionsData = _.sortBy(data.roomOptions, (o) => o.position);

  return (
    <div className="pb-40">
      <h1>Rooms</h1>

      <Table className="mt-8">
        <TableHeader>
          <TableRow className="border-b border-gray-200">
            <TableHead>Actions</TableHead>
            <TableHead>Name</TableHead>
            <TableHead>Description</TableHead>
            <TableHead>Type</TableHead>
            <TableHead>Requires Login</TableHead>
            <TableHead>Restricted To</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {roomOptionsData.map((room) => (
            <RoomRow
              key={`${room.id}`}
              room={room}
              onActionComplete={refetch}
              brandId={brandId}
              totalRoomCount={roomOptionsData.length}
            />
          ))}
        </TableBody>
      </Table>
      <div className="mt-8 flex space-x-2">
        <Button
          onClick={() => {
            setCreateModalOpen(true);
          }}
        >
          Add a Room
        </Button>
        <ReorderRoomsModalButton rooms={roomOptionsData} />
      </div>
      <CreateRoomModal
        onComplete={refetch}
        brandId={brandId}
        open={createModalOpen}
        initialPosition={roomOptionsData.length + 1}
        onOpenChange={(open) => {
          setCreateModalOpen(open);
        }}
      />
    </div>
  );
}

export default RoomsPage;
