import {
  ErrorableLoader,
  Alert,
  Sidebar,
  LogoDisplay,
  Modal,
  Button,
  Select,
} from '@addglowapp/components';
import { getFullCommunityDomain } from '@addglowapp/shared';
import { useGrowthBook } from '@growthbook/growthbook-react';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import {
  TbBook,
  TbCode,
  TbDiscount,
  TbFileAnalytics,
  TbMail,
  TbMessageReport,
  TbPlugConnected,
  TbSettings,
  TbUserCircle,
} from 'react-icons/tb';
import {
  Navigate,
  Outlet,
  useLocation,
  useMatch,
  useNavigate,
} from 'react-router-dom';
import { z } from 'zod';
import { useBrandId } from 'src/hooks/useBrandId';
import { useCurrentUser } from 'src/hooks/useCurrentUser';
import { useLogOut } from 'src/hooks/useLogOut';
import { ENABLE_YOTPO_DEV_INTEGRATION } from '@src/constants/flags';
import { useFeatureEnabled } from '@src/hooks/useFeatureEnabled';
import { useRequiredUserId } from '@src/hooks/useRequiredUserId';
import { setImpersonationUserId } from '@src/services/brand-impersonation-auth';
import { setGrowthbookAttributes } from '@src/services/growthbook';

interface Props {
  className?: string;
}

interface StateProps {
  newBrand?: boolean;
}

function AdminLayout({ className }: Props): JSX.Element {
  const logOut = useLogOut();
  const { user, error } = useCurrentUser();
  const userId = useRequiredUserId();
  const brandId = useBrandId();
  const navigate = useNavigate();
  const isAnalyticsUrl = useMatch(`/${brandId}/analytics/*`);
  const isCustomizationUrl = useMatch(`/${brandId}/customization/*`);
  const isKlaviyoUrl = useMatch(`/${brandId}/klaviyo/*`);
  const isShopifyUrl = useMatch(`/${brandId}/shopify/*`);
  const isYotpoUrl = useMatch(`/${brandId}/integrations/yotpo/*`);
  const isYotpoLoyaltyUrl = useMatch(
    `/${brandId}/integrations/yotpo-loyalty/*`,
  );
  const isInveterateUrl = useMatch(`/${brandId}/inveterate`);
  const isDevelopersUrl = useMatch(`/${brandId}/developers`);
  const isMembersUrl = useMatch(`/${brandId}/members/*`);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { state, pathname, search } = useLocation();
  const [showNewBrandModal, setShowNewBrandModal] = useState(
    (state as StateProps)?.newBrand ?? false,
  );

  useEffect(() => {
    // set current impersonation ID if brand uses custom auth
    const userIdToImpersonate = (() => {
      if (!user) return null;
      const brandAdmin = user.brandAdminMemberships.find(
        (m) => m.brandId === brandId,
      );
      if (!brandAdmin?.brand.usesCustomAuth) return null;
      if (brandAdmin.userId === user.id) return null;
      return brandAdmin.userId;
    })();

    setImpersonationUserId(userIdToImpersonate);
  }, [brandId, user]);

  const brandAdmins = user?.brandAdminMemberships;
  const brandOptions = brandAdmins?.map((m) => ({
    label: m.brand.name,
    value: m.brandId,
  }));

  const currentBrand = brandAdmins?.find((m) => m.brandId === brandId);

  const growthbook = useGrowthBook();

  const currentBrandDomain = currentBrand?.brand.communityDomain;

  useEffect(() => {
    setGrowthbookAttributes({
      userId,
      host: window.location.host,
      communityDomain: currentBrandDomain,
    });
  }, [currentBrandDomain, growthbook, userId]);

  const shouldHidePoweredBy = useFeatureEnabled('hide-powered-by');
  const shouldHidePostApprovals = useFeatureEnabled('hide-post-approvals');

  if (!user) {
    return <ErrorableLoader error={error} />;
  }

  if (!brandOptions?.length) {
    return <Navigate to={`/`} />;
  }

  if (!z.string().uuid().safeParse(brandId).success) {
    return <Navigate to={`/${brandOptions[0].value}${pathname}${search}`} />;
  }

  if (!currentBrand) {
    return <Alert type="error">Unknown brand</Alert>;
  }

  const logoUrl = currentBrand.brand.faviconFile?.hostedUrl;
  const communityUrl = getFullCommunityDomain(
    currentBrand.brand.communityDomain,
  );

  return (
    <div className="flex h-full flex-col">
      <div className={clsx('flex h-full flex-1 items-stretch', className)}>
        <Sidebar className="flex-none">
          <div className="flex flex-1 flex-col justify-between space-y-8">
            <div>
              <Sidebar.Header className="my-4 flex flex-col items-center space-y-2">
                {brandOptions.length > 1 && (
                  <Select
                    onValueChange={(value: string) => {
                      navigate(`/${value}`);
                    }}
                  >
                    <Select.Trigger className="w-full">
                      {brandOptions.find(
                        (option) => option.value === currentBrand?.brandId,
                      )?.label ?? 'Select a Brand'}
                    </Select.Trigger>
                    <Select.Content>
                      <Select.Group>
                        {brandOptions.map((option) => (
                          <Select.Item key={option.value} value={option.value}>
                            {option.label}
                          </Select.Item>
                        ))}
                      </Select.Group>
                    </Select.Content>
                  </Select>
                )}
                <LogoDisplay
                  brandLogoUrl={logoUrl}
                  brandName={currentBrand.brand.name}
                />
                <h2 className="font-medium">
                  <a
                    href={communityUrl}
                    target="_blank"
                    rel="noreferrer"
                    className="text-inherit"
                  >
                    {currentBrand.brand.name}
                  </a>
                </h2>
              </Sidebar.Header>
              <Sidebar.LinkGroup>
                <Sidebar.Dropdown
                  defaultOpen={!!isAnalyticsUrl}
                  Icon={TbFileAnalytics}
                  label="Analytics"
                >
                  <Sidebar.DropdownLinkItem
                    withParentIcon
                    to={`/${brandId}/analytics`}
                  >
                    Events
                  </Sidebar.DropdownLinkItem>
                  <Sidebar.DropdownLinkItem
                    withParentIcon
                    to={`/${brandId}/analytics/visitors`}
                  >
                    Visitors
                  </Sidebar.DropdownLinkItem>
                </Sidebar.Dropdown>

                <Sidebar.Dropdown Icon={TbMessageReport} label="Moderation">
                  <Sidebar.DropdownLinkItem
                    withParentIcon
                    to={`/${brandId}/bannedwords`}
                  >
                    Banned Words
                  </Sidebar.DropdownLinkItem>
                  <Sidebar.DropdownLinkItem
                    withParentIcon
                    to={`/${brandId}/guidelines`}
                  >
                    Community Guidelines
                  </Sidebar.DropdownLinkItem>
                  {!shouldHidePostApprovals && (
                    <Sidebar.DropdownLinkItem
                      withParentIcon
                      to={`/${brandId}/post-approval`}
                    >
                      Post Approval
                    </Sidebar.DropdownLinkItem>
                  )}
                </Sidebar.Dropdown>

                <Sidebar.Dropdown
                  defaultOpen={!!isCustomizationUrl}
                  Icon={TbSettings}
                  label="Customization"
                >
                  <Sidebar.DropdownLinkItem
                    withParentIcon
                    to={`/${brandId}/customization`}
                  >
                    Theming
                  </Sidebar.DropdownLinkItem>
                  <Sidebar.DropdownLinkItem
                    withParentIcon
                    to={`/${brandId}/customization/profiles`}
                  >
                    User Profiles
                  </Sidebar.DropdownLinkItem>
                  <Sidebar.DropdownLinkItem
                    withParentIcon
                    to={`/${brandId}/customization/tags`}
                  >
                    Tags
                  </Sidebar.DropdownLinkItem>
                  <Sidebar.DropdownLinkItem
                    withParentIcon
                    to={`/${brandId}/customization/rooms`}
                  >
                    Rooms
                  </Sidebar.DropdownLinkItem>
                  <Sidebar.DropdownLinkItem
                    withParentIcon
                    to={`/${brandId}/customization/faq`}
                  >
                    FAQ
                  </Sidebar.DropdownLinkItem>
                  <Sidebar.DropdownLinkItem
                    withParentIcon
                    to={`/${brandId}/customization/welcome`}
                  >
                    Welcome Messages
                  </Sidebar.DropdownLinkItem>
                  <Sidebar.DropdownLinkItem
                    withParentIcon
                    to={`/${brandId}/customization/emails`}
                  >
                    Emails
                  </Sidebar.DropdownLinkItem>
                </Sidebar.Dropdown>
                <Sidebar.Dropdown
                  Icon={TbUserCircle}
                  label="Members"
                  defaultOpen={!!isMembersUrl}
                >
                  <Sidebar.DropdownLinkItem
                    withParentIcon
                    to={`/${brandId}/members`}
                  >
                    All Members
                  </Sidebar.DropdownLinkItem>
                  <Sidebar.DropdownLinkItem
                    withParentIcon
                    to={`/${brandId}/members/groups`}
                  >
                    Groups
                  </Sidebar.DropdownLinkItem>
                  <Sidebar.DropdownLinkItem
                    withParentIcon
                    to={`/${brandId}/members/badges`}
                  >
                    Badges
                  </Sidebar.DropdownLinkItem>
                </Sidebar.Dropdown>
                <Sidebar.LinkItem Icon={TbDiscount} to={`/${brandId}/loyalty`}>
                  Loyalty
                </Sidebar.LinkItem>
                <Sidebar.Dropdown
                  defaultOpen={
                    !!isKlaviyoUrl ||
                    !!isShopifyUrl ||
                    !!isYotpoUrl ||
                    !!isYotpoLoyaltyUrl ||
                    !!isInveterateUrl
                  }
                  Icon={TbPlugConnected}
                  label="Integrations"
                >
                  <Sidebar.DropdownLinkItem
                    withParentIcon
                    to={`/${brandId}/shopify`}
                  >
                    Shopify
                  </Sidebar.DropdownLinkItem>
                  <Sidebar.DropdownLinkItem
                    withParentIcon
                    to={`/${brandId}/klaviyo`}
                  >
                    Klaviyo
                  </Sidebar.DropdownLinkItem>
                  <Sidebar.DropdownLinkItem
                    withParentIcon
                    to={`/${brandId}/integrations/yotpo-loyalty`}
                  >
                    Yotpo Loyalty
                  </Sidebar.DropdownLinkItem>
                  {ENABLE_YOTPO_DEV_INTEGRATION && (
                    <Sidebar.DropdownLinkItem
                      withParentIcon
                      to={`/${brandId}/integrations/yotpo`}
                    >
                      Yotpo SMS
                    </Sidebar.DropdownLinkItem>
                  )}
                  <Sidebar.DropdownLinkItem
                    withParentIcon
                    to={`/${brandId}/inveterate`}
                  >
                    Inveterate
                  </Sidebar.DropdownLinkItem>
                </Sidebar.Dropdown>
                <Sidebar.Dropdown
                  defaultOpen={!!isDevelopersUrl}
                  Icon={TbCode}
                  label="Developers"
                >
                  <Sidebar.DropdownLinkItem
                    withParentIcon
                    to={`/${brandId}/developers/api-keys`}
                  >
                    API Keys
                  </Sidebar.DropdownLinkItem>
                  <Sidebar.DropdownLinkItem
                    withParentIcon
                    to={`/${brandId}/developers/webhooks`}
                  >
                    Webhooks
                  </Sidebar.DropdownLinkItem>
                </Sidebar.Dropdown>
                <Sidebar.LinkItem Icon={TbSettings} to={`/${brandId}/guide`}>
                  User Guides
                </Sidebar.LinkItem>
                <Sidebar.LinkItem Icon={TbBook} to={`/${brandId}/log-entries`}>
                  Admin Log
                </Sidebar.LinkItem>
                <Sidebar.LinkItem Icon={TbMail} to={`/${brandId}/contact`}>
                  Contact Us
                </Sidebar.LinkItem>
              </Sidebar.LinkGroup>
            </div>
            <div className="text-center">
              {!shouldHidePoweredBy && (
                <p className="mb-5">Powered by AddGlow</p>
              )}
              <button
                type="button"
                className="text-gray-500"
                onClick={() => logOut()}
              >
                Log Out
              </button>
            </div>
          </div>
        </Sidebar>
        <div className="flex flex-auto flex-col overflow-auto bg-gray-50 p-10">
          <Outlet />
        </div>
        <Modal
          isOpen={showNewBrandModal}
          onClose={() => setShowNewBrandModal(false)}
        >
          <Modal.Body className="space-y-4">
            <h1>Welcome to your new AddGlow community!</h1>
            <p>
              You can now begin to personalize your brand using the links
              provided in the left banner. Should you have any inquiries,
              don&apos;t hesitate to reach out to us through the &quot;Contact
              Us&quot; link, also located on the left side.
            </p>
            <p>
              You can access your community at{' '}
              <strong>
                <a href={communityUrl} target="_blank" rel="noreferrer">
                  {communityUrl}
                </a>
              </strong>
            </p>
            {currentBrand.brand.isRestricted && (
              <p className="text-sm text-gray-700">
                Please be aware that your community will initially be only
                visible to you as it undergoes a review by the AddGlow team.
                Once approved, it will be visible to others.
              </p>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setShowNewBrandModal(false)}>Got it</Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default AdminLayout;
