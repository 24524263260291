import {
  EditRoomModal,
  Menu,
  MenuItem,
  MenuOption,
} from '@addglowapp/components';
import { useState } from 'react';
import { BsThreeDots } from 'react-icons/bs';
import { FaUserShield } from 'react-icons/fa';
import { RoomOptionFragment } from 'src/generated/graphql';
import DeleteRoomModal from './DeleteRoomModal';

interface Props {
  room: RoomOptionFragment;
  setLoading(loading: boolean): void;
  onActionComplete(): void;
  brandId: string;
  totalRoomCount: number;
}

export default function RoomsActionMenu({
  room,
  setLoading,
  onActionComplete,
  brandId,
  totalRoomCount,
}: Props): JSX.Element {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);

  const options: MenuOption[] = [
    {
      icon: <FaUserShield className="h-5 w-5" />,
      text: 'Edit',
      onClick: () => setEditModalOpen(true),
    },
  ];

  if (totalRoomCount > 1) {
    options.unshift({
      icon: <FaUserShield className="h-5 w-5" />,
      text: 'Delete',
      onClick: () => setShowDeleteModal(true),
    });
  }

  return (
    <div>
      <Menu
        buttonLabel={
          <div className="flex items-center space-x-2 p-1 text-xs font-normal">
            <BsThreeDots aria-label="User action" />
          </div>
        }
      >
        {options.map((option) => (
          <MenuItem key={option.text} onClick={option.onClick}>
            <div className="flex items-center space-x-2">
              {option.icon}
              <div>{option.text}</div>
            </div>
          </MenuItem>
        ))}
      </Menu>
      <DeleteRoomModal
        isOpen={showDeleteModal}
        setModalOpen={setShowDeleteModal}
        room={room}
        onActionComplete={onActionComplete}
        setLoading={setLoading}
      />
      <EditRoomModal
        open={editModalOpen}
        onOpenChange={setEditModalOpen}
        brandId={brandId}
        room={room}
        hasEditRoomSettingsPermissions
        hasEditRoomAboutPermissions
        hasEditRoomPostPromptsPermissions
      />
    </div>
  );
}
