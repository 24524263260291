import { InMemoryCache } from '@apollo/client';
import {
  offsetLimitPagination,
  relayStylePagination,
} from '@apollo/client/utilities';

export function createApolloCache(): InMemoryCache {
  return new InMemoryCache({
    typePolicies: {
      BrandMember: {
        keyFields: ['userId', 'brandId'],
      },
      BrandKlaviyoIntegration: {
        keyFields: ['brandId'],
      },
      Query: {
        fields: {
          brandMembersPaginated: offsetLimitPagination(['brandId', 'filter']),
          brandLogEntriesConnection: relayStylePagination(),
          usersByBrandConnection: relayStylePagination(),
        },
      },
    },
  });
}
