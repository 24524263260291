import { Button } from '@addglowapp/components';
import { Popover, Portal, Transition } from '@headlessui/react';
import { clsx } from 'clsx';
import { addMonths, addYears } from 'date-fns';
import _ from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { DayPicker } from 'react-day-picker';
import { RxCaretDown } from 'react-icons/rx';
import { usePopper } from 'react-popper';
import { formatDateWithoutTime } from 'src/utils/date';

export interface DateRange {
  start: Date;
  end: Date;
}

interface DateRangePickerProps {
  className?: string;
  presetOptions?: { label: string; range: DateRange }[];
  value?: DateRange;
  onChange?: (value: DateRange) => void;
}

export function DateRangePicker({
  className,
  presetOptions,
  onChange,
  value,
}: DateRangePickerProps): React.JSX.Element {
  const selectedPresetLabel = presetOptions?.find((option) =>
    _.isEqual(option.range, value),
  )?.label;
  const selectedLabel = useMemo(() => {
    if (!value) {
      return 'Select Date Range';
    }

    if (selectedPresetLabel) {
      return selectedPresetLabel;
    }

    const start = formatDateWithoutTime(value.start);
    const end = formatDateWithoutTime(value.end);

    if (start === end) {
      return start;
    }

    return `${start} - ${end}`;
  }, [value, selectedPresetLabel]);

  const popperElementRef = useRef<HTMLDivElement | null>(null);
  const [referenceElement, setReferenceElement] =
    useState<HTMLButtonElement | null>();
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>();

  const [temporaryDateRange, setTemporaryDateRange] = useState<{
    from: Date | undefined;
    to?: Date;
  }>();

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-end',
    strategy: 'fixed',
  });

  // hack to detect if popover is closed
  useEffect(() => {
    if (!popperElement) {
      setTemporaryDateRange(undefined);
      if (temporaryDateRange?.from) {
        onChange?.({
          start: temporaryDateRange.from,
          end: temporaryDateRange.to || temporaryDateRange.from,
        });
      }
    }
  }, [popperElement, temporaryDateRange, onChange]);

  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            aria-label="Choose Date Range"
            ref={setReferenceElement}
            className={clsx(
              'flex min-w-[200px] items-center justify-between rounded-sm border bg-white p-2 text-left shadow-sm',
              className,
            )}
          >
            <div>{selectedLabel}</div>
            <RxCaretDown className="ml-2" />
          </Popover.Button>
          <Portal>
            <div
              ref={popperElementRef}
              style={styles.popper}
              {...attributes.popper}
              className="z-50"
            >
              <Transition
                show={open}
                enter="ease-out duration-100"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-100"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
                beforeEnter={() => setPopperElement(popperElementRef.current)}
                afterLeave={() => setPopperElement(null)}
              >
                <Popover.Panel>
                  {({ close }) => (
                    <div className="flex justify-around space-x-2 bg-white">
                      <div className="flex flex-col border-r border-gray-200">
                        {presetOptions?.map((option) => (
                          <button
                            className={clsx(
                              'px-4 py-2 text-left hover:bg-blue-800 hover:text-white',
                              selectedPresetLabel === option.label &&
                                'bg-blue-800 text-white',
                            )}
                            type="button"
                            key={option.label}
                            onClick={() => {
                              onChange?.(option.range);
                              close();
                            }}
                          >
                            {option.label}
                          </button>
                        ))}
                      </div>
                      <div className="flex flex-col items-end space-y-2 p-2">
                        <DayPicker
                          mode="range"
                          numberOfMonths={2}
                          fixedWeeks
                          defaultMonth={addMonths(new Date(), -1)}
                          disabled={[
                            { from: new Date(), to: addYears(new Date(), 1) },
                          ]}
                          onSelect={(range) => {
                            setTemporaryDateRange(range);
                          }}
                          selected={temporaryDateRange}
                        />
                        <Button
                          onClick={() => {
                            close();
                          }}
                          type="button"
                          color="primary"
                        >
                          Apply
                        </Button>
                      </div>
                    </div>
                  )}
                </Popover.Panel>
              </Transition>
            </div>
          </Portal>
        </>
      )}
    </Popover>
  );
}
