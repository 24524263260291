import { ErrorableLoader } from '@addglowapp/components';
import {
  useBrandMembersLazyQuery,
  useBrandMembersPaginatedQuery,
  useGetMemberGroupsQuery,
} from 'src/generated/graphql';
import { useBrandId } from 'src/hooks/useBrandId';
import { useDownloadCsv } from 'src/hooks/useDownloadCsv';
import { logAndFormatError } from '@src/services/error-formatter';
import { notEmpty } from '@src/utils/array';
import { brandMemberColumns } from './columns';
import { DataTable } from './data-table';
import { BrandMemberData } from './types';

export function MembersPage(): JSX.Element {
  const brandId = useBrandId();

  // Fetch paginated brand members
  const { data, error } = useBrandMembersPaginatedQuery({
    variables: {
      brandId: brandId,
    },
  });

  // Fetch member groups
  const { data: groupsData, error: groupsError } = useGetMemberGroupsQuery({
    variables: {
      brandId,
    },
  });

  // Lazy query for brand members with refetch capabilities
  const [queryBrandMembers, { refetch }] = useBrandMembersLazyQuery({
    variables: { brandId },
  });

  // Download CSV functionality
  const { downloadCsv } = useDownloadCsv({
    getData: async () => {
      const brandMembers = await queryBrandMembers();
      if (!brandMembers.data) {
        throw new Error('Failed to fetch brand members');
      }
      return brandMembers.data.brand.members.map((member) => ({
        firstName: member.user.firstName,
        lastName: member.user.lastName,
        username: member.user.username,
        email: member.user.email,
        skinType: member.user.skinType,
        skinTone: member.user.skinTone,
        hairColor: member.user.hairColor?.color ?? member.user.hairColorOther,
        hairTexture: member.user.hairTexture,
        hairLength: member.user.hairLength,
        joinedAt: member.user.dateJoined,
      }));
    },
    columns: [
      'firstName',
      'lastName',
      'username',
      'email',
      'skinType',
      'skinTone',
      'hairColor',
      'hairTexture',
      'hairLength',
      'joinedAt',
    ],
    filename: 'users.csv',
  });

  // Error and loading handling
  if (!data || !groupsData) {
    return <ErrorableLoader error={error ?? groupsError} />;
  }

  // Decide whether to show loyalty points
  const showLoyaltyPoints = !data.brand.isYotpoLoyaltyEnabled;

  function handleRefetch(): void {
    refetch().catch((e) => {
      logAndFormatError(e);
    });
  }
  // Map brand members to a usable structure
  const members = data.brandMembersPaginated.map(
    (member): BrandMemberData => ({
      userId: member.user.id,
      name: `${member.user.firstName ?? ''} ${member.user.lastName ?? ''}`,
      username: member.user.username ?? 'N/A',
      role: member.role,
      email: member.user.email,
      loyaltyPoints: member.loyaltyPoints,
      lifetimePoints: member.lifetimeLoyaltyPoints,
      groups: member.groupMemberships
        .map(
          (g) =>
            groupsData.memberGroups.find((mg) => mg.id === g.memberGroupId)!,
        )
        .filter(notEmpty),
      label: member.labels[0]?.labelOption,
      dateJoined: member.createdAt,
      isBanned: member.isBanned ?? false,
      communityDomain: data.brand.communityDomain,
      groupMembershipIds: member.groupMemberships.map((g) => g.memberGroupId),
      userModeratedRooms: member.user.userModeratedRooms,
    }),
  );

  // Generate table columns and refetch when necessary
  const filteredColumns = brandMemberColumns(handleRefetch).filter(
    (column) => column.id !== 'loyaltyPoints' || showLoyaltyPoints,
  );

  // Render the DataTable with the members data
  return (
    <div className="space-y-4">
      <h1>Community Members ({data.brand.memberCount})</h1>
      <DataTable
        columns={filteredColumns}
        data={members}
        downloadCsv={downloadCsv}
      />
    </div>
  );
}
