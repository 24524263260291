import { Button } from '@addglowapp/components';
import { TextInputField } from '@addglowapp/components';
import { toast } from '@addglowapp/components';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import LayoutSection from '@src/components/LayoutSection';
import {
  BrandEmailSettingsFragment,
  useUpdateBrandEmailSettingsMutation,
} from '@src/generated/graphql';
import { logAndFormatError } from '@src/services/error-formatter';

const emailSettingsFormSchema = z.object({
  replyToEmail: z.union([z.literal(''), z.string().email().nullish()]),
});

type FormValues = z.infer<typeof emailSettingsFormSchema>;

interface ReplyToEmailSectionProps {
  className?: string;
  brand: BrandEmailSettingsFragment;
}

export function ReplyToEmailSection({
  className,
  brand,
}: ReplyToEmailSectionProps): JSX.Element {
  const [updateBrand] = useUpdateBrandEmailSettingsMutation();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isDirty },
  } = useForm<FormValues>({
    resolver: zodResolver(emailSettingsFormSchema),
    values: {
      replyToEmail: brand.replyToEmail ?? '',
    },
  });

  const onSubmit = async (values: FormValues): Promise<void> => {
    return updateBrand({
      variables: {
        input: {
          id: brand.id,
          data: {
            replyToEmail: values.replyToEmail ? values.replyToEmail : null,
          },
        },
      },
    })
      .then(() => {
        toast.success('Email reply-to updated successfully!');
      })
      .catch((err) => {
        toast.error(
          logAndFormatError(err, 'Sorry, the action could not be completed.'),
        );
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={className}>
      <LayoutSection>
        <LayoutSection.Header title="Settings" />
        <LayoutSection.Body className="space-y-4">
          <TextInputField.Controller
            label="Reply-to Address"
            control={control}
            name="replyToEmail"
          />
          <p>
            Add your own reply-to email address for any emails we send out to
            community members.
          </p>
          <Button disabled={isSubmitting || !isDirty} type="submit">
            Save
          </Button>
        </LayoutSection.Body>
      </LayoutSection>
    </form>
  );
}
