import { useParams } from 'react-router-dom';

export function useBrandId(): string {
  const { brandId } = useParams<{ brandId: string }>();

  if (!brandId) {
    throw new Error('Brand ID is required');
  }

  return brandId;
}
