import { Button, Dialog, FormError, FormLabel } from '@addglowapp/components';
import { CheckedInput, TextInputField } from '@addglowapp/components';
import { toast } from '@addglowapp/components';
import { BRAND_WEBHOOK_EVENT_TYPES } from '@addglowapp/shared';
import { zodResolver } from '@hookform/resolvers/zod';
import { useController, useForm } from 'react-hook-form';
import { z } from 'zod';
import {
  BrandWebhookFragment,
  GetBrandWebhooksDocument,
  useCreateBrandWebhookMutation,
  useUpdateBrandWebhookMutation,
} from '@src/generated/graphql';
import { useBrandId } from '@src/hooks/useBrandId';
import { logAndFormatError } from '@src/services/error-formatter';

interface WebhookEditModalProps {
  existingWebhook?: BrandWebhookFragment | null;
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  onWebhookCreated: (webhook: BrandWebhookFragment) => void;
}

const formSchema = z.object({
  description: z.string().trim().min(1).max(30),
  endpointUrl: z
    .string()
    .url()
    .max(512)
    .refine(
      (value) => value.startsWith('https://'),
      'Endpoint URL must start with https://',
    ),
  eventTypes: z
    .array(
      z.object({
        eventType: z.string(),
      }),
    )
    .min(1),
});

type FormValues = z.infer<typeof formSchema>;

export function WebhookEditModal({
  existingWebhook,
  isOpen,
  onOpenChange,
  onWebhookCreated,
}: WebhookEditModalProps): JSX.Element {
  const { control, handleSubmit, reset } = useForm<FormValues>({
    values: {
      description: existingWebhook?.description ?? '',
      endpointUrl: existingWebhook?.endpointUrl ?? '',
      eventTypes: existingWebhook?.eventTypes ?? [],
    },
    resolver: zodResolver(formSchema),
  });
  const brandId = useBrandId();
  const [createBrandWebhook] = useCreateBrandWebhookMutation({
    refetchQueries: [
      {
        query: GetBrandWebhooksDocument,
        variables: { brandId },
      },
    ],
  });
  const [updateBrandWebhook] = useUpdateBrandWebhookMutation();

  const onSubmit = async (data: FormValues): Promise<void> => {
    try {
      if (existingWebhook) {
        await updateBrandWebhook({
          variables: {
            input: {
              id: existingWebhook.id,
              data,
            },
          },
        });
      } else {
        const { data: createdWebhook } = await createBrandWebhook({
          variables: {
            input: {
              data: {
                brandId,
                ...data,
              },
            },
          },
        });
        if (!createdWebhook) {
          throw new Error('Key was not created');
        }
        onWebhookCreated(createdWebhook.createBrandWebhook.brandWebhook);
      }
      onOpenChange(false);
      toast.success(
        existingWebhook
          ? 'Webhook updated successfully'
          : 'Webhook created successfully',
      );
      reset();
    } catch (error) {
      toast.error(logAndFormatError(error));
    }
  };

  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    control,
    name: 'eventTypes',
  });

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <Dialog.Content>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          <Dialog.Header>
            <Dialog.Title>
              {existingWebhook ? 'Edit Webhook' : 'New Webhook'}
            </Dialog.Title>
          </Dialog.Header>
          <div className="space-y-4">
            <TextInputField.Controller
              control={control}
              name="description"
              label="Description"
              autoComplete="off"
            />
            <TextInputField.Controller
              control={control}
              name="endpointUrl"
              label="Endpoint URL"
              autoComplete="off"
            />
            <div>
              <FormLabel>Events</FormLabel>
              <div className="border-b border-brand-border last:border-none">
                {BRAND_WEBHOOK_EVENT_TYPES.map((eventType) => (
                  <CheckedInput.Labelled
                    horizontalLabel
                    key={eventType.id}
                    label={eventType.name}
                    checked={value.some((v) => v.eventType === eventType.id)}
                    onChange={(checked) => {
                      if (checked) {
                        onChange([...value, { eventType: eventType.id }]);
                      } else {
                        onChange(
                          value.filter((v) => v.eventType !== eventType.id),
                        );
                      }
                    }}
                  />
                ))}
              </div>
            </div>
            {error && <FormError>{error.message}</FormError>}
          </div>
          <Dialog.Footer className="flex gap-4">
            <Dialog.Close asChild>
              <Button color="secondary">Cancel</Button>
            </Dialog.Close>
            <Button type="submit">
              {existingWebhook ? 'Save Changes' : 'Create Webhook'}
            </Button>
          </Dialog.Footer>
        </form>
      </Dialog.Content>
    </Dialog>
  );
}
