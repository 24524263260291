import {
  Button,
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableHead,
  TableCell,
  ErrorableLoader,
} from '@addglowapp/components';
import { ChangeEvent, useState } from 'react';
import { BsTrash } from 'react-icons/bs';
import {
  BannedWordFragment,
  useBannedWordQuery,
  useCreateBannedWordMutation,
  useDeleteBannedWordMutation,
} from 'src/generated/graphql';
import { useBrandId } from 'src/hooks/useBrandId';
import { logAndFormatError } from 'src/services/error-formatter';
import { formatDate } from 'src/utils/date';

interface RowProps {
  bannedWord: BannedWordFragment;
  onCompleted(): void;
}

function BannedWordRow({ bannedWord, onCompleted }: RowProps): JSX.Element {
  const [loading, setLoading] = useState(false);
  const [deleteWord] = useDeleteBannedWordMutation();

  const handleDelete = async (): Promise<void> => {
    setLoading(true);
    try {
      await deleteWord({
        variables: {
          input: {
            id: bannedWord.id,
          },
        },
      });
      setLoading(false);
      onCompleted();
    } catch (error) {
      setLoading(false);
      onCompleted();
    }
  };

  return (
    <TableRow
      key={bannedWord.id}
      className={
        loading
          ? 'pointer-events-none animate-pulse touch-none bg-gray-200'
          : ''
      }
    >
      <TableCell>
        <Button color="tertiary" onClick={handleDelete}>
          <BsTrash />
        </Button>
      </TableCell>
      <TableCell>{bannedWord.word}</TableCell>
      <TableCell>
        {bannedWord.createdAt ? formatDate(bannedWord.createdAt) : 'Unknown'}
      </TableCell>
    </TableRow>
  );
}

function BannedWordsPage(): JSX.Element {
  const brandId = useBrandId();
  const [createBannedWord] = useCreateBannedWordMutation();

  const { data, error, refetch } = useBannedWordQuery({
    variables: {
      brandId,
    },
  });

  const [word, setWord] = useState('');

  if (!data) {
    return <ErrorableLoader error={error} />;
  }

  const handleWordInput = (e: ChangeEvent<HTMLInputElement>): void => {
    setWord(e.target.value);
  };

  const handleAddBannedWord = async (): Promise<void> => {
    try {
      await createBannedWord({
        variables: {
          input: {
            data: {
              brandId,
              word,
            },
          },
        },
      });
      setWord('');
      await refetch();
    } catch (err) {
      logAndFormatError(err);
    }
  };

  return (
    <div className="pb-40">
      <h1>Banned Words</h1>
      <div>
        <h2 className="mb-2">
          Our moderation system supports <strong>wildcards (*)</strong> for
          flexible word filtering:
        </h2>
        <p>
          End Wildcard <strong>word*</strong>: Flags words starting with word.
        </p>
        <p className="ml-8">E.g., cat* flags cat, cats, catalog.</p>
        <p>
          Start Wildcard <strong>*word</strong>: Flags words ending with word.
        </p>
        <p className="ml-8">E.g., *cat flags cat, bobcat, educat.</p>
        <p>
          Both Ends Wildcard <strong>*word*</strong>: Flags words containing
          word.
        </p>
        <p className="ml-8">E.g., *cat* flags cat, bobcat, catastrophe.</p>
        <p className="mt-3">
          Note: Use wildcards carefully to avoid over-filtering.
        </p>
      </div>
      <div className="mt-6 overflow-x-auto">
        <input
          type="text"
          placeholder="Word to Ban"
          value={word}
          onChange={handleWordInput}
          className="rounded border border-gray-300 p-2"
        />
        <Button className="ml-4" onClick={handleAddBannedWord}>
          Add
        </Button>
      </div>

      <Table className="mt-8">
        <TableHeader>
          <TableRow className="border-b border-gray-200">
            <TableHead>Delete</TableHead>
            <TableHead>Word</TableHead>
            <TableHead>Created At</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {data.bannedWords.map((bannedWord: BannedWordFragment) => (
            <BannedWordRow
              key={`${bannedWord.id}`}
              bannedWord={bannedWord}
              onCompleted={refetch}
            />
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

export default BannedWordsPage;
