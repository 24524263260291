import clsx from 'clsx';

interface Props {
  className?: string;
  children?: React.ReactNode;
}

function LayoutSection({ className, children }: Props): JSX.Element {
  return (
    <div className={clsx('rounded-md border border-brand-border', className)}>
      {children}
    </div>
  );
}

interface LayoutSectionHeaderProps {
  className?: string;
  actions?: React.ReactNode;
  title: string;
}

LayoutSection.Header = function LayoutSectionHeader({
  className,
  actions,
  title,
}: LayoutSectionHeaderProps): JSX.Element {
  return (
    <div
      className={clsx(
        'rounded-t-md border-b border-gray-200 bg-gray-50 p-4',
        className,
      )}
    >
      <div className="flex items-center justify-between">
        <div className="font-bold">{title}</div>
        {actions}
      </div>
    </div>
  );
};

interface LayoutSectionBodyProps {
  className?: string;
  children: React.ReactNode;
}

LayoutSection.Body = function LayoutSectionBody({
  className,
  children,
}: LayoutSectionBodyProps): JSX.Element {
  return (
    <div className={clsx('rounded-b-md bg-white p-4', className)}>
      {children}
    </div>
  );
};

export default LayoutSection;
