import { Route, Routes } from 'react-router-dom';
import NotFoundPage from '../../NotFound.page';
import AnalyticsOverviewPage from './overview.page';
import AnalyticsVisitorsPage from './visitors.page';

function AnalyticsRoutes(): JSX.Element {
  return (
    <Routes>
      <Route index element={<AnalyticsOverviewPage />} />
      <Route path="visitors" element={<AnalyticsVisitorsPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default AnalyticsRoutes;
