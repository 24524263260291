import {
  Button,
  Dialog,
  TextInputField,
  MemberBadge,
} from '@addglowapp/components';
import { toast } from '@addglowapp/components';
import { updateBrandMemberBadgeSchema } from '@addglowapp/shared';
import { zodResolver } from '@hookform/resolvers/zod';
import { clsx } from 'clsx';
import { pick } from 'es-toolkit';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import {
  BrandMemberBadgeFragment,
  useUpdateBrandMemberBadgeMutation,
} from '@src/generated/graphql';
import { logAndFormatError } from '@src/services/error-formatter';
import ColorInput from '../../customization/theming/components/ColorInput';

interface MemberBadgeFormProps {
  memberBadge: BrandMemberBadgeFragment;
  className?: string;
  onSaved?: () => void;
}

type FormValues = z.infer<typeof updateBrandMemberBadgeSchema>;

// Note: Creation has not been supported yet because we don't have a need yet.

export function MemberBadgeForm({
  memberBadge,
  className,
  onSaved,
}: MemberBadgeFormProps): JSX.Element {
  const [updateBadge] = useUpdateBrandMemberBadgeMutation();

  const {
    handleSubmit,
    control,
    formState: { isSubmitting, isDirty },
    watch,
  } = useForm<FormValues>({
    resolver: zodResolver(updateBrandMemberBadgeSchema),
    defaultValues: {
      name: memberBadge?.name ?? '',
      backgroundColor: memberBadge?.backgroundColor ?? '',
      foregroundColor: memberBadge?.foregroundColor ?? '',
    },
  });

  const onSubmit = handleSubmit(async (data) => {
    try {
      await updateBadge({
        variables: {
          input: {
            id: memberBadge.id,
            data: pick(data, ['name', 'foregroundColor', 'backgroundColor']),
          },
        },
      });
      toast.success('Member badge updated successfully');
      onSaved?.();
    } catch (error) {
      toast.error(
        logAndFormatError(
          error,
          'Failed to save member badge. Please try again.',
        ),
      );
    }
  });

  const badge = watch();

  return (
    <form className={clsx('space-y-4', className)} onSubmit={onSubmit}>
      <TextInputField.Controller
        autoComplete="off"
        control={control}
        name="name"
        label="Name"
      />
      <div className="flex gap-4">
        <ColorInput.LabelledController
          control={control}
          className="flex-1"
          name="backgroundColor"
          label="Background Color"
        />
        <ColorInput.LabelledController
          control={control}
          className="flex-1"
          name="foregroundColor"
          label="Foreground Color"
        />
      </div>
      <div className="flex w-full flex-col items-center justify-center gap-2 border border-brand-border p-4">
        <MemberBadge badge={badge} />
        <span className="text-sm text-muted-foreground">Badge Preview</span>
      </div>
      <Dialog.Footer>
        <Dialog.Close>
          <Button color="secondary">Cancel</Button>
        </Dialog.Close>
        <Button
          type="submit"
          color="primary"
          disabled={isSubmitting || !isDirty}
        >
          Save
        </Button>
      </Dialog.Footer>
    </form>
  );
}
