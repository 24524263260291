import { Button } from '@addglowapp/components';
import { TextAreaInputField, ToggleInput } from '@addglowapp/components';
import { toast } from '@addglowapp/components';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import LayoutSection from '@src/components/LayoutSection';
import {
  BrandEmailSettingsFragment,
  useUpdateBrandEmailSettingsMutation,
} from '@src/generated/graphql';
import { logAndFormatError } from '@src/services/error-formatter';

const emailSettingsFormSchema = z.object({
  isEmailDigestEnabled: z.boolean(),
  emailDigestIntroMessage: z.string().max(1000),
});

type FormValues = z.infer<typeof emailSettingsFormSchema>;

interface WeeklyDigestSectionProps {
  className?: string;
  brand: BrandEmailSettingsFragment;
}

export function WeeklyDigestSection({
  className,
  brand,
}: WeeklyDigestSectionProps): JSX.Element {
  const [updateBrand] = useUpdateBrandEmailSettingsMutation();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isDirty },
  } = useForm<FormValues>({
    resolver: zodResolver(emailSettingsFormSchema),
    values: brand,
  });

  const onSubmit = async (values: FormValues): Promise<void> => {
    return updateBrand({
      variables: {
        input: {
          id: brand.id,
          data: values,
        },
      },
    })
      .then(() => {
        toast.success('Weekly digest updated successfully!');
      })
      .catch((err) => {
        toast.error(
          logAndFormatError(err, 'Sorry, the action could not be completed.'),
        );
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={className}>
      <LayoutSection>
        <LayoutSection.Header title="Weekly Digest" />
        <LayoutSection.Body className="space-y-4">
          <ToggleInput.LabelledController
            label="Enable Weekly Digest Email"
            control={control}
            name="isEmailDigestEnabled"
          />
          <p>
            Every week, your community members will be sent a digest email
            featuring the week&apos;s top posts. Should they choose, they have
            the option to unsubscribe at any time.
          </p>
          <hr />
          <div className="text-sm font-medium">Custom Message</div>
          <p>Customize the message at the top of your digest email</p>
          <TextAreaInputField.Controller
            control={control}
            name="emailDigestIntroMessage"
          />
          <Button disabled={isSubmitting || !isDirty} type="submit">
            Save
          </Button>
        </LayoutSection.Body>
      </LayoutSection>
    </form>
  );
}
