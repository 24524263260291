import { useEffect } from 'react';

const useOutsideClick = (
  elem: HTMLElement | null | undefined,
  handler: () => void,
): void => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (elem && !elem.contains(event.target as Node)) {
        handler();
      }
    };

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [elem, handler]); // Re-run if ref or handler changes
};

export default useOutsideClick;
