import { useBrandQuery } from 'src/generated/graphql';
import { useBrandId } from 'src/hooks/useBrandId';

function BrandHomePage(): JSX.Element {
  const brandId = useBrandId();

  const { data } = useBrandQuery({
    variables: {
      id: brandId,
    },
  });

  return (
    <div>
      <h1>Welcome to the {data?.brand.name} AddGlow dashboard.</h1>
    </div>
  );
}

export default BrandHomePage;
