import {
  Dialog,
  ErrorableLoader,
  getProfileImageUrl,
  ScrollArea,
} from '@addglowapp/components';
import { LinkButton, TextInputField } from '@addglowapp/components';
import { toast } from '@addglowapp/components';
import { useState, useEffect } from 'react';
import { LuCheckSquare, LuPlusSquare } from 'react-icons/lu';
import {
  BrandMember,
  useBrandMembersPaginatedQuery,
  useUpdateBrandMemberMutation,
} from '@src/generated/graphql';
import { useBrandId } from '@src/hooks/useBrandId';
import { logAndFormatError } from '@src/services/error-formatter';

interface ManageMembersDialogProps {
  groupId: string;
  groupName: string;
  currentMembers: string[];
  onAddMember: () => void;
  open?: boolean;
  onOpenChange?: (isOpen: boolean) => void;
  children?: React.ReactNode;
}

export function ManageMembersDialog({
  groupId,
  groupName,
  currentMembers,
  onAddMember,
  open,
  children,
}: ManageMembersDialogProps): JSX.Element {
  const brandId = useBrandId();
  const [isOpen, setIsOpen] = useState(open || false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredMembers, setFilteredMembers] = useState<BrandMember[]>([]);

  const [addedMembers, setAddedMembers] = useState<string[]>([]);

  const { data, error, loading } = useBrandMembersPaginatedQuery({
    variables: { brandId },
  });

  const [updateBrandMember] = useUpdateBrandMemberMutation();

  // Effect to filter members based on the search term
  useEffect(() => {
    if (data?.brandMembersPaginated) {
      const filtered = data.brandMembersPaginated.filter((member) =>
        member.user?.username?.toLowerCase().includes(searchTerm.toLowerCase()),
      );
      setFilteredMembers(filtered as BrandMember[]);
    }
  }, [data, searchTerm]);

  if (!data) {
    return <ErrorableLoader error={error} />;
  }

  const handleAddMemberToGroup = async (
    userId: string,
    existingGroupIds: string[],
  ): Promise<void> => {
    try {
      const updatedGroupIds = [
        ...new Set([...(existingGroupIds || []), groupId]),
      ];

      await updateBrandMember({
        variables: {
          input: {
            id: { userId, brandId },
            data: { memberGroupIds: updatedGroupIds },
          },
          brandId,
        },
      });

      setAddedMembers((prevAddedMembers) => [...prevAddedMembers, userId]);
      onAddMember();

      toast.success('Member added to the group');
    } catch (err) {
      toast.error(logAndFormatError(err, 'Failed to add member to the group.'));
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      {children && <Dialog.Trigger asChild>{children}</Dialog.Trigger>}
      <Dialog.Content aria-describedby={undefined}>
        <Dialog.Header>
          <Dialog.Title>Add Members to {groupName}</Dialog.Title>
        </Dialog.Header>

        <TextInputField
          placeholder="Search..."
          value={searchTerm}
          label="Search members"
          onChange={(e) => setSearchTerm(e)}
        />

        {loading ? (
          <ErrorableLoader />
        ) : (
          <div className="space-y-4">
            <ScrollArea className="-mr-4 h-[50vh] pr-4">
              <div className="space-y-4">
                <p>Results</p>

                {filteredMembers.map((member) => {
                  const isMemberInGroup =
                    currentMembers.includes(member.user.id) ||
                    addedMembers.includes(member.user.id);

                  return (
                    <div
                      key={member.user.id}
                      className="flex items-center justify-between"
                    >
                      <div className="flex items-center gap-2">
                        <img
                          src={getProfileImageUrl(
                            member.user.avatarFile?.hostedUrl,
                            member.user.username,
                            160,
                          )}
                          alt={`${member.user.username}'s avatar`}
                          className="h-10 w-10 rounded-md object-cover"
                        />
                        <div className="flex flex-col">
                          <div className="font-bold">
                            {member.user.firstName} {member.user.lastName}
                          </div>
                          <p className="text-xs opacity-70">
                            {member.user.username} • {member.user.email}
                          </p>
                        </div>
                      </div>
                      <LinkButton
                        onClick={() =>
                          handleAddMemberToGroup(
                            member.user.id,
                            member.groupMemberships.map((g) => g.memberGroupId),
                          )
                        }
                        disabled={isMemberInGroup}
                      >
                        {isMemberInGroup ? (
                          <LuCheckSquare className="text-brand-text-content opacity-50" />
                        ) : (
                          <LuPlusSquare className="text-brand-text-content" />
                        )}
                      </LinkButton>
                    </div>
                  );
                })}
              </div>
            </ScrollArea>
          </div>
        )}
      </Dialog.Content>
    </Dialog>
  );
}
