import {
  ColorCircle,
  HairTextureIcon,
  ProfileInfo,
} from '@addglowapp/components';
import { ErrorableLoader, ToggleInput } from '@addglowapp/components';
import { toast } from '@addglowapp/components';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import LayoutSection from 'src/components/LayoutSection';
import { useBrandQuery, useUpdateBrandMutation } from 'src/generated/graphql';
import { useBrandId } from 'src/hooks/useBrandId';
import { logAndFormatError } from 'src/services/error-formatter';
import { CustomTraitSection } from './CustomTraitSection';

const formSchema = z.object({
  hasSkinTraits: z.boolean().nullish(),
  hasHairTraits: z.boolean().nullish(),
  hasShoeTraits: z.boolean().nullish(),
});

type FormValues = z.infer<typeof formSchema>;

export function ProfilesPage(): JSX.Element {
  const brandId = useBrandId();
  const { data, error, refetch } = useBrandQuery({
    variables: {
      id: brandId,
    },
  });

  const [updateBrand] = useUpdateBrandMutation();

  const {
    formState: { isSubmitting },
  } = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    values: data?.brand,
  });

  const { hasShoeTraits, hasHairTraits, hasSkinTraits } = data?.brand ?? {};

  const handleToggleChange = async (
    name: keyof FormValues,
    value: boolean,
  ): Promise<void> => {
    await updateBrand({
      variables: {
        input: {
          id: brandId,
          data: { [name]: value },
        },
      },
    })
      .then(() => {
        toast.success('Profile data updated successfully!');
      })
      .catch((err) => {
        refetch().catch((err) => logAndFormatError(err));
        toast.error(
          logAndFormatError(err, 'Sorry, the action could not be completed.'),
        );
      });
  };

  if (!data) {
    return <ErrorableLoader error={error} />;
  }

  return (
    <div className="space-y-2">
      <h1>User Profiles</h1>
      <LayoutSection>
        <LayoutSection.Header title="Profile Trait Groups" />
        <LayoutSection.Body>
          <div className="max-w-2xl space-y-8">
            <p className="border-b pb-8">
              Add user profile traits that your community members can fill in
              and show on their profiles.
            </p>
            <div className="space-y-4 border-b border-brand-border pb-8">
              <div className="flex justify-between">
                <h2>Skin</h2>
                <ToggleInput.Labelled
                  checked={hasSkinTraits ?? false}
                  onChange={(isEnabled) =>
                    handleToggleChange('hasSkinTraits', isEnabled)
                  }
                  label={hasSkinTraits ? 'Disable' : 'Enable'}
                  disabled={isSubmitting}
                />
              </div>
              <p>Allow users to add the following to their profiles:</p>
              <ProfileInfo title="Skin">
                <ProfileInfo.Detail label="Skin Type" value="Sensitive" />
                <ProfileInfo.Detail
                  label="Skin Tone"
                  icon={<ColorCircle color="#584025" size="small" />}
                  value="Fair"
                />
                <ProfileInfo.Detail label="Skin Concern" value="Sensitivity" />
                <ProfileInfo.Detail
                  label="Eye Color"
                  icon={<ColorCircle mixed size="small" />}
                  value="Mixed"
                />
              </ProfileInfo>
            </div>

            <div className="space-y-4 border-b border-brand-border pb-8">
              <div className="flex justify-between">
                <h2>Hair</h2>
                <ToggleInput.Labelled
                  checked={hasHairTraits ?? false}
                  onChange={(isEnabled) =>
                    handleToggleChange('hasHairTraits', isEnabled)
                  }
                  label={hasHairTraits ? 'Disable' : 'Enable'}
                  disabled={isSubmitting}
                />
              </div>
              <p>Allow users to add the following to their profiles:</p>
              <ProfileInfo title="Hair">
                <ProfileInfo.Detail
                  label="Hair Color"
                  icon={<ColorCircle color="#816346" size="small" />}
                  value="Brown"
                />
                <ProfileInfo.Detail
                  label="Hair Texture"
                  icon={
                    <HairTextureIcon
                      className="h-3 w-3"
                      hairTexture="CURLY_3A"
                    />
                  }
                  value="Curly 3A"
                />
                <ProfileInfo.Detail label="Hair Length" value="Short" />
                <ProfileInfo.Detail label="Hair Concern" value="Dry Scalp" />
              </ProfileInfo>
            </div>

            <div className="space-y-4">
              <div className="flex justify-between">
                <h2>Shoes</h2>
                <ToggleInput.Labelled
                  checked={hasShoeTraits ?? false}
                  label={hasShoeTraits ? 'Disable' : 'Enable'}
                  onChange={(isEnabled) =>
                    handleToggleChange('hasShoeTraits', isEnabled)
                  }
                  disabled={isSubmitting}
                />
              </div>
              <p>Allow users to add the following to their profiles:</p>
              <ProfileInfo title="Shoes">
                <ProfileInfo.Detail label="Shoe Size" value="US 9" />
                <ProfileInfo.Detail label="Shoe Width" value="Narrow" />
                <ProfileInfo.Detail label="Bunions" value="No" />
                <ProfileInfo.Detail
                  label="Favorite Shoe Type"
                  value="Sneakers"
                />
              </ProfileInfo>
            </div>

            <p>
              Disabling any of the above options will hide the data from
              profiles in your community only.
            </p>
          </div>
        </LayoutSection.Body>
      </LayoutSection>
      <LayoutSection>
        <LayoutSection.Header title="Custom Traits" />
        <LayoutSection.Body>
          <div className="max-w-2xl space-y-8">
            <CustomTraitSection brand={data?.brand} refetch={refetch} />
          </div>
        </LayoutSection.Body>
      </LayoutSection>
    </div>
  );
}
