import {
  ErrorableLoader,
  Button,
  TextAreaInputField,
} from '@addglowapp/components';
import { toast } from '@addglowapp/components';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import LayoutSection from 'src/components/LayoutSection';
import {
  useBrandGuidelinesQuery,
  useUpdateBrandGuidelinesMutation,
} from 'src/generated/graphql';
import { useBrandId } from 'src/hooks/useBrandId';
import { logAndFormatError } from 'src/services/error-formatter';

const submitGuidelinesFormSchema = z.object({
  communityGuidelines: z.string().trim().min(1).max(5000),
});

type SubmitGuidelinesFormSchema = z.infer<typeof submitGuidelinesFormSchema>;

function GuidelinesPage(): JSX.Element {
  const {
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { isSubmitting, isDirty },
  } = useForm<SubmitGuidelinesFormSchema>({
    resolver: zodResolver(submitGuidelinesFormSchema),
  });

  const brandId = useBrandId();
  const [updateBrandGuidelines] = useUpdateBrandGuidelinesMutation();

  const { data, error } = useBrandGuidelinesQuery({
    variables: {
      id: brandId,
    },
  });

  useEffect(() => {
    if (data) {
      setValue('communityGuidelines', data.brand.communityGuidelines ?? '');
    }
  }, [data, setValue]);

  if (!data) {
    return <ErrorableLoader error={error} />;
  }

  const handleUpdateGuidelines = async (
    formData: SubmitGuidelinesFormSchema,
  ): Promise<void> => {
    try {
      await updateBrandGuidelines({
        variables: {
          input: {
            id: brandId,
            data: {
              ...formData,
            },
          },
        },
      });
      toast.success('Saved successfully');
      reset({
        communityGuidelines: formData.communityGuidelines,
      });
    } catch (err) {
      toast.error(logAndFormatError(err));
    }
  };

  return (
    <div>
      <h1>Community Guidelines</h1>
      <LayoutSection>
        <form onSubmit={handleSubmit(handleUpdateGuidelines)}>
          <LayoutSection.Header
            title="Community Guidelines"
            actions={
              <Button
                className="w-48"
                type="submit"
                disabled={isSubmitting || !isDirty}
              >
                Save
              </Button>
            }
          />

          <LayoutSection.Body>
            <div className="mt-6 flex max-w-2xl flex-col overflow-x-auto">
              <TextAreaInputField.Controller
                control={control}
                label="Community Guidelines Copy"
                name="communityGuidelines"
                rows={20}
              />
            </div>
          </LayoutSection.Body>
        </form>
      </LayoutSection>
    </div>
  );
}

export default GuidelinesPage;
