import { ErrorableLoader } from '@addglowapp/components';
import { useGetBrandLoyaltyPageQuery } from 'src/generated/graphql';
import { useBrandId } from 'src/hooks/useBrandId';
import RewardsSection from './components/RewardsSection';
import SettingsSection from './components/SettingsSection';

function LoyaltyPage(): JSX.Element {
  const brandId = useBrandId();
  const { data, error } = useGetBrandLoyaltyPageQuery({
    variables: { brandId },
  });

  if (!data) {
    return <ErrorableLoader error={error} />;
  }

  return (
    <div>
      <h1>Loyalty points</h1>
      <div className="mt-2 space-y-2 md:space-y-4">
        <SettingsSection brandData={data} />
        <RewardsSection brandData={data} />
      </div>
    </div>
  );
}

export default LoyaltyPage;
