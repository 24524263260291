import {
  Button,
  MemberBadge,
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableHead,
  TableCell,
} from '@addglowapp/components';
import { useConfirmDialog } from '@addglowapp/components';
import { toast } from '@addglowapp/components';
import { LuTrash2, LuPencil } from 'react-icons/lu';
import { useNavigate } from 'react-router-dom';
import {
  GetMemberGroupsDocument,
  MemberGroupFragment,
  useDeleteMemberGroupMutation,
} from '@src/generated/graphql';
import { useBrandId } from '@src/hooks/useBrandId';
import { logAndFormatError } from '@src/services/error-formatter';
import { formatDate } from '@src/utils/date';
import { MemberGroupDialog } from './MemberGroupDialog';

interface MemberGroupTableProps {
  memberGroups: MemberGroupFragment[];
}

export function MemberGroupTable({
  memberGroups,
}: MemberGroupTableProps): JSX.Element {
  const brandId = useBrandId();
  const { requestConfirm } = useConfirmDialog();
  const [deleteMemberGroup, { loading: deleteLoading }] =
    useDeleteMemberGroupMutation({
      refetchQueries: [GetMemberGroupsDocument],
    });

  const navigate = useNavigate();

  const onDelete = (memberGroup: MemberGroupFragment): void => {
    requestConfirm({
      title: 'Delete Member Group',
      content: `Are you sure you want to delete ${memberGroup.name}?`,
      onConfirm: () => {
        deleteMemberGroup({
          variables: {
            input: { id: memberGroup.id },
          },
        })
          .then(() => {
            toast.success('Member group deleted');
          })
          .catch((error) => {
            toast.error(logAndFormatError(error));
          });
      },
    });
  };

  const goToGroupDetails = (groupId: string): void => {
    navigate(`/${brandId}/members/groups/${groupId}`);
  };

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Actions</TableHead>
          <TableHead>Name</TableHead>
          <TableHead>Badge</TableHead>
          <TableHead>Members</TableHead>
          <TableHead>Created At</TableHead>
          <TableHead>Manage</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {memberGroups.map((memberGroup) => (
          <TableRow key={memberGroup.id}>
            <TableCell className="space-x-2">
              <Button
                icon={<LuTrash2 />}
                size="round"
                color="transparent"
                onClick={() => onDelete(memberGroup)}
                disabled={deleteLoading}
              />
              <MemberGroupDialog memberGroup={memberGroup}>
                <Button icon={<LuPencil />} size="round" color="transparent" />
              </MemberGroupDialog>
            </TableCell>
            <TableCell>{memberGroup.name}</TableCell>
            <TableCell>
              {memberGroup.brandMemberBadge && (
                <MemberBadge badge={memberGroup.brandMemberBadge} />
              )}
            </TableCell>
            <TableCell>
              <button
                className="cursor-pointer text-brand-background-primary underline"
                onClick={() => goToGroupDetails(memberGroup.id)}
              >
                {memberGroup.memberCount}
              </button>
            </TableCell>
            <TableCell>{formatDate(memberGroup.createdAt)}</TableCell>
            <TableCell>
              <button
                className="cursor-pointer text-brand-background-primary underline"
                onClick={() => goToGroupDetails(memberGroup.id)}
              >
                Manage Members
              </button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
