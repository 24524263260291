import { ErrorableLoader } from '@addglowapp/components';
import { useGetShopifyIntegrationQuery } from 'src/generated/graphql';
import { useBrandId } from 'src/hooks/useBrandId';
import { ShopifyApiKeySection } from './ShopifyApiKeySection';
import { ShopifyProductSyncSection } from './ShopifyProductSyncSection';

export function ShopifyPage(): JSX.Element {
  const brandId = useBrandId();
  const { data, error } = useGetShopifyIntegrationQuery({
    variables: {
      id: brandId,
    },
    pollInterval: 5000,
  });

  if (!data) {
    return <ErrorableLoader error={error} />;
  }

  return (
    <div className="space-y-2">
      <h1>Shopify</h1>
      <ShopifyApiKeySection
        brandId={brandId}
        shopifyIntegration={data.brand.shopifyIntegration}
      />
      {data.brand.shopifyIntegration && (
        <ShopifyProductSyncSection
          shopifyIntegration={data.brand.shopifyIntegration}
        />
      )}
    </div>
  );
}
