import { Button, Card, ErrorableLoader } from '@addglowapp/components';
import { Navigate } from 'react-router-dom';
import { useCurrentUser } from 'src/hooks/useCurrentUser';
import { useLogOut } from '@src/hooks/useLogOut';

function HomePage(): JSX.Element {
  const { user, error } = useCurrentUser();
  const logout = useLogOut();

  if (!user) {
    return <ErrorableLoader error={error} />;
  }

  const adminMembership = user.brandAdminMemberships[0];

  if (!adminMembership) {
    return (
      <div className="flex h-full flex-col items-center justify-center">
        <Card className="max-w-xl space-y-4" padding>
          <div>You must be an admin of a brand in order to use this page.</div>
          <div className="flex gap-4">
            <Button onClick={logout} className="mt-4">
              Switch Account
            </Button>
            <a href="https://www.addglow.com">
              <Button className="mt-4" color="secondary">
                Go to AddGlow
              </Button>
            </a>
          </div>
        </Card>
      </div>
    );
  }

  return <Navigate to={`/${adminMembership.brandId}/analytics`} />;
}

export default HomePage;
