import {
  Button,
  ErrorableLoader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@addglowapp/components';
import { toast } from '@addglowapp/components';
import { ChangeEvent, useState } from 'react';
import { BsTrash } from 'react-icons/bs';
import {
  PostTagOptionFragment,
  useCreatePostTagOptionMutation,
  useDeletePostTagOptionMutation,
  usePostTagOptionQuery,
} from 'src/generated/graphql';
import { useBrandId } from 'src/hooks/useBrandId';
import { logAndFormatError } from 'src/services/error-formatter';
import { formatDate } from 'src/utils/date';

interface RowProps {
  postTagOption: PostTagOptionFragment;
  onCompleted(): void;
}

function TagRow({ postTagOption, onCompleted }: RowProps): JSX.Element {
  const [loading, setLoading] = useState(false);
  const [deleteTag] = useDeletePostTagOptionMutation();

  const handleDelete = async (): Promise<void> => {
    setLoading(true);
    try {
      await deleteTag({
        variables: {
          input: {
            id: postTagOption.id,
          },
        },
      });
      onCompleted();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      onCompleted();
    }
  };

  return (
    <TableRow
      key={postTagOption.id}
      className={
        loading
          ? 'pointer-events-none animate-pulse touch-none bg-gray-200'
          : ''
      }
    >
      <TableCell>
        <Button color="tertiary" onClick={handleDelete}>
          <BsTrash />
        </Button>
      </TableCell>
      <TableCell>{postTagOption.name}</TableCell>
      <TableCell>{postTagOption.tagCount}</TableCell>
      <TableCell>
        {postTagOption.createdAt
          ? formatDate(postTagOption.createdAt)
          : 'Unknown'}
      </TableCell>
    </TableRow>
  );
}

function TagsPage(): JSX.Element {
  const brandId = useBrandId();
  const [createPostTagOption] = useCreatePostTagOptionMutation();

  const { data, error, refetch } = usePostTagOptionQuery({
    variables: {
      brandId,
    },
  });

  const [tagInput, setTagInput] = useState('');

  if (!data) {
    return <ErrorableLoader error={error} />;
  }

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setTagInput(e.target.value);
  };

  const handleAddTag = async (): Promise<void> => {
    try {
      await createPostTagOption({
        variables: {
          input: {
            data: {
              brandId,
              name: tagInput,
            },
          },
        },
      });
      setTagInput('');
      await refetch();
    } catch (err) {
      toast.error(logAndFormatError(err));
    }
  };

  return (
    <div className="pb-40">
      <h1>Tags</h1>
      <div className="mt-6 overflow-x-auto">
        <input
          type="text"
          placeholder="Tag Name"
          value={tagInput}
          onChange={handleSearchChange}
          className="rounded border border-gray-300 p-2"
        />
        <Button className="ml-4" onClick={handleAddTag}>
          Add Tag
        </Button>
      </div>

      <Table className="mt-8">
        <TableHeader>
          <TableRow className="border-b border-gray-200">
            <TableHead>Delete</TableHead>
            <TableHead>TAG</TableHead>
            <TableHead># of tagged posts</TableHead>
            <TableHead>Created At</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {data.postTagOptions.map((member) => (
            <TagRow
              key={member.id}
              postTagOption={member}
              onCompleted={refetch}
            />
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

export default TagsPage;
