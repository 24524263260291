import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@addglowapp/components';
import { Alert, ErrorableLoader } from '@addglowapp/components';
import { toast } from '@addglowapp/components';
import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { z } from 'zod';
import { useGetBrandLogEntriesQuery } from '@src/generated/graphql';
import { useBrandId } from '@src/hooks/useBrandId';
import { logAndFormatError } from '@src/services/error-formatter';
import { formatDate } from '@src/utils/date';

const PAGE_SIZE = 30;

export function LogEntriesPage(): JSX.Element {
  const brandId = useBrandId();
  const { data, error, loading, fetchMore } = useGetBrandLogEntriesQuery({
    variables: {
      first: PAGE_SIZE,
      where: { brandId },
    },
    fetchPolicy: 'cache-and-network',
  });
  const { inView, ref } = useInView();

  const { endCursor, hasNextPage } =
    data?.brandLogEntriesConnection.pageInfo ?? {};
  const shouldFetch = !loading && data && inView && hasNextPage;

  useEffect(() => {
    if (shouldFetch) {
      fetchMore({
        variables: {
          after: endCursor,
        },
      }).catch((err) => {
        toast.error(logAndFormatError(err));
      });
    }
  }, [shouldFetch, fetchMore, endCursor]);

  if (!data) {
    return <ErrorableLoader error={error} />;
  }

  function formatInfo(info: unknown): string {
    // try parse info
    try {
      // check if parsed is an object
      const parsedRecord = z
        .record(z.coerce.string())
        .parse(JSON.parse(info as string));
      return Object.keys(parsedRecord)
        .map((key) => `${key}: ${parsedRecord[key]}`)
        .join('\n');
    } catch (e) {
      return info as string;
    }
  }

  const logEntries = data.brandLogEntriesConnection.edges;

  return (
    <div className="max-w-5xl space-y-4">
      <h1>Admin Actions</h1>
      {logEntries.length === 0 ? (
        <Alert type="info">No log entries found</Alert>
      ) : (
        <div className="rounded-md border">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Time</TableHead>
                <TableHead>Admin</TableHead>
                <TableHead>Action</TableHead>
                <TableHead>Details</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody className="bg-white">
              {data.brandLogEntriesConnection.edges.map(({ node }) => (
                <TableRow key={node.id}>
                  <TableCell>{formatDate(node.createdAt)}</TableCell>
                  <TableCell>
                    {node.user
                      ? `${node.user.firstName} ${node.user.lastName ?? ''}`
                      : 'Deleted User'}
                  </TableCell>
                  <TableCell>{node.action}</TableCell>
                  <TableCell className="whitespace-pre-wrap font-mono text-sm">
                    {formatInfo(node.info)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      )}

      <div ref={ref} />
    </div>
  );
}
