import { GrowthBook } from '@growthbook/growthbook-react';
import { config } from './config';
import { logError } from './error-logger';

const GROWTHBOOK_API_HOST = 'https://cdn.growthbook.io';

export type GrowthbookFeatureFlag = 'hide-powered-by' | 'hide-post-approvals';

export interface GrowthBookAttributes {
  userId?: string;
  host?: string;
  communityDomain?: string;
}

export const growthbook = new GrowthBook({
  apiHost: GROWTHBOOK_API_HOST,
  clientKey: config.VITE_GROWTHBOOK_CLIENT_KEY,
  enableDevMode: config.VITE_ENVIRONMENT === 'development',
});

export function setGrowthbookAttributes(
  attributes: GrowthBookAttributes,
): void {
  growthbook.setAttributes(attributes).catch((err) => logError(err));
}
