import { getImpersonationUserId } from '@src/services/brand-impersonation-auth';
import { useSession } from './useSession';

/**
 * Returns the user ID of the currently authenticated user.
 *
 * If the user is impersonating another user, the impersonated user ID is returned.
 */
export function useRequiredUserId(): string {
  const impersonateId = getImpersonationUserId();
  const { userId } = useSession();
  if (impersonateId) return impersonateId;
  if (!userId) {
    throw new Error('User is not authenticated');
  }
  return userId;
}
