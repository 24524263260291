import { GrowthBookProvider } from '@growthbook/growthbook-react';
import { useEffect } from 'react';
import { logError } from '@src/services/error-logger';
import { growthbook } from '@src/services/growthbook';

export function AppGrowthBookWrapper({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  useEffect(() => {
    growthbook
      .init({ streaming: true })
      .then((result) => {
        if (result.error) {
          throw result.error;
        }
      })
      .catch((err) => logError(err));
  }, []);

  return (
    <GrowthBookProvider growthbook={growthbook}>{children}</GrowthBookProvider>
  );
}
