import { Button, Dialog, FormLabel } from '@addglowapp/components';
import { TextInputField } from '@addglowapp/components';
import { toast } from '@addglowapp/components';
import { BiCopy } from 'react-icons/bi';
import { BrandWebhookFragment } from '@src/generated/graphql';

interface WebhookShowModalProps {
  brandWebhook: BrandWebhookFragment | undefined;
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
}

export function WebhookShowModal({
  brandWebhook,
  isOpen,
  onOpenChange,
}: WebhookShowModalProps): JSX.Element | null {
  if (!brandWebhook) {
    return null;
  }

  function handleCopy(): void {
    if (!brandWebhook) return;
    navigator.clipboard
      .writeText(brandWebhook.signingSecret)
      .then(() => {
        toast.success('Signing secret copied to clipboard!');
      })
      .catch(() => {
        toast.error('Could not copy, please select and copy manually.');
      });
  }
  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <Dialog.Content>
        <Dialog.Header>
          <Dialog.Title>{brandWebhook.description} Webhook</Dialog.Title>
        </Dialog.Header>
        <div>
          <FormLabel>Signing Secret</FormLabel>
          <div className="relative w-full">
            <TextInputField
              readOnly
              value={brandWebhook.signingSecret}
              onFocus={(e) => {
                e.target.select();
              }}
            />
            <button
              className="absolute bottom-2 right-4 top-2 flex items-center justify-center"
              onClick={handleCopy}
            >
              <BiCopy />
            </button>
          </div>
        </div>
        <TextInputField
          readOnly
          label="Endpoint URL"
          value={brandWebhook.endpointUrl}
        />
        <Dialog.Footer className="flex gap-4">
          <Dialog.Close asChild>
            <Button color="secondary">Close</Button>
          </Dialog.Close>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog>
  );
}
