import {
  headerButtonIconTypeMapping,
  SelectField,
} from '@addglowapp/components';
import {
  Button,
  CheckedInput,
  ErrorableLoader,
  TextInputField,
} from '@addglowapp/components';
import { toast } from '@addglowapp/components';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import LayoutSection from 'src/components/LayoutSection';
import {
  BrandFragment,
  useBrandFontOptionsQuery,
  useBrandQuery,
  useUpdateBrandMutation,
  HeaderButtonIconType,
} from 'src/generated/graphql';
import { useBrandId } from 'src/hooks/useBrandId';
import { logAndFormatError } from 'src/services/error-formatter';
import BrandingAssetsSection from './components/BrandingAssetsSection';
import ColorsSection from './components/ColorsSection';

interface Props {
  brand: BrandFragment;
  brandId: string;
}

const HeaderButtonIconTypeValues = [
  'BOOKMARK',
  'CALENDAR',
  'LOCATION',
  'MOBILE',
  'NOTES',
  'SHOP',
  'SHOPIFY',
] as const;

export const submitNameAndLinksFormSchema = z.object({
  name: z.string().trim().min(1),
  shopUrl: z.string().nullish(),
  headerButtonIcon: z.enum(HeaderButtonIconTypeValues),
  headerButtonText: z.string().trim().min(1).max(20),
  headerButtonOpenInNewWindow: z.boolean(),
  about: z.string().nullish(),
});

export type SubmitNameAndLinksFormData = z.infer<
  typeof submitNameAndLinksFormSchema
>;

function NameAndLinksSection({ brand, brandId }: Props): JSX.Element {
  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting, isDirty },
  } = useForm<SubmitNameAndLinksFormData>({
    resolver: zodResolver(submitNameAndLinksFormSchema),
    defaultValues: brand,
  });

  const [updateBrand] = useUpdateBrandMutation();

  const onSubmit = async (data: SubmitNameAndLinksFormData): Promise<void> => {
    updateBrand({
      variables: {
        input: {
          id: brandId,
          data,
        },
      },
    })
      .then((val) => {
        const brandData = val.data?.updateBrand.brand;
        if (brandData) {
          reset(brandData);
        }
        toast.success('Brand data updated successfully.');
      })
      .catch((error) => {
        toast.error(
          logAndFormatError(error, 'Sorry, the action could not be completed.'),
        );
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <LayoutSection>
        <LayoutSection.Header
          title="Name and Links"
          actions={
            <div className="flex space-x-2">
              <Button
                color="secondary"
                onClick={() => {
                  reset(brand);
                }}
                disabled={isSubmitting || !isDirty}
              >
                Reset
              </Button>
              <Button
                color="primary"
                type="submit"
                disabled={isSubmitting || !isDirty}
              >
                Save
              </Button>
            </div>
          }
        />
        <LayoutSection.Body>
          <TextInputField.Controller
            control={control}
            className="max-w-sm"
            name="name"
            label="Company Name"
          />
          <div className="jsutify-centers mt-4 flex w-max max-w-xl items-end space-x-4">
            <TextInputField.Controller
              control={control}
              name="shopUrl"
              label="Header Button URL"
            />
            <SelectField.Controller
              control={control}
              className="mr-2 flex-1"
              name="headerButtonIcon"
              label="Button Icon"
              options={Object.entries(headerButtonIconTypeMapping).map(
                ([value, Icon]) => ({
                  value: value as HeaderButtonIconType,
                  label: <Icon />,
                }),
              )}
              renderItemLabel={(option) => {
                const Icon = headerButtonIconTypeMapping[option.value];
                return <Icon />;
              }}
            />

            <TextInputField.Controller
              control={control}
              className="ml-2"
              name="headerButtonText"
              label="Button Label"
            />
            <CheckedInput.LabelledController
              control={control}
              name="headerButtonOpenInNewWindow"
              label="Open in a new window"
              horizontalLabel
            />
          </div>
          <TextInputField.Controller
            control={control}
            className="mr-[200px] mt-4"
            name="about"
            label="About (this is used for site metadata)"
          />
        </LayoutSection.Body>
      </LayoutSection>
    </form>
  );
}

function ThemingPage(): JSX.Element {
  const brandId = useBrandId();

  const { data, error } = useBrandQuery({
    variables: {
      id: brandId,
    },
  });
  const { data: fontOptionsData, error: fontOptionsError } =
    useBrandFontOptionsQuery();

  if (!data || !fontOptionsData) {
    return <ErrorableLoader error={error || fontOptionsError} />;
  }

  return (
    <div>
      <h1>Theming</h1>
      <div className="mt-2 space-y-2 md:space-y-4">
        <NameAndLinksSection brand={data.brand} brandId={brandId} />
        <BrandingAssetsSection
          brand={data.brand}
          brandId={brandId}
          fontOptionsData={fontOptionsData.brandFontOptions}
        />
        <ColorsSection brand={data.brand} brandId={brandId} />
      </div>
    </div>
  );
}

export default ThemingPage;
