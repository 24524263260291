import { clsx } from 'clsx';

interface StatusBadgeProps {
  className?: string;
  status: 'active' | 'inactive';
}

export function StatusBadge({
  className,
  status,
}: StatusBadgeProps): JSX.Element {
  const badgeColor =
    status === 'active'
      ? 'bg-green-300 text-green-800'
      : 'bg-red-300 text-red-800';

  return (
    <div
      className={clsx(
        'inline-block rounded-full px-4 py-1 text-sm font-medium uppercase',
        badgeColor,
        className,
      )}
    >
      {status === 'active' ? 'Active' : 'Inactive'}
    </div>
  );
}
