import { EmptyDisplay, ErrorableLoader } from '@addglowapp/components';
import { Link } from 'react-router-dom';
import { useGetBrandMemberBadgesQuery } from '@src/generated/graphql';
import { useBrandId } from '@src/hooks/useBrandId';
import { MemberBadgeTable } from './MemberBadgeTable';

export function MemberBadgesPage(): JSX.Element {
  const brandId = useBrandId();
  const { data, error } = useGetBrandMemberBadgesQuery({
    variables: {
      brandId,
    },
  });

  if (!data) {
    return <ErrorableLoader error={error} />;
  }

  return (
    <div className="max-w-4xl space-y-4">
      <h1>Member Badges</h1>
      <p>
        Member badges allow you to show a badge next to a member&apos;s name in
        the community. You can create badges for groups from the{' '}
        <Link to="/members/groups">groups page</Link>.
      </p>

      {data.brandMemberBadges.length === 0 ? (
        <EmptyDisplay
          header="No Member Badges"
          subtitle="There are no member badges for this brand yet. You can create one from the groups page."
        />
      ) : (
        <MemberBadgeTable memberBadges={data.brandMemberBadges} />
      )}
    </div>
  );
}
