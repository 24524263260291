import { ErrorableLoader } from '@addglowapp/components';
import { useGetInveterateIntegrationQuery } from 'src/generated/graphql';
import { useBrandId } from 'src/hooks/useBrandId';
import { ConnectInveterateSection } from './ConnectInveterateSection';
import { UpdateInveterateSection } from './UpdateInveterateSection';

export function InveteratePage(): JSX.Element {
  const brandId = useBrandId();
  const { data, error } = useGetInveterateIntegrationQuery({
    variables: {
      id: brandId,
    },
  });

  if (!data) {
    return <ErrorableLoader error={error} />;
  }

  return (
    <div className="space-y-2">
      <h1>Inveterate</h1>
      {!data.brand.inveterateIntegration ? (
        <ConnectInveterateSection brandId={brandId} />
      ) : (
        <UpdateInveterateSection brand={data.brand} />
      )}
    </div>
  );
}
