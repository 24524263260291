import { Button, Dialog, FormLabel } from '@addglowapp/components';
import { Alert, TextInputField } from '@addglowapp/components';
import { toast } from '@addglowapp/components';
import { BiCopy } from 'react-icons/bi';

interface CreatedKeyModalProps {
  createdKey?: string;
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
}

export function CreatedKeyModal({
  createdKey,
  isOpen,
  onOpenChange,
}: CreatedKeyModalProps): JSX.Element {
  function handleCopy(): void {
    if (!createdKey) return;
    navigator.clipboard
      .writeText(createdKey)
      .then(() => {
        toast.success('Key copied to clipboard!');
      })
      .catch(() => {
        toast.error('Could not copy, please select and copy manually.');
      });
  }
  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <Dialog.Content>
        <Dialog.Header>
          <Dialog.Title>API Key Generated</Dialog.Title>
        </Dialog.Header>
        <div>
          <FormLabel>API Key</FormLabel>
          <div className="relative w-full">
            <TextInputField
              readOnly
              value={createdKey}
              onFocus={(e) => {
                e.target.select();
              }}
            />
            <button
              className="absolute bottom-2 right-4 top-2 flex items-center justify-center"
              onClick={handleCopy}
            >
              <BiCopy />
            </button>
          </div>
        </div>
        <Alert type="info">
          Here is your API key. This is the only time this key can be displayed,
          so please copy it and keep it safe.
        </Alert>
        <Dialog.Footer className="flex gap-4">
          <Dialog.Close asChild>
            <Button color="secondary">Close</Button>
          </Dialog.Close>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog>
  );
}
