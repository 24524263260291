import { Button, Dialog } from '@addglowapp/components';
import { toast } from '@addglowapp/components';
import { useEffect, useState } from 'react';
import { HiOutlineTrash } from 'react-icons/hi'; // Import trash icon
import { useUpdateRoomMemberMutation } from '@src/generated/graphql';
import { logAndFormatError } from '@src/services/error-formatter';
import { BrandMemberData } from '../types';

export function RevokeRoomModeratorDialogContent({
  brandMember,
  onSaved,
  refetch,
}: {
  brandMember: BrandMemberData;
  onSaved?: () => void;
  refetch: () => void;
}): JSX.Element {
  const [moderatedRooms, setModeratedRooms] = useState(
    brandMember?.userModeratedRooms || [],
  );

  const [updateRoomMember, { loading }] = useUpdateRoomMemberMutation();

  useEffect(() => {
    if (brandMember.userModeratedRooms) {
      setModeratedRooms(brandMember.userModeratedRooms);
    }
  }, [brandMember.userModeratedRooms]);

  const handleRemoveModeration = async (roomId: string): Promise<void> => {
    try {
      await updateRoomMember({
        variables: {
          input: {
            id: {
              roomOptionId: roomId,
              userId: brandMember.userId,
            },
            data: {
              isModerator: false,
            },
          },
        },
      });

      // Update local state to remove the room after revoking permissions
      const updatedRooms = moderatedRooms.filter((room) => room.id !== roomId);
      setModeratedRooms(updatedRooms);

      toast.success(`Moderator permissions removed for room.`);

      // Auto-close dialog only if it was the last room
      if (updatedRooms.length === 0) {
        refetch();
        onSaved?.();
      }
    } catch (err) {
      toast.error(
        logAndFormatError(err, 'Failed to revoke moderator permissions.'),
      );
    }
  };

  return (
    <div className="space-y-4">
      <Dialog.Header>
        <Dialog.Title>Revoke Room Moderator Permissions</Dialog.Title>
        <Dialog.Description>
          Remove room moderator permissions for {brandMember.username} for the
          following rooms:
        </Dialog.Description>
      </Dialog.Header>

      {moderatedRooms.length === 0 ? (
        <p className="text-muted-foreground">
          This user is not a moderator of any rooms.
        </p>
      ) : (
        <div className="space-y-2">
          {moderatedRooms.map((room) => (
            <div key={room.id} className="flex items-center justify-between">
              <span>{room.name}</span>
              <Button
                icon={<HiOutlineTrash />}
                color="transparent"
                aria-label={`Remove moderator permissions for ${room.name}`}
                onClick={() => handleRemoveModeration(room.id)}
                disabled={loading}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

interface RevokeRoomModeratorDialogProps {
  brandMember: BrandMemberData;
  open: boolean;
  refetch: () => void;
  onOpenChange: (open: boolean) => void;
}

export function RevokeRoomModeratorDialog({
  brandMember,
  open,
  onOpenChange,
  refetch,
}: RevokeRoomModeratorDialogProps): JSX.Element {
  const [isDialogOpen, setIsDialogOpen] = useState(open);

  useEffect(() => {
    setIsDialogOpen(open); // Sync with the parent open state
  }, [open]);

  return (
    <Dialog
      open={isDialogOpen}
      onOpenChange={(newState) => {
        setIsDialogOpen(newState);
        onOpenChange(newState);
      }}
    >
      <Dialog.Content>
        <RevokeRoomModeratorDialogContent
          brandMember={brandMember}
          refetch={refetch}
          onSaved={() => {
            if (brandMember?.userModeratedRooms.length === 0) {
              setIsDialogOpen(false);
            }
          }}
        />
      </Dialog.Content>
    </Dialog>
  );
}
