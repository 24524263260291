import { Loader } from '@addglowapp/components';
import { Button, ErrorDisplay } from '@addglowapp/components';
import { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { z } from 'zod';
import { useHandleYotpoDeveloperAuthCallbackMutation } from '@src/generated/graphql';

const callbackParamsSchema = z.object({
  code: z.string(),
  app_key: z.string(),
  state: z.string(),
});

export function YotpoAuthCallbackPage(): JSX.Element {
  const [params] = useSearchParams();
  const [error, setError] = useState<unknown>(null);
  const [handleYotpoDeveloperAuthCallback] =
    useHandleYotpoDeveloperAuthCallbackMutation();
  const navigate = useNavigate();

  const parsedParams = useMemo(() => {
    try {
      const paramObject = {
        code: params.get('code'),
        app_key: params.get('app_key'),
        state: params.get('state'),
      };

      return callbackParamsSchema.parse(paramObject);
    } catch (error) {
      return null;
    }
  }, [params]);

  useEffect(() => {
    if (!parsedParams) {
      return;
    }

    handleYotpoDeveloperAuthCallback({
      variables: {
        input: {
          code: parsedParams.code,
          appKey: parsedParams.app_key,
          state: parsedParams.state,
        },
      },
    })
      .then((result) => {
        const brandId = result.data?.handleYotpoDeveloperAuthCallback.brandId;
        if (!brandId) {
          throw new Error('Failed to connect Yotpo');
        }
        navigate(`/${brandId}/integrations/yotpo`);
      })
      .catch(() => {
        setError('Received an invalid response for Yotpo authentication');
      });
  }, [parsedParams, handleYotpoDeveloperAuthCallback, navigate]);

  if (!parsedParams) {
    return (
      <ErrorDisplay
        error="We encountered an invalid response from Yotpo. Please try again"
        actions={
          <Link to="../yotpo">
            <Button>Back to Yotpo Integration</Button>
          </Link>
        }
      />
    );
  }

  if (error) {
    return (
      <ErrorDisplay
        error={error}
        actions={
          <Link to="../yotpo">
            <Button>Back to Yotpo Integration</Button>
          </Link>
        }
      />
    );
  }

  return <Loader />;
}
