import { Button } from '@addglowapp/components';
import { TextInputField } from '@addglowapp/components';
import { toast } from '@addglowapp/components';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import LayoutSection from 'src/components/LayoutSection';
import { logAndFormatError } from 'src/services/error-formatter';
import { getApolloErrorCode } from 'src/utils/apollo-error';
import { useConnectYotpoLoyaltyIntegrationMutation } from '@src/generated/graphql';

interface ConnectYotpoSectionProps {
  className?: string;
  brandId: string;
}

const formSchema = z.object({
  apiKey: z.string().trim().min(5),
  guid: z.string().trim().min(5),
});

type FormValues = z.infer<typeof formSchema>;

export function ConnectYotpoSection({
  className,
  brandId,
}: ConnectYotpoSectionProps): JSX.Element {
  const [connectYotpoIntegration] = useConnectYotpoLoyaltyIntegrationMutation();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isDirty },
  } = useForm<FormValues>({
    resolver: zodResolver(formSchema),
  });

  async function upsertYotpoIntegration({
    apiKey,
    guid,
  }: FormValues): Promise<void> {
    try {
      await connectYotpoIntegration({
        variables: {
          input: {
            brandId,
            apiKey,
            guid,
          },
        },
      });
      toast.success('Yotpo successfully connected!');
    } catch (err) {
      const apolloError = getApolloErrorCode(err, [
        'invalid-credentials',
      ] as const);

      if (apolloError === 'invalid-credentials') {
        toast.error(
          'Invalid credentials. Please check your API key and GUID and try again.',
        );
        return;
      }
      toast.error(logAndFormatError(err));
    }
  }

  return (
    <form onSubmit={handleSubmit(upsertYotpoIntegration)}>
      <LayoutSection className={className}>
        <LayoutSection.Header
          title="Settings"
          actions={
            <div className="flex space-x-2">
              <Button
                color="primary"
                type="submit"
                disabled={isSubmitting || !isDirty}
              >
                Connect
              </Button>
            </div>
          }
        />
        <LayoutSection.Body>
          <div className="flex max-w-3xl flex-col space-y-4">
            <div className="flex space-x-4">
              <TextInputField.Controller
                control={control}
                name="apiKey"
                label="API Key"
                className="w-60"
              />
              <TextInputField.Controller
                control={control}
                name="guid"
                label="GUID"
                className="w-60"
              />
            </div>
            <div className="space-y-4 text-sm text-gray-500">
              <p>
                You can find your Yotpo API key & GUID in{' '}
                <a href="https://loyalty-app.yotpo.com/settings">
                  your Yotpo dashboard
                </a>
                .
              </p>
            </div>
          </div>
        </LayoutSection.Body>
      </LayoutSection>
    </form>
  );
}
