import { Button } from '@addglowapp/components';
import { Alert } from '@addglowapp/components';
import { toast } from '@addglowapp/components';
import clsx from 'clsx';
import LayoutSection from 'src/components/LayoutSection';
import {
  BrandWithYotpoIntegrationFragment,
  useUninstallYotpoDeveloperIntegrationMutation,
} from 'src/generated/graphql';
import { logAndFormatError } from 'src/services/error-formatter';
import { useRedirectToYotpoInstallationLink } from './hooks';

interface UpdateYotpoSectionProps {
  className?: string;
  brand: BrandWithYotpoIntegrationFragment;
}

export function UpdateYotpoConnectionSection({
  className,
  brand,
}: UpdateYotpoSectionProps): JSX.Element {
  const { redirect, isRedirecting } = useRedirectToYotpoInstallationLink();
  const [uninstallYotpoDeveloperIntegration, { loading: isDeleting }] =
    useUninstallYotpoDeveloperIntegrationMutation();

  function handleUninstall(): void {
    if (
      window.confirm(
        'Are you sure you want to uninstall the Yotpo integration?',
      )
    ) {
      uninstallYotpoDeveloperIntegration({
        variables: { brandId: brand.id },
      })
        .then(() => {
          toast.success('Yotpo integration successfully disconnected!');
        })
        .catch((err) => {
          toast.error(logAndFormatError(err));
        });
    }
  }

  return (
    <div className={clsx('max-w-4xl space-y-4', className)}>
      <LayoutSection>
        <LayoutSection.Header title="Yotpo Connection" />
        <LayoutSection.Body className="space-y-4">
          {brand.yotpoDeveloperIntegration?.connectionError ? (
            <>
              <Alert type="error">
                <p>
                  We have connection issues with your Yotpo integration. Please
                  try connecting your Yotpo integration again or contact AddGlow
                  support.
                </p>
              </Alert>
              <div className="flex space-x-4">
                <Button
                  color="primary"
                  onClick={redirect}
                  disabled={isRedirecting}
                >
                  Reconnect Yotpo
                </Button>
                <Button
                  color="secondary"
                  disabled={isDeleting}
                  onClick={handleUninstall}
                >
                  Uninstall Integration
                </Button>
              </div>
            </>
          ) : (
            <>
              <p>
                You have connected your Yotpo integration. You can now use Yotpo
                for your loyalty point tracking.
              </p>
              <p>
                If you need to change or disconnect the integration, you can
                uninstall the integration below:
              </p>
              <Button
                color="secondary"
                disabled={isDeleting}
                onClick={handleUninstall}
              >
                Uninstall Integration
              </Button>
            </>
          )}
        </LayoutSection.Body>
      </LayoutSection>
    </div>
  );
}
