import { Button } from '@addglowapp/components';
import { Modal } from '@addglowapp/components';
import { toast } from '@addglowapp/components';
import { useState } from 'react';
import {
  RoomOptionFragment,
  useDeleteRoomOptionMutation,
} from 'src/generated/graphql';

interface Props {
  room: RoomOptionFragment;
  isOpen: boolean;
  setModalOpen(open: boolean): void;
  onActionComplete(): void;
  setLoading(isLoading: boolean): void;
}
export default function DeleteRoomModal({
  room,
  isOpen,
  setModalOpen,
  onActionComplete,
  setLoading,
}: Props): JSX.Element {
  const [deleteRoom] = useDeleteRoomOptionMutation();
  const [typedRoomName, setTypedRoomName] = useState('');

  const handleDelete = async (): Promise<void> => {
    if (typedRoomName !== room?.name) {
      return;
    }
    setLoading(true);
    try {
      await deleteRoom({
        variables: {
          input: {
            id: room.id,
          },
        },
      });
      toast.success('Room deleted successfully!');
      onActionComplete();
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
    setModalOpen(false);
  };

  return (
    <Modal isOpen={isOpen} onClose={() => setModalOpen(false)}>
      <Modal.Header
        className="justify-center"
        onClose={() => setModalOpen(false)}
      >
        Confirm Delete
      </Modal.Header>
      <Modal.Body>
        <p>
          Deleting this room will also delete all its posts. To confirm, please
          type the room name (<strong>{room.name}</strong>):
        </p>
        <input
          className="bg-red mt-4 w-[300px] border p-2"
          value={typedRoomName}
          onChange={(e) => setTypedRoomName(e.target.value)}
          placeholder="Type room name"
        />
      </Modal.Body>
      <Modal.Footer>
        <Button color="secondary" onClick={() => setModalOpen(false)}>
          Cancel
        </Button>
        <Button onClick={handleDelete}>Confirm Delete</Button>
      </Modal.Footer>
    </Modal>
  );
}
