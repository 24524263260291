import { Button } from '@addglowapp/components';
import { TextInputField } from '@addglowapp/components';
import { toast } from '@addglowapp/components';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import LayoutSection from 'src/components/LayoutSection';
import { useConnectInveterateIntegrationMutation } from 'src/generated/graphql';
import { logAndFormatError } from 'src/services/error-formatter';
import { getApolloErrorCode } from 'src/utils/apollo-error';

interface ConnectInveterateSectionProps {
  className?: string;
  brandId: string;
}

const formSchema = z.object({
  apiKey: z.string().trim().min(5),
});

type FormValues = z.infer<typeof formSchema>;

export function ConnectInveterateSection({
  className,
  brandId,
}: ConnectInveterateSectionProps): JSX.Element {
  const [connectInveterateIntegration] =
    useConnectInveterateIntegrationMutation();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isDirty },
  } = useForm<FormValues>({
    resolver: zodResolver(formSchema),
  });

  async function upsertInveterateIntegration({
    apiKey,
  }: FormValues): Promise<void> {
    try {
      await connectInveterateIntegration({
        variables: {
          input: {
            brandId,
            apiKey,
          },
        },
      });
      toast.success('Inveterate successfully connected!');
    } catch (err) {
      const apolloError = getApolloErrorCode(err, [
        'invalid-credentials',
      ] as const);

      if (apolloError === 'invalid-credentials') {
        toast.error(
          'Invalid credentials. Please check your API key and try again.',
        );
        return;
      }
      toast.error(logAndFormatError(err));
    }
  }

  return (
    <form onSubmit={handleSubmit(upsertInveterateIntegration)}>
      <LayoutSection className={className}>
        <LayoutSection.Header
          title="Settings"
          actions={
            <div className="flex space-x-2">
              <Button
                color="primary"
                type="submit"
                disabled={isSubmitting || !isDirty}
              >
                Connect
              </Button>
            </div>
          }
        />
        <LayoutSection.Body>
          <div className="flex max-w-3xl flex-col space-y-4">
            <p className="text-sm text-gray-700">
              If you wish to restrict access to your community to only members
              who are part of your Inveterate program, you can connect your
              Inveterate account here.
            </p>
            <TextInputField.Controller
              control={control}
              name="apiKey"
              label="API Key"
              className="w-[300px]"
            />
            <div className="space-y-4 text-sm text-gray-500">
              <p>
                You can find your Inveterate API key in{' '}
                <a href="https://dashboard.inveterate.com/integrations/api-key">
                  your Inveterate dashboard
                </a>
                .
              </p>
            </div>
          </div>
        </LayoutSection.Body>
      </LayoutSection>
    </form>
  );
}
