import { Button } from '@addglowapp/components';
import { TextInputField } from '@addglowapp/components';
import { toast } from '@addglowapp/components';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import LayoutSection from 'src/components/LayoutSection';
import {
  BrandKlaviyoIntegrationFragment,
  GetKlaviyoIntegrationDocument,
  useCreateKlaviyoIntegrationMutation,
  useUpdateKlaviyoIntegrationMutation,
} from 'src/generated/graphql';
import { logAndFormatError } from 'src/services/error-formatter';
import { getApolloErrorCode } from 'src/utils/apollo-error';

interface ConnectKlaviyoSectionProps {
  className?: string;
  klaviyoIntegration?: BrandKlaviyoIntegrationFragment | null;
  brandId: string;
}

const formSchema = z.object({
  klaviyoApiKey: z.string().trim().min(5),
});

type FormValues = z.infer<typeof formSchema>;

export function ConnectKlaviyoSection({
  className,
  klaviyoIntegration,
  brandId,
}: ConnectKlaviyoSectionProps): JSX.Element {
  const [createKlaviyoIntegration] = useCreateKlaviyoIntegrationMutation({
    refetchQueries: [GetKlaviyoIntegrationDocument],
  });
  const [updateKlaviyoIntegration] = useUpdateKlaviyoIntegrationMutation();

  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting, isDirty },
  } = useForm<FormValues>({
    resolver: zodResolver(formSchema),
  });

  async function upsertKlaviyoIntegration({
    klaviyoApiKey,
  }: FormValues): Promise<void> {
    try {
      if (klaviyoIntegration) {
        await updateKlaviyoIntegration({
          variables: {
            input: {
              id: klaviyoIntegration.brandId,
              data: { klaviyoApiKey },
            },
          },
        });
      } else {
        await createKlaviyoIntegration({
          variables: {
            input: {
              data: {
                brandId,
                klaviyoApiKey,
              },
            },
          },
        });
      }
      toast.success('Klaviyo successfully connected!');
    } catch (err) {
      const apolloError = getApolloErrorCode(err, [
        'invalid-key',
        'invalid-scopes',
      ]);
      if (apolloError === 'invalid-key') {
        setError('klaviyoApiKey', {
          message:
            'The API key appears to be invalid or has insufficient scopes. Please check the key and try again.',
        });
        return;
      }
      if (apolloError === 'invalid-scopes') {
        setError('klaviyoApiKey', {
          message:
            'The API key appears to have insufficient scopes. Please check the key and try again.',
        });
        return;
      }
      toast.error(logAndFormatError(err));
    }
  }

  return (
    <form onSubmit={handleSubmit(upsertKlaviyoIntegration)}>
      <LayoutSection className={className}>
        <LayoutSection.Header
          title="Settings"
          actions={
            <div className="flex space-x-2">
              <Button
                color="primary"
                type="submit"
                disabled={isSubmitting || !isDirty}
              >
                Connect
              </Button>
            </div>
          }
        />
        <LayoutSection.Body>
          <div className="flex max-w-3xl flex-col space-y-4">
            <TextInputField.Controller
              control={control}
              className="flex-1"
              name="klaviyoApiKey"
              label="Klaviyo API Key"
            />
            <div className="space-y-4 text-sm text-gray-500">
              <p>
                You can create a Klaviyo API key from the Klaviyo dashboard. (
                <a href="https://help.klaviyo.com/hc/en-us/articles/7423954176283">
                  instructions
                </a>
                )
              </p>
              <p>We need the following scopes:</p>
              <ul className="list-inside list-disc space-y-2">
                <li>
                  <strong>Profiles (Full Access)</strong>: Allows us to sync
                  brand profiles to your Klaviyo database
                </li>
                <li>
                  <strong>Events (Full Access)</strong>: Allows us to write
                  events to the profiles
                </li>
                <li>
                  <strong>List (Full Access)</strong>: Allows us to add profiles
                  to a list
                </li>
                <li>
                  <strong>Subscriptions (Full Access)</strong>: Allows us to
                  automatically subscribe AddGlow members to a list
                </li>
              </ul>
            </div>
          </div>
        </LayoutSection.Body>
      </LayoutSection>
    </form>
  );
}
