import { Button, ProfileInfo } from '@addglowapp/components';
import { ToggleInput } from '@addglowapp/components';
import { toast } from '@addglowapp/components';
import { useState } from 'react';
import {
  BrandFragment,
  CustomTraitEmbeddedSelectOptionsData,
  CustomTraitGroupEmbeddedTraitsData,
  CustomTraitGroupFragment,
  useCreateCustomTraitGroupMutation,
  useDeleteCustomTraitGroupMutation,
  useUpdateCustomTraitGroupMutation,
} from 'src/generated/graphql';
import { logAndFormatError } from 'src/services/error-formatter';
import CreateGroupModal, {
  CreateCustomTraitGroupFormSchema,
} from './CreateGroupModal';
import CreateTraitModal, {
  CreateCustomTraitFormSchema,
} from './CreateTraitModal';

export function CustomTraitSection({
  refetch,
  brand,
}: {
  refetch: () => void;
  brand: BrandFragment;
}): JSX.Element {
  const [createGroup] = useCreateCustomTraitGroupMutation();
  const [updateGroup] = useUpdateCustomTraitGroupMutation();
  const [deleteGroup] = useDeleteCustomTraitGroupMutation();

  const [createGroupModalOpen, setCreateGroupModalOpen] = useState(false);
  const [selectedTraitGroup, setSelectedTraitGroup] =
    useState<CustomTraitGroupFragment | null>(null);

  const [editTraitGroup, setEditTraitGroup] =
    useState<CustomTraitGroupFragment | null>(null);

  const [editTraitId, setEditTraitId] = useState<string | null>(null);

  const onSubmitGroup = async (
    data: CreateCustomTraitGroupFormSchema,
    groupId?: string,
  ): Promise<void> => {
    if (groupId) {
      updateGroup({
        variables: {
          input: {
            id: groupId,
            data: {
              title: data.title,
            },
          },
        },
      })
        .then(() => {
          toast.success('Group updated!');
        })
        .catch((err) => {
          toast.error(
            logAndFormatError(err, 'Sorry, the group could not be updated.'),
          );
        })
        .finally(() => {
          setCreateGroupModalOpen(false);
        });
    } else {
      createGroup({
        variables: {
          input: {
            data: {
              brandId: brand.id,
              title: data.title,
            },
          },
        },
      })
        .then(() => {
          toast.success('Group created!');
        })
        .catch((err) => {
          toast.error(
            logAndFormatError(err, 'Sorry, the group could not be created.'),
          );
        })
        .finally(() => {
          setCreateGroupModalOpen(false);
          refetch();
        });
    }
  };

  const onSubmitTrait = async (
    data: CreateCustomTraitFormSchema,
    groupId: string,
    traitId?: string,
  ): Promise<void> => {
    const { options, type, title } = data;
    const group = brand.customTraitGroups.find((g) => g.id === groupId) ?? {
      traits: [],
    };

    const traits: CustomTraitGroupEmbeddedTraitsData[] = group.traits.map(
      ({ id, title, type, selectOptions }) => ({
        id,
        title,
        type,
        selectOptions: selectOptions.map(({ id, text, order }) => ({
          id,
          text,
          order,
        })),
      }),
    );

    let selectOptions: CustomTraitEmbeddedSelectOptionsData[] = [];
    if (type === 'SELECT' && options) {
      selectOptions = options.split(',').map(
        (optionText, index): CustomTraitEmbeddedSelectOptionsData => ({
          text: optionText.trim(),
          order: index + 1,
        }),
      );
    }

    if (traitId) {
      // Update existing trait
      const index = traits.findIndex((trait) => trait.id === traitId);
      if (index !== -1) {
        traits[index] = {
          ...traits[index],
          title,
          ...(type === 'SELECT' ? { selectOptions } : {}),
        };
      } else {
        return;
      }
    } else {
      // Add new trait
      traits.push({
        title,
        selectOptions,
        type,
      });
    }

    updateGroup({
      variables: {
        input: {
          id: groupId,
          data: { traits },
        },
      },
    })
      .then(() => {
        toast.success('Profile traits updated successfully!');
      })
      .catch((err) => {
        toast.error(
          logAndFormatError(err, 'Sorry, the action could not be completed.'),
        );
      })
      .finally(() => {
        setSelectedTraitGroup(null);
        setEditTraitId(null);
        refetch();
      });
  };

  const onDeleteGroup = (groupId: string): void => {
    if (!window.confirm(`Are you sure you want to delete this trait group?`)) {
      return;
    }
    deleteGroup({
      variables: {
        input: {
          id: groupId,
        },
      },
    })
      .then(() => {
        toast.success('Group deleted!');
      })
      .catch((err) => {
        toast.error(
          logAndFormatError(err, 'Sorry, the action could not be completed.'),
        );
      })
      .finally(() => {
        refetch();
      });
  };

  const onDeleteTrait = async (
    groupId: string,
    traitId: string,
  ): Promise<void> => {
    if (!window.confirm(`Are you sure you want to delete this trait?`)) {
      return;
    }
    const group = brand.customTraitGroups.find((g) => g.id === groupId);
    if (!group) {
      toast.error('Group not found.');
      return;
    }

    const traits = group.traits.map(({ id, title, type, selectOptions }) => ({
      id,
      title,
      type,
      selectOptions: selectOptions.map(({ id, text }) => ({ id, text })),
    }));

    const updatedTraits = traits.filter((trait) => trait.id !== traitId);

    try {
      await updateGroup({
        variables: {
          input: {
            id: groupId,
            data: {
              traits: updatedTraits,
            },
          },
        },
      });
      toast.success('Trait deleted successfully!');
      refetch();
    } catch (err) {
      toast.error(
        logAndFormatError(err, 'Sorry, the trait could not be deleted.'),
      );
    }
  };

  const handleToggleGroupEnabled = async (
    groupId: string,
    isEnabled: boolean,
  ): Promise<void> => {
    const group = brand.customTraitGroups.find((g) => g.id === groupId);
    if (!group) {
      toast.error('Trait group not found.');
      return;
    }

    if (group.traits.length === 0) {
      toast.error('Please add traits in order to enable this group');
      return;
    }

    try {
      await updateGroup({
        variables: {
          input: {
            id: groupId,
            data: {
              isEnabled: isEnabled,
            },
          },
        },
      });
      toast.success(
        `Group ${isEnabled ? 'enabled' : 'disabled'} successfully.`,
      );
      refetch();
    } catch (error) {
      toast.error(logAndFormatError(error, 'Failed to update group.'));
    }
  };

  return (
    <div className="space-y-4">
      {brand.customTraitGroups.map((group) => (
        <div className="space-y-4" key={group.id}>
          <div className="flex justify-between">
            <h2>{group.title}</h2>
            <div className="flex flex-row items-center justify-center space-x-2">
              <Button
                color="secondary"
                onClick={() => {
                  setEditTraitGroup(group);
                  setCreateGroupModalOpen(true);
                }}
              >
                Edit
              </Button>
              <Button
                color="secondary"
                onClick={() => {
                  onDeleteGroup(group.id);
                }}
              >
                Delete
              </Button>
              <ToggleInput.Labelled
                checked={group.isEnabled}
                label={group.isEnabled ? 'Disable' : 'Enable'}
                onChange={(e) => handleToggleGroupEnabled(group.id, e)}
              />
            </div>
          </div>
          <p>Allow users to add the following to their profiles:</p>
          <ProfileInfo title={group.title}>
            {group.traits.map((trait) => (
              <div
                className="items-center justify-center rounded-md border p-2"
                key={trait.id}
              >
                <p className="text-center opacity-50">{trait.title}</p>
                <Button
                  color="transparent"
                  onClick={() => {
                    setEditTraitId(trait.id);
                    setSelectedTraitGroup(group);
                  }}
                >
                  Edit
                </Button>
                <Button
                  color="transparent"
                  onClick={() => onDeleteTrait(group.id, trait.id)}
                >
                  Delete
                </Button>
              </div>
            ))}
            <Button
              color="transparent"
              className="my-auto h-min underline"
              onClick={() => setSelectedTraitGroup(group)}
            >
              Add Trait
            </Button>{' '}
          </ProfileInfo>
        </div>
      ))}
      <Button
        onClick={() => {
          setEditTraitGroup(null);
          setCreateGroupModalOpen(true);
        }}
      >
        Add Custom Trait Group
      </Button>
      <CreateGroupModal
        isModalOpen={createGroupModalOpen}
        onClose={() => {
          setCreateGroupModalOpen(false);
        }}
        onSubmit={onSubmitGroup}
        group={editTraitGroup}
      />
      {selectedTraitGroup && (
        <CreateTraitModal
          isModalOpen={!!selectedTraitGroup}
          onClose={() => {
            setSelectedTraitGroup(null);
            setEditTraitId(null);
          }}
          group={selectedTraitGroup}
          onSubmit={onSubmitTrait}
          traitId={editTraitId}
        />
      )}
    </div>
  );
}
