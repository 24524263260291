import { Button, Dialog, FormError, FormLabel } from '@addglowapp/components';
import { CheckedInput, TextInputField } from '@addglowapp/components';
import { toast } from '@addglowapp/components';
import { BRAND_API_SCOPE_KEYS } from '@addglowapp/shared';
import { zodResolver } from '@hookform/resolvers/zod';
import { useController, useForm } from 'react-hook-form';
import { z } from 'zod';
import {
  BrandApiKeyFragment,
  GetBrandApiKeysDocument,
  useCreateBrandApiKeyMutation,
  useUpdateBrandApiKeyMutation,
} from '@src/generated/graphql';
import { useBrandId } from '@src/hooks/useBrandId';
import { logAndFormatError } from '@src/services/error-formatter';

interface ApiKeyEditModalProps {
  existingApiKey?: BrandApiKeyFragment | null;
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  onKeyCreated: (key: string) => void;
}

const formSchema = z.object({
  name: z.string().trim().min(1).max(30),
  scopes: z
    .array(
      z.object({
        id: z.string().optional(),
        scope: z.string(),
      }),
    )
    .min(1),
});

type FormValues = z.infer<typeof formSchema>;

export function ApiKeyEditModal({
  existingApiKey,
  isOpen,
  onOpenChange,
  onKeyCreated,
}: ApiKeyEditModalProps): JSX.Element {
  const { control, handleSubmit, reset } = useForm<FormValues>({
    values: {
      name: existingApiKey?.name ?? '',
      scopes: existingApiKey?.scopes ?? [],
    },
    resolver: zodResolver(formSchema),
  });
  const brandId = useBrandId();
  const [createBrandApiKey] = useCreateBrandApiKeyMutation({
    refetchQueries: [
      {
        query: GetBrandApiKeysDocument,
        variables: { brandId },
      },
    ],
  });
  const [updateBrandApiKey] = useUpdateBrandApiKeyMutation();

  const onSubmit = async (data: FormValues): Promise<void> => {
    try {
      if (existingApiKey) {
        await updateBrandApiKey({
          variables: {
            input: {
              id: existingApiKey.id,
              data,
            },
          },
        });
      } else {
        const { data: createdKey } = await createBrandApiKey({
          variables: {
            input: {
              data: {
                brandId,
                ...data,
              },
            },
          },
        });
        if (!createdKey?.createBrandApiKey.key) {
          throw new Error('Key was not created');
        }
        onKeyCreated(createdKey?.createBrandApiKey.key);
      }
      onOpenChange(false);
      toast.success(
        existingApiKey
          ? 'API Key updated successfully'
          : 'API Key created successfully',
      );
      reset();
    } catch (error) {
      toast.error(logAndFormatError(error));
    }
  };

  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    control,
    name: 'scopes',
  });

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <Dialog.Content>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          <Dialog.Header>
            <Dialog.Title>
              {existingApiKey ? 'Edit API Key' : 'Create API Key'}
            </Dialog.Title>
          </Dialog.Header>
          <div className="space-y-4">
            <TextInputField.Controller
              control={control}
              name="name"
              label="Key Name"
              autoComplete="off"
            />
            <div>
              <FormLabel>Permissions</FormLabel>
              <div className="border-b border-brand-border last:border-none">
                {BRAND_API_SCOPE_KEYS.map((scopeKey) => (
                  <CheckedInput.Labelled
                    horizontalLabel
                    key={scopeKey}
                    label={scopeKey}
                    checked={value.some((v) => v.scope === scopeKey)}
                    onChange={(checked) => {
                      if (checked) {
                        onChange([
                          ...value,
                          { id: undefined, scope: scopeKey },
                        ]);
                      } else {
                        onChange(value.filter((v) => v.scope !== scopeKey));
                      }
                    }}
                  />
                ))}
              </div>
            </div>
            {error && <FormError>{error.message}</FormError>}
          </div>
          <Dialog.Footer className="flex gap-4">
            <Dialog.Close asChild>
              <Button color="secondary">Cancel</Button>
            </Dialog.Close>
            <Button type="submit">
              {existingApiKey ? 'Save Changes' : 'Create API Key'}
            </Button>
          </Dialog.Footer>
        </form>
      </Dialog.Content>
    </Dialog>
  );
}
