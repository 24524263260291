import { Alert, ErrorableLoader } from '@addglowapp/components';
import { useGetBrandAnalyticsEmbedLinkQuery } from 'src/generated/graphql';
import { useBrandId } from 'src/hooks/useBrandId';
import { PlausibleAnalyticsEmbed } from './components/PlausibleAnalyticsEmbed';

function AnalyticsVisitorsPage(): JSX.Element {
  const brandId = useBrandId();
  const { data, error } = useGetBrandAnalyticsEmbedLinkQuery({
    variables: { brandId },
  });

  if (!data) {
    return <ErrorableLoader error={error} />;
  }

  if (data.brand.isTrial) {
    return (
      <div>
        <Alert type="info">
          <p>Analytics is not available during the trial period.</p>
        </Alert>
      </div>
    );
  }

  if (!data.brand.plausibleEmbedLink) {
    return (
      <div>
        <Alert type="info">
          <p>We don&apos;t have any data yet but we&apos;re working on it!</p>
        </Alert>
      </div>
    );
  }

  return (
    <div>
      <PlausibleAnalyticsEmbed shareUrl={data?.brand.plausibleEmbedLink} />
    </div>
  );
}

export default AnalyticsVisitorsPage;
