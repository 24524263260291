import {
  Button,
  Dialog,
  ErrorableLoader,
  MultiComboboxField,
} from '@addglowapp/components';
import { toast } from '@addglowapp/components';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  useGetMemberGroupsQuery,
  useUpdateBrandMemberMutation,
} from '@src/generated/graphql';
import { useBrandId } from '@src/hooks/useBrandId';
import { logAndFormatError } from '@src/services/error-formatter';
import { BrandMemberData } from '../types';

export function BrandMemberGroupContent({
  brandMember,
  onSaved,
}: {
  brandMember?: BrandMemberData;
  onSaved?: () => void;
}): JSX.Element {
  const [groupIds, setGroupIds] = useState<string[]>([]);
  const brandId = useBrandId();
  const { data, error } = useGetMemberGroupsQuery({
    variables: { brandId },
  });
  const [updateBrandMember, { loading }] = useUpdateBrandMemberMutation();

  useEffect(() => {
    if (!brandMember) return;
    setGroupIds(brandMember.groups.map((group) => group.id));
  }, [brandMember]);

  const options = data?.memberGroups.map((group) => ({
    label: group.name,
    value: group.id,
  }));

  const handleSave = (): void => {
    if (!brandMember) return;
    updateBrandMember({
      variables: {
        brandId,
        input: {
          id: {
            userId: brandMember.userId,
            brandId,
          },
          data: {
            memberGroupIds: groupIds,
          },
        },
      },
    })
      .then(() => {
        toast.success(`Successfully updated groups!`);
        onSaved?.();
      })
      .catch((err) => {
        toast.error(
          logAndFormatError(err, 'Sorry, we could not update groups.'),
        );
      });
  };

  return (
    <div className="space-y-4">
      <Dialog.Header>
        <Dialog.Title>Update Groups for {brandMember?.name}</Dialog.Title>
        <Dialog.Description>
          Select the groups the member should be part of.
        </Dialog.Description>
      </Dialog.Header>
      {!options ? (
        <ErrorableLoader error={error} />
      ) : (
        <MultiComboboxField
          options={options}
          disabled={!options.length}
          value={groupIds}
          onChange={setGroupIds}
          placeholder="Select a group"
        />
      )}
      {options && !options.length && (
        <p className="text-muted-foreground">
          This community has no member groups. You can them in the{' '}
          <Link to="/members/groups">Member Groups</Link> section.
        </p>
      )}
      <Dialog.Footer>
        <Dialog.Close asChild>
          <Button color="secondary">Close</Button>
        </Dialog.Close>
        <Button onClick={handleSave} disabled={loading}>
          Save
        </Button>
      </Dialog.Footer>
    </div>
  );
}

interface BrandMemberGroupDialogProps {
  brandMember?: BrandMemberData;
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export function BrandMemberGroupDialog({
  brandMember,
  open,
  onOpenChange,
}: BrandMemberGroupDialogProps): JSX.Element {
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <Dialog.Content>
        <BrandMemberGroupContent
          brandMember={brandMember}
          onSaved={() => onOpenChange(false)}
        />
      </Dialog.Content>
    </Dialog>
  );
}
