import { stringify } from 'csv-stringify/browser/esm/sync';

interface UseDownloadCsvOptions<DataType> {
  getData(): Promise<DataType[]> | DataType[];
  columns: (keyof DataType)[];
  filename: string;
}

export function useDownloadCsv<DataType>({
  getData,
  columns,
  filename,
}: UseDownloadCsvOptions<DataType>): {
  downloadCsv: () => Promise<void>;
} {
  return {
    downloadCsv: async () => {
      const data = await getData();
      const csv = stringify(data, {
        columns: columns as string[],
        header: true,
      });
      const downloadLink = document.createElement('a');
      downloadLink.href = `data:text/csv;charset=utf-8,${encodeURIComponent(
        csv,
      )}`;
      downloadLink.download = filename;
      downloadLink.click();
    },
  };
}
