import { ErrorableLoader } from '@addglowapp/components';
import { useGetYotpoLoyaltyIntegrationQuery } from 'src/generated/graphql';
import { useBrandId } from 'src/hooks/useBrandId';
import { ConnectYotpoSection } from './ConnectYotpoSection';
import { UpdateYotpoSection } from './UpdateYotpoSection';

export function YotpoLoyaltyPage(): JSX.Element {
  const brandId = useBrandId();
  const { data, error } = useGetYotpoLoyaltyIntegrationQuery({
    variables: {
      id: brandId,
    },
  });

  if (!data) {
    return <ErrorableLoader error={error} />;
  }

  return (
    <div className="space-y-2">
      <h1>Yotpo Loyalty</h1>
      {!data.brand.yotpoIntegration ? (
        <ConnectYotpoSection brandId={brandId} />
      ) : (
        <UpdateYotpoSection brand={data.brand} />
      )}
    </div>
  );
}
