export default function GuideMenu(): JSX.Element {
  return (
    <div
      className="sticky top-8 mb-6 mt-8 flex-shrink-0"
      style={{ maxHeight: 'calc(100vh - 8rem)' }}
    >
      <ul className="space-y-2">
        <li>Menu</li>
        <li>
          <a href="#customization">Customization</a>
        </li>
        <li>
          <a href="#moderation">Moderation</a>
        </li>
        <li>
          <a href="#suggested-content">Suggested Content</a>
        </li>
        <li>
          <a href="#pinned-post">Pinned Post</a>
        </li>
        <li>
          <a href="#admin-roles">Admin Roles</a>
        </li>
        <li>
          <a href="#member-roles">Member Roles</a>
        </li>
        <li>
          <a href="#promo">Promoting Your Community </a>
        </li>
      </ul>
    </div>
  );
}
