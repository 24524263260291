import { FormError, FormLabel } from '@addglowapp/components';
import clsx from 'clsx';
import Color from 'color';
import {
  FocusEventHandler,
  HTMLInputTypeAttribute,
  InputHTMLAttributes,
  KeyboardEventHandler,
  MouseEventHandler,
  useCallback,
  useState,
} from 'react';
import { ChromePicker } from 'react-color';
import {
  Control,
  FieldPath,
  FieldPathValue,
  FieldValues,
  RegisterOptions,
  useController,
} from 'react-hook-form';
import { usePopper } from 'react-popper';
import useOutsideClick from 'src/hooks/useClickOutside';

function tryFormatColor(color?: string): string | null {
  try {
    if (!color) {
      return null;
    }
    return new Color(color).hex();
  } catch {
    return null;
  }
}

interface Props {
  className?: string;
  disabled?: boolean;
  placeholder?: string;
  name?: string;
  type?: HTMLInputTypeAttribute;
  onChange?: (value: string) => void;
  onClick?: MouseEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  value?: string;
}

const ColorInput = function ColorInput({
  className,
  disabled,
  placeholder,
  name,
  type = 'text',
  onChange,
  onBlur,
  onClick,
  onFocus,
  onKeyDown,
  value,
}: Props): JSX.Element {
  const [referenceElement, setReferenceElement] =
    useState<HTMLElement | null>();
  const [popperElement, setPopperElement] = useState<HTMLElement | null>();
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 12],
        },
      },
    ],
  });

  const [showPicker, setShowPicker] = useState(false);

  const inputProps: InputHTMLAttributes<HTMLInputElement> = {
    name,
    placeholder,
    disabled,
    type,
    onChange: onChange && ((e) => onChange(e.target.value)),
    onBlur: (e) => {
      if (onBlur) {
        onBlur(e);
      }
      const formattedValue = tryFormatColor(value);
      if (onChange && formattedValue && value !== formattedValue) {
        onChange(formattedValue);
      }
    },
    onClick,
    onFocus: (e) => {
      if (onFocus) {
        onFocus(e);
      }
      setShowPicker(true);
    },
    onKeyDown,
    value,
  };

  useOutsideClick(
    popperElement,
    useCallback(() => {
      setShowPicker(false);
    }, []),
  );

  return (
    <div className="relative" ref={setReferenceElement}>
      <input
        className={clsx(
          'block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 pl-8 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500',
          className,
        )}
        {...inputProps}
      />
      <div
        className="absolute left-2 top-1/2 h-4 w-4 -translate-y-1/2 rounded border bg-brand-border"
        style={{
          backgroundColor: value,
        }}
      />
      {showPicker && (
        <div
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
          className="z-10"
        >
          <ChromePicker
            color={value || '#000000'}
            onChange={(color) => {
              if (onChange && color) {
                onChange(color.hex.toUpperCase());
              }
            }}
          />
        </div>
      )}
    </div>
  );
};

interface ColorInputLabelledProps extends Props {
  label?: string;
  error?: React.ReactNode;
}

ColorInput.Labelled = function ColorInputLabelled({
  label,
  className,
  error,
  ...rest
}: ColorInputLabelledProps): JSX.Element {
  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label className={clsx('flex flex-col justify-between', className)}>
      {label && <FormLabel>{label}</FormLabel>}
      <div>
        <ColorInput {...rest} />
        {error && <FormError>{error}</FormError>}
      </div>
    </label>
  );
};

interface ColorInputControllerProps<
  TFieldValues extends FieldValues = FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends ColorInputLabelledProps {
  control: Control<TFieldValues>;
  name: TFieldName;
  registerOptions?: RegisterOptions<TFieldValues, TFieldName>;
}

ColorInput.LabelledController = function ColorInputController<
  TFieldValues extends FieldValues = FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  registerOptions,
  ...rest
}: ColorInputControllerProps<TFieldValues, TFieldName>): JSX.Element {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  return (
    <ColorInput.Labelled
      onChange={(val) => {
        field.onChange(val as FieldPathValue<TFieldValues, TFieldName>);
      }}
      value={field.value}
      error={error?.message}
      {...rest}
    />
  );
};

export default ColorInput;
