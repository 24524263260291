import { Route, Routes } from 'react-router-dom';
import NotFoundPage from 'src/pages/NotFound.page';
import { EmailsPage } from './emails/emails.page';
import FaqPage from './faq/faq.page';
import { ProfilesPage } from './profiles/profiles.page';
import RoomsPage from './rooms/rooms.page';
import TagsPage from './tags/tags.page';
import ThemingPage from './theming/theming.page';
import WelcomeMessagesPage from './welcome/welcome.page';

export function CustomizationRoutes(): JSX.Element {
  return (
    <Routes>
      <Route path="/" element={<ThemingPage />} />
      <Route path="profiles" element={<ProfilesPage />} />
      <Route path="rooms" element={<RoomsPage />} />
      <Route path="tags" element={<TagsPage />} />
      <Route path="faq" element={<FaqPage />} />
      <Route path="welcome" element={<WelcomeMessagesPage />} />
      <Route path="emails" element={<EmailsPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}
