import { Button, TextInputField, toast } from '@addglowapp/components';
import { zodResolver } from '@hookform/resolvers/zod';
import clsx from 'clsx';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import LayoutSection from 'src/components/LayoutSection';
import {
  BrandWithYotpoLoyaltyIntegrationFragment,
  useDisconnectYotpoLoyaltyIntegrationMutation,
  useUpdateYotpoLoyaltyRedemptionUrlMutation,
} from 'src/generated/graphql';
import { logAndFormatError } from 'src/services/error-formatter';

interface UpdateYotpoSectionProps {
  className?: string;
  brand: BrandWithYotpoLoyaltyIntegrationFragment;
}

const formSchema = z.object({
  redemptionUrl: z.union([z.literal(''), z.string().url()]),
});

type FormValues = z.infer<typeof formSchema>;

export function UpdateYotpoSection({
  className,
  brand,
}: UpdateYotpoSectionProps): JSX.Element {
  const [deleteYotpoIntegration, { loading: isDeleting }] =
    useDisconnectYotpoLoyaltyIntegrationMutation();

  const [updateYotpoLoyaltyRedemptionUrl] =
    useUpdateYotpoLoyaltyRedemptionUrlMutation();
  const {
    control,
    handleSubmit,
    formState: { isDirty, isSubmitting },
  } = useForm<FormValues>({
    values: {
      redemptionUrl: brand.yotpoIntegration?.redemptionUrl ?? '',
    },
    resolver: zodResolver(formSchema),
  });

  async function handleUpdate(values: FormValues): Promise<void> {
    try {
      await updateYotpoLoyaltyRedemptionUrl({
        variables: {
          input: {
            brandId: brand.id,
            redemptionUrl: values.redemptionUrl || null,
          },
        },
      });
      toast.success('Redemption URL successfully updated!');
    } catch (err) {
      toast.error(logAndFormatError(err));
    }
  }

  return (
    <div className={clsx('max-w-4xl space-y-4', className)}>
      <LayoutSection>
        <LayoutSection.Header title="Yotpo Connection" />
        <LayoutSection.Body className="space-y-4">
          <p>
            You have connected your Yotpo integration. You can now use Yotpo for
            your loyalty point tracking and redemptions.
          </p>
          <TextInputField
            label="GUID"
            value={brand.yotpoIntegration?.guid}
            readOnly
          />
          <p>
            If you need to change or disconnect the integration, you can disable
            the integration below:
          </p>
          <Button
            color="secondary"
            disabled={isDeleting}
            onClick={() => {
              if (
                window.confirm('Are you sure you want to disconnect Yotpo?')
              ) {
                deleteYotpoIntegration({
                  variables: { input: { brandId: brand.id } },
                })
                  .then(() => {
                    toast.success(
                      'Yotpo integration successfully disconnected!',
                    );
                  })
                  .catch((err) => {
                    toast.error(logAndFormatError(err));
                  });
              }
            }}
          >
            Disconnect Integration
          </Button>
        </LayoutSection.Body>
      </LayoutSection>
      <form onSubmit={handleSubmit(handleUpdate)}>
        <LayoutSection>
          <LayoutSection.Header title="Yotpo Redemption URL" />
          <LayoutSection.Body className="space-y-4">
            <p>
              If you wish to add a custom URL to send users to redeem their
              points for your Yotpo integration, you can add one so below.
              Otherwise, we will display the available Yotpo integrations inside
              the AddGlow community.
            </p>
            <TextInputField.Controller name="redemptionUrl" control={control} />
            <Button
              color="primary"
              disabled={isSubmitting || !isDirty}
              type="submit"
            >
              Update Redemption URL
            </Button>
          </LayoutSection.Body>
        </LayoutSection>
      </form>
    </div>
  );
}
