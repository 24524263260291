import { Button } from '@addglowapp/components';
import {
  ErrorableLoader,
  TextAreaInputField,
  TextInputField,
} from '@addglowapp/components';
import { toast } from '@addglowapp/components';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import LayoutSection from 'src/components/LayoutSection';
import {
  FaqFragment,
  useCreateFaqMutation,
  useDeleteFaqMutation,
  useGetBrandFaqsQuery,
  useUpdateFaqMutation,
} from 'src/generated/graphql';
import { useBrandId } from 'src/hooks/useBrandId';
import { logAndFormatError } from 'src/services/error-formatter';

interface FaqRowProps {
  faq?: FaqFragment | null;
  onCompleted(): void;
  brandId: string;
}

const submitFaqFormSchema = z.object({
  question: z.string().trim().min(1).max(500),
  answer: z.string().trim().min(1).max(5000),
});

type SubmitFaqFormSchema = z.infer<typeof submitFaqFormSchema>;

function FaqItem({ faq, onCompleted, brandId }: FaqRowProps): JSX.Element {
  const [deleteFaq] = useDeleteFaqMutation();
  const [createFaq] = useCreateFaqMutation();
  const [updateFaq] = useUpdateFaqMutation();

  const {
    handleSubmit,
    control,
    formState: { isSubmitting, isDirty },
  } = useForm<SubmitFaqFormSchema>({
    resolver: zodResolver(submitFaqFormSchema),
    defaultValues: {
      ...faq,
    },
  });

  const handleSave = async (data: SubmitFaqFormSchema): Promise<void> => {
    if (faq?.id) {
      try {
        await updateFaq({
          variables: {
            input: {
              id: faq.id,
              data: {
                ...data,
              },
            },
          },
        });
        toast.success('FAQ saved successfully.');
        onCompleted();
      } catch (err) {
        toast.error(logAndFormatError(err));
      }
    } else {
      try {
        await createFaq({
          variables: {
            input: {
              data: {
                brandId,
                ...data,
              },
            },
          },
        });
        toast.success('FAQ created successfully.');
        onCompleted();
      } catch (err) {
        toast.error(logAndFormatError(err));
      }
    }
  };
  const handleDelete = async (): Promise<void> => {
    if (!faq?.id) {
      onCompleted();
      return;
    }

    try {
      await deleteFaq({ variables: { input: { id: faq.id } } });
      onCompleted();
    } catch (error) {
      toast.error(logAndFormatError(error));
      onCompleted();
    }
  };

  return (
    <div className="mt-4 max-w-xl space-y-2 border-b">
      <form onSubmit={handleSubmit(handleSave)}>
        <TextInputField.Controller
          control={control}
          label="Question"
          name="question"
        />

        <TextAreaInputField.Controller
          control={control}
          label="Answer"
          name="answer"
          className="mt-4"
          rows={4}
        />
        <div className="my-4 space-x-2">
          <Button disabled={isSubmitting || !isDirty} type="submit">
            Save
          </Button>
          <Button
            disabled={isSubmitting}
            color="secondary"
            onClick={handleDelete}
          >
            Remove
          </Button>
        </div>
      </form>
    </div>
  );
}

function FaqPage(): JSX.Element {
  const brandId = useBrandId();
  const { data, error, refetch } = useGetBrandFaqsQuery({
    variables: { brandId },
  });

  const [faqs, setFaqs] = useState<(FaqFragment | null)[]>(
    data?.brand?.faq || [],
  );

  useEffect(() => {
    setFaqs(data?.brand?.faq || []);
  }, [data]);

  if (!data) {
    return <ErrorableLoader error={error} />;
  }

  const url = `http://${data.brand.communityDomain}/faq`;

  const addNewFaq = (): void => {
    setFaqs([...faqs, null]);
  };

  return (
    <>
      <h1>FAQ</h1>
      <LayoutSection>
        <LayoutSection.Header
          title="FAQs"
          actions={
            <div className="flex space-x-2">
              <Button
                color="primary"
                onClick={() => {
                  window.location.href = url;
                }}
              >
                Visit FAQs
              </Button>
            </div>
          }
        />
        <LayoutSection.Body>
          <div className="-mt-4">
            {faqs.map((faqItem, index) => (
              <FaqItem
                brandId={brandId}
                key={faqItem?.id || `new-${index}`}
                faq={faqItem ?? null}
                onCompleted={refetch}
              />
            ))}
          </div>
          <div className="mt-6">
            <Button className="w-48" onClick={addNewFaq}>
              Add New FAQ
            </Button>
          </div>
        </LayoutSection.Body>
      </LayoutSection>
    </>
  );
}

export default FaqPage;
