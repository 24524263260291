import { Button, EmptyDisplay, ErrorableLoader } from '@addglowapp/components';
import { useGetMemberGroupsQuery } from 'src/generated/graphql';
import { useBrandId } from 'src/hooks/useBrandId';
import { MemberGroupDialog } from './MemberGroupDialog';
import { MemberGroupTable } from './MemberGroupTable';

export function MemberGroupsPage(): JSX.Element {
  const brandId = useBrandId();
  const { data, error } = useGetMemberGroupsQuery({
    variables: {
      brandId,
    },
  });

  if (!data) {
    return <ErrorableLoader error={error} />;
  }

  return (
    <div className="max-w-4xl space-y-4">
      <h1>Member Groups</h1>
      <p>
        Member groups allow you to organize your members into different groups
        and you can attach badges to these groups or use them to restrict access
        to certain rooms.
      </p>

      <MemberGroupDialog>
        <Button>Create Member Group</Button>
      </MemberGroupDialog>

      {data.memberGroups.length === 0 ? (
        <EmptyDisplay
          header="No Member Groups"
          subtitle="There are no member groups for this brand yet. Please create one above to get started."
        />
      ) : (
        <MemberGroupTable memberGroups={data.memberGroups} />
      )}
    </div>
  );
}
