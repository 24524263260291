import {
  Button,
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TextInputField,
  ToggleInput,
} from '@addglowapp/components';
import { toast } from '@addglowapp/components';
import { zodResolver } from '@hookform/resolvers/zod';
import clsx from 'clsx';
import { useFieldArray, useForm } from 'react-hook-form';
import { z } from 'zod';
import LayoutSection from 'src/components/LayoutSection';
import {
  BrandKlaviyoIntegrationFragment,
  GetKlaviyoIntegrationDocument,
  KlaviyoProperty,
  useDeleteKlaviyoIntegrationMutation,
  useUpdateKlaviyoIntegrationMutation,
} from 'src/generated/graphql';
import { logAndFormatError } from 'src/services/error-formatter';
import {
  KLAVIYO_PROPERTIES,
  KLAVIYO_PROPERTY_KEYS,
} from './constants/klaviyoProperties';
import { KlaviyoSubscribeListSection } from './KlaviyoSubscribeListSection';

interface UpdateKlaviyoSectionProps {
  className?: string;
  klaviyoIntegration: BrandKlaviyoIntegrationFragment;
}

const formSchema = z.object({
  brandKlaviyoMappings: z.array(
    z.object({
      addGlowProperty: z.enum(KLAVIYO_PROPERTY_KEYS),
      isEnabled: z.boolean(),
      destinationProperty: z.string(),
    }),
  ),
});

type FormValues = z.infer<typeof formSchema>;

export function UpdateKlaviyoSection({
  className,
  klaviyoIntegration,
}: UpdateKlaviyoSectionProps): JSX.Element {
  const [updateKlaviyoIntegration] = useUpdateKlaviyoIntegrationMutation();
  const [deleteKlaviyoIntegration, { loading: isDeleting }] =
    useDeleteKlaviyoIntegrationMutation({
      refetchQueries: [
        {
          query: GetKlaviyoIntegrationDocument,
          variables: { id: klaviyoIntegration.brandId },
        },
      ],
    });

  function getDefaultMappings(): FormValues['brandKlaviyoMappings'] {
    return Object.keys(KLAVIYO_PROPERTIES).map((key) => {
      const propertyKey = key as KlaviyoProperty;
      const existingProperty = klaviyoIntegration.brandKlaviyoMappings.find(
        (m) => m.addGlowProperty === key,
      );

      return (
        existingProperty || {
          addGlowProperty: propertyKey,
          isEnabled: KLAVIYO_PROPERTIES[propertyKey].default ?? false,
          destinationProperty: KLAVIYO_PROPERTIES[propertyKey].label,
        }
      );
    });
  }

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting, isDirty },
  } = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    values: klaviyoIntegration,
  });

  async function upsertKlaviyoIntegration({
    brandKlaviyoMappings,
  }: FormValues): Promise<void> {
    try {
      await updateKlaviyoIntegration({
        variables: {
          input: {
            id: klaviyoIntegration.brandId,
            data: { brandKlaviyoMappings },
          },
        },
      });
      toast.success('Klaviyo mappings successfully saved!');
    } catch (err) {
      toast.error(logAndFormatError(err));
    }
  }

  const { fields } = useFieldArray({ control, name: 'brandKlaviyoMappings' });

  return (
    <div className={clsx('space-y-8', className)}>
      <form onSubmit={handleSubmit(upsertKlaviyoIntegration)}>
        <LayoutSection>
          <LayoutSection.Header
            title="User Profile Synchronization"
            actions={
              <div className="flex space-x-2">
                <Button
                  color="primary"
                  type="submit"
                  disabled={isSubmitting || !isDirty}
                >
                  Save
                </Button>
              </div>
            }
          />
          <LayoutSection.Body className="space-y-4">
            <p>
              AddGlow can synchronize brand members to AddGlow to Klaviyo. This
              allows you to view and segment your brand members in Klaviyo.
            </p>
            <ToggleInput.Labelled
              label="Enable Profile Synchronization"
              checked={fields.length > 0}
              onChange={(checked) => {
                setValue(
                  'brandKlaviyoMappings',
                  checked ? getDefaultMappings() : [],
                  {
                    shouldDirty: true,
                  },
                );
              }}
            />
            {fields.length === 0 ? null : (
              <div className="flex max-w-6xl flex-col space-y-4">
                <p className="text-sm text-gray-700">
                  Synchronizations occur every hour so it may take time to show
                  up in Klaviyo.
                </p>
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead>Enable</TableHead>
                      <TableHead>AddGlow Property</TableHead>
                      <TableHead>Klaviyo Property</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {fields.map((f, idx) => (
                      <TableRow key={f.id}>
                        <TableCell>
                          <ToggleInput.LabelledController
                            control={control}
                            name={`brandKlaviyoMappings.${idx}.isEnabled`}
                            disabled={
                              KLAVIYO_PROPERTIES[f.addGlowProperty].forceEnabled
                            }
                          />
                        </TableCell>
                        <TableCell>
                          {KLAVIYO_PROPERTIES[f.addGlowProperty].label}
                        </TableCell>
                        <TableCell>
                          {KLAVIYO_PROPERTIES[f.addGlowProperty]
                            .hasNoMapping ? null : (
                            <TextInputField.Controller
                              control={control}
                              name={`brandKlaviyoMappings.${idx}.destinationProperty`}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            )}
          </LayoutSection.Body>
        </LayoutSection>
      </form>
      <KlaviyoSubscribeListSection klaviyoIntegration={klaviyoIntegration} />
      <LayoutSection>
        <LayoutSection.Header title="Klaviyo Connection" />
        <LayoutSection.Body className="space-y-4">
          <p>
            If you need to change or disconnect the integration, you can disable
            the integration below:
          </p>
          <Button
            color="secondary"
            disabled={isDeleting}
            onClick={() => {
              if (
                window.confirm('Are you sure you want to disconnect Klaviyo?')
              ) {
                deleteKlaviyoIntegration({
                  variables: { input: { id: klaviyoIntegration.brandId } },
                })
                  .then(() => {
                    toast.success(
                      'Klaviyo integration successfully disconnected!',
                    );
                  })
                  .catch((err) => {
                    toast.error(logAndFormatError(err));
                  });
              }
            }}
          >
            Disconnect Integration
          </Button>
        </LayoutSection.Body>
      </LayoutSection>
    </div>
  );
}
