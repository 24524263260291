import {
  Button,
  DataTableViewOptions,
  LoadingProvider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@addglowapp/components';
import { TextInputField } from '@addglowapp/components';
import { toast } from '@addglowapp/components';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
  SortingState,
  getSortedRowModel,
  getFilteredRowModel,
} from '@tanstack/react-table';
import { useState } from 'react';
import { MdClose } from 'react-icons/md';
import { logAndFormatError } from '@src/services/error-formatter';

interface DataTableProps<TData> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: ColumnDef<TData, any>[];
  data: TData[];
  downloadCsv?: () => Promise<void>;
  cta?: React.ReactNode;
}

export function DataTable<TData>({
  columns,
  data,
  downloadCsv,
  cta,
}: DataTableProps<TData>): JSX.Element {
  const [sortingState, setSortingState] = useState<SortingState>([]);
  const [globalFilter, setGlobalFilter] = useState('');

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSortingState,
    getSortedRowModel: getSortedRowModel(),
    initialState: {
      columnVisibility: {
        lifetimePoints: false,
      },
      pagination: {
        pageSize: 20,
      },
    },
    state: {
      sorting: sortingState,
      globalFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
    enableFilters: true,
    enableGlobalFilter: true,
  });

  const [isDownloading, setIsDownloading] = useState(false);

  return (
    <div className="space-y-4">
      <div className="flex justify-between">
        <div className="relative w-full max-w-sm">
          <TextInputField
            placeholder="Search for member..."
            value={globalFilter}
            onChange={(val) => setGlobalFilter(val)}
          />
          {globalFilter && (
            <button
              className="absolute right-0 top-0 flex h-full items-center px-2"
              onClick={() => setGlobalFilter('')}
            >
              <MdClose />
            </button>
          )}
        </div>
        <div className="flex flex-row items-center gap-4">
          {downloadCsv && (
            <Button
              size="small"
              onClick={() => {
                setIsDownloading(true);
                downloadCsv()
                  .catch((err) => {
                    toast.error(logAndFormatError(err));
                  })
                  .finally(() => {
                    setIsDownloading(false);
                  });
              }}
              color="secondary"
              disabled={isDownloading}
            >
              Export CSV
            </Button>
          )}

          <DataTableViewOptions table={table} />
          {cta}
        </div>
      </div>
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody className="bg-white">
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && 'selected'}
                >
                  <LoadingProvider>
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </TableCell>
                    ))}
                  </LoadingProvider>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className="flex items-center justify-end space-x-2 py-4">
        <Button
          color="secondary"
          size="small"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          Previous
        </Button>
        <Button
          color="secondary"
          size="small"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          Next
        </Button>
      </div>
    </div>
  );
}
