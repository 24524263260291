import { Badge, DataTableColumnHeader } from '@addglowapp/components';
import { getFullCommunityDomain } from '@addglowapp/shared';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { HiOutlineExternalLink } from 'react-icons/hi';
import { BrandMemberRole, MemberGroupFragment } from '@src/generated/graphql';
import { formatDate } from '@src/utils/date';
import { BrandMemberActionMenu } from './components/BrandMemberActionMenu';
import { BrandMemberRoleBadge } from './components/BrandMemberRoleBadge';
import { BrandMemberData } from './types';

const columnHelper = createColumnHelper<BrandMemberData>();

export const brandMemberColumns = (
  refetch: () => void,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): ColumnDef<BrandMemberData, any>[] => [
  columnHelper.display({
    id: 'actions',
    meta: {
      name: 'Actions',
    },
    header: 'Actions',
    cell: ({ row }) => {
      return (
        <BrandMemberActionMenu brandMember={row.original} refetch={refetch} />
      );
    },
  }),
  columnHelper.accessor('name', {
    meta: { name: 'Name' },
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Name" />
    ),
    enableGlobalFilter: true,
  }),
  columnHelper.accessor('username', {
    meta: { name: 'Username' },
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Username" />
    ),
    enableGlobalFilter: true,
    cell: (info) => {
      return (
        <a
          className="flex flex-row items-center text-brand-text-content underline"
          href={
            getFullCommunityDomain(info.row.original.communityDomain) +
            '/profile/' +
            info.getValue()
          }
        >
          {info.getValue()} <HiOutlineExternalLink className="ml-1" />
        </a>
      );
    },
  }),
  columnHelper.accessor('role', {
    meta: { name: 'Role' },
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Role" />
    ),
    cell: (info) => (
      <BrandMemberRoleBadge
        role={info.getValue() as BrandMemberRole}
        isBanned={info.row.original.isBanned}
        moderatedRooms={info.row.original.userModeratedRooms}
      />
    ),
  }),
  columnHelper.accessor('groups', {
    meta: { name: 'Groups' },
    header: 'Groups',
    cell: ({ getValue }) => {
      const val = getValue() as MemberGroupFragment[];
      if (val.length > 3) {
        return <Badge variant="secondary">{val.length} groups</Badge>;
      }
      return (
        <div className="flex gap-0.5">
          {val.map((v) => (
            <Badge key={v.id} variant="secondary">
              {v.name}
            </Badge>
          ))}
        </div>
      );
    },
  }),
  columnHelper.accessor('email', {
    meta: { name: 'Email' },
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Email" />
    ),
  }),
  columnHelper.accessor('loyaltyPoints', {
    id: 'loyaltyPoints',
    meta: { name: 'Loyalty Points' },
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Loyalty Points" />
    ),
  }),
  columnHelper.accessor('lifetimePoints', {
    id: 'lifetimePoints',
    meta: { name: 'Lifetime Points' },
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Lifetime Points" />
    ),
  }),
  columnHelper.accessor('dateJoined', {
    meta: { name: 'Date Joined' },
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Date Joined" />
    ),
    cell: (info) => formatDate(new Date(info.getValue() as string)),
  }),
];
