import { FaShopify } from '@addglowapp/components';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@addglowapp/components';
import { BsFillBookmarkFill, BsPersonCircle } from 'react-icons/bs';
import { useBrandId } from 'src/hooks/useBrandId';
import ActionCard from './ActionCard';
import GuideMenu from './GuideMenu';
import GuideSection from './GuideSection';

function CustomizationSection(): JSX.Element {
  return (
    <GuideSection title="Customization" id="customization">
      <p>
        When you modify the colors, fonts, or assets under the customization tab
        in Brand Admin, they will propagate throughout the community. Below is a
        snapshot of where the elements that you configure will be used.
      </p>
    </GuideSection>
  );
}

function ModerationSection(): JSX.Element {
  return (
    <GuideSection title="Moderation" id="moderation">
      <p>
        AddGlow has two types of moderation for the community: automatic and
        manual.
      </p>
      <p>
        To ensure a positive community experience, AddGlow uses Hive to
        automatically moderate all images and text for offensive and
        inappropriate content. Offensive content is blocked from being posted,
        while uncertain content is flagged for admin review.
      </p>
      <p>
        Manual moderation allows all members to report posts or comments that
        may violate community guidelines. Admins can manage these reports in the
        brand dashboard.
      </p>
      <ol className="space-y-4">
        <li>
          <strong>Monitor the community:</strong> Regularly monitor discussions
          and user behavior.
        </li>
        <li>
          <strong>Contact AddGlow for additional moderation support:</strong>{' '}
          support@addglow.com or via Slack.
        </li>
      </ol>
    </GuideSection>
  );
}

function SuggestedContentSection(): JSX.Element {
  const contentSuggestions = [
    'Founder AMA',
    'Educational content',
    'Behind-the-scenes',
    'Events',
    'Before and after photos',
    'Share success stories',
    'New products or features',
    'Live Q&A',
    'Ask for feedback',
    'Exclusive offers',
    'Industry news',
    'Interviews with experts',
    'Team interviews',
    'Customer profiles',
    'Polls and surveys',
    'Contests and giveaways',
    'Celebrate milestones',
    'User-generated content',
    'Community spotlights',
    'Social',
  ];

  return (
    <GuideSection title="Suggested Content" id="suggested-content">
      <ol className="space-y-4">
        {contentSuggestions.map((item, idx) => (
          <li key={idx}>
            <strong>{item}</strong>
          </li>
        ))}
      </ol>
    </GuideSection>
  );
}

function PinnedPostsSection(): JSX.Element {
  const pinnedPostSuggestions = [
    'Welcome and Introduction',
    'Important Announcements',
    'Community Guidelines',
    'FAQs',
    'Exclusive Offers',
    'Brand Story',
    'Product spotlight',
    'Brand ambassador spotlight',
    'Seasonal content',
    'Crowdsourcing ideas',
  ];

  return (
    <GuideSection title="Pinned Posts" id="pinned-post">
      <p>
        Admins can pin one post at a time to highlight important content. Here
        are some suggestions:
      </p>
      <ol className="space-y-4">
        {pinnedPostSuggestions.map((item, idx) => (
          <li key={idx}>
            <strong>{item}</strong>
          </li>
        ))}
      </ol>
    </GuideSection>
  );
}

function AdminRolesSection(): JSX.Element {
  return (
    <GuideSection title="Admin Roles" id="admin-roles">
      <p>Here are the admin roles for the AddGlow community:</p>
      <ul className="list-inside list-disc">
        <li>
          <strong>Member:</strong> No special privileges.
        </li>
        <li>
          <strong>Moderator:</strong> Can moderate posts and comments.
        </li>
        <li>
          <strong>Admin:</strong> Has full access to the brand dashboard and can
          manage members and content.
        </li>
      </ul>
    </GuideSection>
  );
}

function MemberRolesSection(): JSX.Element {
  return (
    <GuideSection title="Member Roles" id="member-roles">
      <p>
        Custom member role labels can be added to designate a member’s
        affiliation with the brand.
      </p>
      <p>
        Admins have a reserved label that cannot be deleted but can be
        customized in the dashboard.
      </p>
    </GuideSection>
  );
}

function PromotingYourCommunitySection(): JSX.Element {
  const contentCategories = [
    {
      category: 'Engagement',
      items: ['Memes', 'Articles', 'Videos', 'Social Media Content', 'Quotes'],
    },
    {
      category: 'Connection',
      items: [
        'User-generated questions',
        'Conversation starters',
        'Brand-sponsored challenges',
      ],
    },
    {
      category: 'Education',
      items: [
        'Infographics',
        'Ingredient education',
        'Behind the scenes footage',
      ],
    },
  ];

  const contentPlanData = [
    { day: 'Monday', content: 'Memes' },
    { day: 'Tuesday', content: 'Community spotlight' },
    { day: 'Wednesday', content: 'Behind the scenes' },
    { day: 'Thursday', content: 'Post a quote' },
    { day: 'Friday', content: 'Post a TikTok/Insta' },
    { day: 'Monthly', content: 'Company AMA' },
  ];

  return (
    <GuideSection title="Promoting Your Community" id="promo">
      <h3 className="text-lg font-semibold">Content Strategies</h3>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Category</TableCell>
            <TableCell>Items</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {contentCategories.map((category, idx) => (
            <TableRow key={idx}>
              <TableCell>
                <strong>{category.category}</strong>
              </TableCell>
              <TableCell>{category.items.join(', ')}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <h3 className="text-lg font-semibold">Sample Content Plan</h3>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Day</TableCell>
            <TableCell>Content Type</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {contentPlanData.map((item, idx) => (
            <TableRow key={idx}>
              <TableCell>
                <strong>{item.day}</strong>
              </TableCell>
              <TableCell>{item.content}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </GuideSection>
  );
}

function UserGuide(): JSX.Element {
  const brandId = useBrandId();

  return (
    <div>
      <div className="grid grid-cols-3 gap-4">
        <ActionCard
          icon={<FaShopify />}
          text="Show community posts on your store or reward your members."
          header="Integrate with Shopify"
          actionUrl={`/${brandId}/shopify`}
        />
        <ActionCard
          icon={<BsFillBookmarkFill />}
          text="Send community data to your Klaviyo account."
          header="Data to Klaviyo"
          actionUrl={`/${brandId}/klaviyo`}
        />
        <ActionCard
          icon={<BsPersonCircle />}
          text="Add data fields for customer insights."
          header="Detailed Profiles"
          actionUrl={`/${brandId}/customization/profiles`}
        />
      </div>
      <div className="flex flex-row space-x-10 p-4">
        <GuideMenu />
        <div>
          <CustomizationSection />
          <ModerationSection />
          <SuggestedContentSection />
          <PinnedPostsSection />
          <AdminRolesSection />
          <MemberRolesSection />
          <PromotingYourCommunitySection />
        </div>
      </div>
    </div>
  );
}

export default UserGuide;
