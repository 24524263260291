import {
  Button,
  SelectField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  TextInputField,
  ToggleInput,
} from '@addglowapp/components';
import { toast } from '@addglowapp/components';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMemo } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { z } from 'zod';

import LayoutSection from 'src/components/LayoutSection';
import {
  BrandLoyaltyPageDataFragment,
  LoyaltyActionPeriod,
  LoyaltyActionType,
  useUpdateBrandMutation,
} from 'src/generated/graphql';
import { logAndFormatError } from 'src/services/error-formatter';

const EARN_RATE = Array(10)
  .fill(0)
  .map((_, idx) => idx + 1);
const EARN_PERIOD = ['HOUR', 'DAY', 'WEEK'] as const;

interface LoyaltyActionTypeConfig {
  label: string;
  hint?: string;
  hasEarnRate?: boolean;
  default: {
    points: number;
    earnRate?: number;
    earnPeriod?: LoyaltyActionPeriod;
  };
}

const LOYALTY_ACTION_TYPE_CONFIG: Record<
  LoyaltyActionType,
  LoyaltyActionTypeConfig | null
> = {
  ACCOUNT_CREATE: {
    label: 'Join the community',
    default: {
      points: 10,
    },
  },
  PROFILE_COMPLETE: {
    label: 'Profile Completion',
    hint: 'Fill in bio, location and all profile traits.',
    default: {
      points: 100,
    },
  },
  SOCIAL_COMPLETE: {
    label: 'Social Profile',
    hint: 'Add at least one social link.',
    default: {
      points: 20,
    },
  },
  VISIT_DAILY: {
    label: 'Community Visit',
    hasEarnRate: true,
    default: {
      points: 1,
      earnRate: 1,
      earnPeriod: 'DAY',
    },
  },
  POST_CREATE: {
    label: 'Write Post',
    hasEarnRate: true,
    default: {
      points: 50,
      earnRate: 1,
      earnPeriod: 'DAY',
    },
  },
  POST_REPLY: {
    label: 'Comment',
    hasEarnRate: true,
    default: {
      points: 10,
      earnRate: 1,
      earnPeriod: 'DAY',
    },
  },
  CHALLENGE_COMPLETE: null,
  REFERRAL_SIGNUP: {
    label: 'Refer a Friend',
    hint: 'Points given for every sign up that is referred by other members.',
    default: {
      points: 50,
    },
  },
};

const NON_NULLABLE_LOYALTY_ACTION_TYPE_CONFIG: Record<
  LoyaltyActionType,
  LoyaltyActionTypeConfig
> = Object.keys(LOYALTY_ACTION_TYPE_CONFIG)
  .filter(
    (key) => LOYALTY_ACTION_TYPE_CONFIG[key as LoyaltyActionType] !== null,
  )
  .reduce(
    (acc, key) => {
      acc[key as LoyaltyActionType] =
        LOYALTY_ACTION_TYPE_CONFIG[key as LoyaltyActionType]!;
      return acc;
    },
    {} as Record<LoyaltyActionType, LoyaltyActionTypeConfig>,
  );

const loyaltySettingsSchema = z.object({
  loyaltyEnabled: z.boolean(),
  loyaltyActions: z.array(
    z.object({
      id: z.string().optional(),
      type: z.enum(
        Object.keys(LOYALTY_ACTION_TYPE_CONFIG) as [
          LoyaltyActionType,
          ...LoyaltyActionType[],
        ],
      ),
      enabled: z.boolean(),
      earnPeriod: z.enum(EARN_PERIOD).nullish(),
      earnRate: z.coerce.number().int().min(1).max(10).nullish(),
      points: z.coerce.number().int().min(1).max(1000),
    }),
  ),
});

type LoyaltySettingsFormData = z.infer<typeof loyaltySettingsSchema>;

function SettingsSection({
  brandData,
}: {
  brandData: BrandLoyaltyPageDataFragment;
}): JSX.Element | null {
  const { brand } = brandData;

  const defaultValues = useMemo(() => {
    return {
      loyaltyEnabled: brandData.brand.loyaltyEnabled,
      loyaltyActions: Object.keys(NON_NULLABLE_LOYALTY_ACTION_TYPE_CONFIG).map(
        (type) => {
          const actionType = type as LoyaltyActionType;
          const typeConfig =
            NON_NULLABLE_LOYALTY_ACTION_TYPE_CONFIG[actionType];

          const action = brandData.brand.loyaltyActions.find(
            (a) => a.type === actionType,
          );
          return (
            action ?? {
              type: actionType,
              enabled: false,
              ...typeConfig.default,
            }
          );
        },
      ),
    };
  }, [brandData]);

  const {
    control,
    formState: { isSubmitting, dirtyFields },
    handleSubmit,
  } = useForm<LoyaltySettingsFormData>({
    resolver: zodResolver(loyaltySettingsSchema),
    defaultValues: defaultValues,
  });

  const [updateBrand] = useUpdateBrandMutation();

  const onSubmit = async (formData: LoyaltySettingsFormData): Promise<void> => {
    try {
      await updateBrand({
        variables: {
          input: {
            id: brand.id,
            data: formData,
          },
        },
      });

      toast.success('Loyalty settings updated successfully.');
    } catch (e) {
      toast.error(
        logAndFormatError(e, 'Sorry, the action could not be completed.'),
      );
    }
  };

  const { fields } = useFieldArray({ control, name: 'loyaltyActions' });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <LayoutSection>
        <LayoutSection.Header
          title="Loyalty Settings"
          actions={
            <div className="flex space-x-2">
              <Button
                color="primary"
                type="submit"
                disabled={isSubmitting || Object.keys(dirtyFields).length === 0}
              >
                Save
              </Button>
            </div>
          }
        />
        <LayoutSection.Body>
          <div className="flex flex-col space-y-4">
            <div>
              <ToggleInput.LabelledController
                name="loyaltyEnabled"
                label="Enable Engagement Loyalty"
                control={control}
              />
              <p className="text-sm text-gray-500">
                Allow your community members to earn points by performing
                certain actions within your community.
              </p>
            </div>
            <hr />
            <div>
              <h2 className="text-base font-semibold">Point eligibility</h2>
              <p className="text-sm text-gray-500">
                List of actions your community members can take to earn points.
              </p>
              <Table className="mt-8">
                <TableHeader>
                  <TableRow className="border-b border-gray-200">
                    <TableHead>Action</TableHead>
                    <TableHead>Points</TableHead>
                    <TableHead>Earn</TableHead>
                    <TableHead>Rate</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {fields.map((field, idx) => {
                    const typeConfig = LOYALTY_ACTION_TYPE_CONFIG[field.type];
                    if (!typeConfig) {
                      return null;
                    }
                    return (
                      <TableRow key={field.id}>
                        <TableCell>
                          <ToggleInput.LabelledController
                            control={control}
                            label={typeConfig.label}
                            hint={typeConfig.hint}
                            name={`loyaltyActions.${idx}.enabled`}
                          />
                        </TableCell>
                        <TableCell>
                          <TextInputField.Controller
                            className="w-20"
                            control={control}
                            name={`loyaltyActions.${idx}.points`}
                          />
                        </TableCell>
                        {typeConfig.hasEarnRate ? (
                          <>
                            <TableCell>
                              <SelectField.Controller
                                className="w-20"
                                control={control}
                                placeholder="Select a rate"
                                name={`loyaltyActions.${idx}.earnRate`}
                                options={EARN_RATE.map((rate) => ({
                                  value: rate.toString(),
                                  label: rate.toString(),
                                }))}
                              />
                            </TableCell>
                            <TableCell>
                              <SelectField.Controller
                                className="w-20"
                                placeholder="Select a period"
                                control={control}
                                name={`loyaltyActions.${idx}.earnPeriod`}
                                options={EARN_PERIOD.map((period) => ({
                                  value: period,
                                  label: period,
                                }))}
                              />
                            </TableCell>
                          </>
                        ) : (
                          <>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                          </>
                        )}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>

              <p className="py-4">
                Want to reward something else?{' '}
                <a href="mailto:contact@addglow.com">Let us know</a>.
              </p>
            </div>
          </div>
        </LayoutSection.Body>
      </LayoutSection>
    </form>
  );
}

export default SettingsSection;
