import {
  Button,
  ErrorableLoader,
  SelectField,
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableHead,
  TableCell,
  TextInputField,
  ToggleInput,
} from '@addglowapp/components';
import { toast } from '@addglowapp/components';
import { zodResolver } from '@hookform/resolvers/zod';
import clsx from 'clsx';
import { useFieldArray, useForm } from 'react-hook-form';
import { z } from 'zod';

import LayoutSection from 'src/components/LayoutSection';
import {
  BrandWithYotpoIntegrationFragment,
  ProfileProperty,
  useGetYotpoListsQuery,
  useUpdateYotpoDeveloperIntegrationMutation,
} from 'src/generated/graphql';
import { logAndFormatError } from 'src/services/error-formatter';
import {
  PROFILE_PROPERTIES,
  PROFILE_PROPERTY_KEYS,
} from './constants/profile-properties';

interface UpdateYotpoSectionProps {
  className?: string;
  yotpoIntegration: Exclude<
    BrandWithYotpoIntegrationFragment['yotpoDeveloperIntegration'],
    undefined | null
  >;
}

const formSchema = z.object({
  brandYotpoMappings: z.array(
    z.object({
      profileProperty: z.enum(PROFILE_PROPERTY_KEYS),
      isEnabled: z.boolean(),
      destinationProperty: z.string(),
    }),
  ),
  subscriberListId: z.string().nullish(),
});

type FormValues = z.infer<typeof formSchema>;

export function UpdateYotpoSyncSection({
  className,
  yotpoIntegration,
}: UpdateYotpoSectionProps): JSX.Element {
  const [updateYotpoDeveloperIntegration] =
    useUpdateYotpoDeveloperIntegrationMutation();

  const { data, error } = useGetYotpoListsQuery({
    variables: {
      brandId: yotpoIntegration.id,
    },
  });

  function getDefaultMappings(): FormValues['brandYotpoMappings'] {
    return Object.keys(PROFILE_PROPERTIES).map((key) => {
      const propertyKey = key as ProfileProperty;
      const existingProperty = yotpoIntegration.brandYotpoMappings.find(
        (m) => m.profileProperty === key,
      );

      return (
        existingProperty ?? {
          profileProperty: propertyKey,
          isEnabled: PROFILE_PROPERTIES[propertyKey].default ?? false,
          destinationProperty: propertyKey.toLowerCase(),
        }
      );
    });
  }

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting, isDirty },
  } = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    values: yotpoIntegration,
  });

  async function onSubmit({
    brandYotpoMappings,
    subscriberListId,
  }: FormValues): Promise<void> {
    try {
      await updateYotpoDeveloperIntegration({
        variables: {
          input: {
            id: yotpoIntegration.id,
            data: {
              brandYotpoMappings,
              isCustomerSyncEnabled: !!brandYotpoMappings.length,
              subscriberListId,
            },
          },
        },
      });
      toast.success('Yotpo mappings successfully saved!');
    } catch (err) {
      toast.error(logAndFormatError(err));
    }
  }

  const { fields } = useFieldArray({ control, name: 'brandYotpoMappings' });

  return (
    <div className={clsx('space-y-8', className)}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <LayoutSection>
          <LayoutSection.Header
            title="User Profile Synchronization"
            actions={
              <div className="flex space-x-2">
                <Button
                  color="primary"
                  type="submit"
                  disabled={isSubmitting || !isDirty}
                >
                  Save
                </Button>
              </div>
            }
          />
          <LayoutSection.Body className="space-y-4">
            <p>
              AddGlow can synchronize brand members to AddGlow to Yotpo. This
              allows you to view and segment your brand members in Yotpo.
            </p>
            <ToggleInput.Labelled
              label="Enable Profile Synchronization"
              checked={fields.length > 0}
              onChange={(checked) => {
                setValue(
                  'brandYotpoMappings',
                  checked ? getDefaultMappings() : [],
                  {
                    shouldDirty: true,
                  },
                );
              }}
            />
            {fields.length === 0 ? null : (
              <div className="flex max-w-6xl flex-col space-y-4">
                <p className="text-sm text-gray-700">
                  Synchronizations occur every hour so it may take time to show
                  up in Yotpo.
                </p>
                {data ? (
                  <>
                    <SelectField.Controller
                      control={control}
                      name="subscriberListId"
                      options={[
                        { label: 'None', value: null },
                        ...(data?.brandYotpoLists.map((l) => ({
                          label: l.name,
                          value: l.id,
                        })) ?? []),
                      ]}
                      label="Subscriber List"
                      getOptionValue={(option) => option.value}
                      getOptionLabel={(option) => option.label}
                      placeholder="Select a subscriber list"
                    />
                    <p className="text-sm text-gray-700">
                      If selected, users who opt-in to marketing emails will be
                      added to this list in Yotpo.
                    </p>
                  </>
                ) : (
                  <ErrorableLoader error={error} />
                )}
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead>Enable</TableHead>
                      <TableHead>AddGlow Property</TableHead>
                      <TableHead>Yotpo Property</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {fields.map((f, idx) => (
                      <TableRow key={f.id}>
                        <TableCell>
                          <ToggleInput.LabelledController
                            control={control}
                            name={`brandYotpoMappings.${idx}.isEnabled`}
                            disabled={
                              PROFILE_PROPERTIES[f.profileProperty].forceEnabled
                            }
                          />
                        </TableCell>
                        <TableCell>
                          {PROFILE_PROPERTIES[f.profileProperty].label}
                        </TableCell>
                        <TableCell>
                          {PROFILE_PROPERTIES[f.profileProperty]
                            .hasNoMapping ? null : (
                            <TextInputField.Controller
                              control={control}
                              name={`brandYotpoMappings.${idx}.destinationProperty`}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            )}
          </LayoutSection.Body>
        </LayoutSection>
      </form>
    </div>
  );
}
