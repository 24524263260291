import { Button } from '@addglowapp/components';
import { Modal } from '@addglowapp/components';
import { toast } from '@addglowapp/components';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useEffect, useState } from 'react';
import { MdDragHandle } from 'react-icons/md';
import {
  RoomOptionFragment,
  useReorderRoomOptionsMutation,
} from '@src/generated/graphql';
import { useBrandId } from '@src/hooks/useBrandId';
import { logAndFormatError } from '@src/services/error-formatter';

function SortableRoomItem({ room }: { room: RoomOptionFragment }): JSX.Element {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: room.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      className="mb-2 flex cursor-grab items-center space-x-4 rounded-md border border-gray-200 p-4"
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    >
      <div>
        <MdDragHandle className="h-4 w-4 text-gray-500" />
      </div>
      <div className="font-medium">{room.name}</div>
    </div>
  );
}

function ReorderableRoomList({
  rooms,
  roomIds,
  onRoomsReordered,
}: {
  rooms: RoomOptionFragment[];
  roomIds: string[];
  onRoomsReordered(roomIds: string[]): void;
}): JSX.Element {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragEnd = (event: DragEndEvent): void => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = roomIds.indexOf(active.id as string);
      const newIndex = roomIds.indexOf(over.id as string);
      const newRoomIds = arrayMove(roomIds, oldIndex, newIndex);

      onRoomsReordered(newRoomIds);
    }
  };

  const getRoomById = (id: string): RoomOptionFragment => {
    const room = rooms.find((room) => room.id === id);
    if (!room) {
      throw new Error(`Could not find room with id ${id}`);
    }
    return room;
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={roomIds} strategy={verticalListSortingStrategy}>
        {roomIds.map((roomId) => (
          <SortableRoomItem key={roomId} room={getRoomById(roomId)} />
        ))}
      </SortableContext>
    </DndContext>
  );
}

interface ReorderRoomsModalButtonProps {
  rooms: RoomOptionFragment[];
}

export function ReorderRoomsModalButton({
  rooms,
}: ReorderRoomsModalButtonProps): JSX.Element {
  const brandId = useBrandId();
  const [modalOpen, setModalOpen] = useState(false);
  const [reorderRooms, { loading }] = useReorderRoomOptionsMutation();
  const [roomIds, setRoomIds] = useState<string[]>(
    rooms.map((room) => room.id),
  );

  useEffect(() => {
    setRoomIds(rooms.map((room) => room.id));
  }, [rooms, modalOpen]);

  const handleSave = async (): Promise<void> => {
    try {
      await reorderRooms({
        variables: {
          input: {
            brandId,
            roomOptionIds: roomIds,
          },
        },
      });
      toast.success('Rooms successfully reordered!');
      setModalOpen(false);
    } catch (error) {
      toast.error(logAndFormatError('Error reordering rooms.'));
    }
  };

  return (
    <>
      <Button color="secondary" onClick={() => setModalOpen(true)}>
        Reorder Rooms
      </Button>
      <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
        <Modal.Header
          className="justify-center"
          onClose={() => setModalOpen(false)}
        >
          Reorder Rooms
        </Modal.Header>
        <Modal.Body className="space-y-4">
          <p>Drag and drop the rooms below to reorder them.</p>
          <ReorderableRoomList
            rooms={rooms}
            roomIds={roomIds}
            onRoomsReordered={setRoomIds}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setModalOpen(false)}>Cancel</Button>
          <Button onClick={handleSave} disabled={loading}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
