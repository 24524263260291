import { format } from 'date-fns';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';

TimeAgo.addDefaultLocale(en);
const timeAgo = new TimeAgo('en-US');

export function formatDateAsTimeAgo(date: Date | string): string {
  return timeAgo.format(date instanceof Date ? date : new Date(date));
}

export function formatDate(date: Date | string): string {
  const inputDate = date instanceof Date ? date : new Date(date);
  return format(inputDate, "MMM do', 'yyyy h:mm aa");
}

export function formatDateWithoutTime(date: Date | string): string {
  const inputDate = date instanceof Date ? date : new Date(date);
  return format(inputDate, "MMM do', 'yyyy");
}

export function convertToDate(date: Date | string): string {
  const inputDate = date instanceof Date ? date : new Date(date);
  return format(inputDate, 'yyyy-MM-dd');
}
