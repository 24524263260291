import { ErrorableLoader } from '@addglowapp/components';
import { Navigate } from 'react-router-dom';
import { useGetYotpoDeveloperIntegrationQuery } from 'src/generated/graphql';
import { useBrandId } from 'src/hooks/useBrandId';
import { ENABLE_YOTPO_DEV_INTEGRATION } from '@src/constants/flags';
import { ConnectYotpoSection } from './ConnectYotpoSection';
import { UpdateYotpoConnectionSection } from './UpdateYotpoConnectionSection';
import { UpdateYotpoSyncSection } from './UpdateYotpoSyncSection';

export function YotpoPage(): JSX.Element {
  const brandId = useBrandId();
  const { data, error } = useGetYotpoDeveloperIntegrationQuery({
    variables: {
      id: brandId,
    },
  });

  if (!ENABLE_YOTPO_DEV_INTEGRATION) {
    return <Navigate to="/" />;
  }

  if (!data) {
    return <ErrorableLoader error={error} />;
  }

  return (
    <div className="max-w-4xl space-y-2">
      <h1>Yotpo SMS/Email Integration</h1>
      {!data.brand.yotpoDeveloperIntegration ? (
        <ConnectYotpoSection />
      ) : (
        <div className="space-y-4">
          <UpdateYotpoConnectionSection brand={data.brand} />
          <UpdateYotpoSyncSection
            yotpoIntegration={data.brand.yotpoDeveloperIntegration}
          />
        </div>
      )}
    </div>
  );
}
